import React, { Component, createRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import {generateGridLayout, generateJustifyLayout} from '../../../frontend/src/components/page/gallery/layout-helpers';
import { LookbookSiteTile } from './lookbook-site-tile';
import { InUseLaptopTile } from './in-use-laptop';

import _ from 'lodash'

const resizeMap = new Map();

const resizeObserver = new ResizeObserver(function(entries){
	entries.forEach(function(entry){

		resizeMap.forEach((mapItem, side)=>{

			if( mapItem.el == entry.target ){

				const box = entry.contentBoxSize[0] || entry.contentBoxSize;
				const width = box.inlineSize;
				mapItem.component.setState(prevState=>{
					if(prevState[side+'ColumnWidth'] === width){
						return null
					} else {
						return {
							[side+'ColumnWidth']: width
						}
					}
				});
			}

		})

	});
});


export const categoryList = Object.freeze([
	{
		title: 'All',
		slug: 'all',
		key: 'all'
	},
	{
		title: 'Sites in Use',
		slug: 'in-use',
		key: 'inuse'
	},
	{
		title: 'Graphic Design',
		slug: 'graphic-design',
		key: 'graphicdesign'
	},
	{
		title: 'Style',
		slug: 'style',
		key: 'style'
	},
	{
		title: 'Architecture & Design',
		slug: 'architecture-design',
		key: 'architecturedesign',
		navTitle: 'Arch. & Design'
	},
	{
		title: 'Art',
		slug: 'art',
		key: 'art'
	},
	{
		title: 'Photo',
		slug: 'photo',
		key: 'photo'
	},
	{
		title: 'Shops',
		slug: 'shops',
		key: 'shop'
	}
]);

class Lookbook extends Component {

	constructor(props) {
		super(props);

		this.state = {
			leftColumnWidth: 0,
			rightColumnWidth: 0,
			currentRenderLimit: 0,
			allRenderIncrement: 4,
			categoryRenderIncrement: 50
		}

		this.state.currentRenderLimit = this.getCurrentRenderIncrement();

		if( !props.hasTemplates && !props.loadingTemplates ){
			this.props.fetchTemplates()
		}

		this.scrollRestorationMap = new Map();
		this.lastScrollPosition = 0;

		this.paginationRef = createRef();

	}

	saveRef =  (el, side) => {

		if(el && !resizeMap.has(side) ){

			resizeMap.set(side, {
				el: el,
				component: this
			});
			resizeObserver.observe(el);

		} else if ( !el && resizeMap.has(side) ){

			const mapItem = resizeMap.get(side);
			resizeObserver.unobserve(mapItem.el);
			resizeMap.delete(side);

		} 
	}

	componentDidMount = () => {

		document.title = 'Cargo Lookbook™';

		this.paginationObserver = new IntersectionObserver(this.onPaginationIntersection, {
			root: document,
			rootMargin: (screen.height * 2) + 'px',
			threshold: [0,1]
		});

		if(this.paginationRef.current) {
			this.paginationObserver.observe(this.paginationRef.current);
		}

		window.addEventListener('scroll', this.onScroll, {
			passive: true
		})

	}

	componentWillUnmount = () => {

		resizeMap.forEach((key, mapItem)=>{
			if( mapItem.component === this){
				resizeObserver.unobserve(mapItem.el);				
				resizeMap.delete(key);
			}
		});

		this.paginationObserver.disconnect();

		window.removeEventListener('scroll', this.onScroll)

	}

	onPaginationIntersection = (entries) => {

		this.paginationRequired = entries[0].isIntersecting;

		if(this.paginationRequired) {
			// start paginating
			this.startPagination();
		}

	}

	getCurrentRenderIncrement = () => {
		return (this.props.category.key === 'all' || this.props.category.key === 'inuse') ? this.state.allRenderIncrement : this.state.categoryRenderIncrement
	}

	shouldComponentUpdate(nextProps, nextState) {

		// before switching to a new category
		if(this.props.category !== nextProps.category) {

			// grab the current and new locations
			const currentSlug = this.props.category?.slug
			const newSlug = nextProps.category?.slug

			// store current location scroll position and render limit
			if(currentSlug) {
				
				this.scrollRestorationMap.set(currentSlug, {
					scrollTop: this.lastScrollPosition,
					renderLimit: this.state.currentRenderLimit
				});

			}

			// get a default next render limit
			let nextRenderLimit = this.getCurrentRenderIncrement();

			// restore new location scroll position if stored
			if(newSlug && this.scrollRestorationMap.has(newSlug)) {

				const scrollRestorationData = this.scrollRestorationMap.get(newSlug);

				// restore last render limit
				nextRenderLimit = scrollRestorationData.renderLimit;

				// kick off scroll restoration
				requestAnimationFrame(() => {
					this.restoreScroll(scrollRestorationData.scrollTop)
				})

			}

			// Immediately set new render limit for the impending render
			nextState.currentRenderLimit = nextRenderLimit;

		}

		return true;

	}

	onScroll = (e) => {
		this.lastScrollPosition = document.scrollingElement.scrollTop;
	}

	restoreScroll = async (savedScrollPosition) => {

		this.scrollAttempts = 0;

		while (true) {

			if (
				// exceeded max attempts
				this.scrollAttempts > 10
				// or user already scrolled
				|| document.scrollingElement.scrollTop
			) {
				// bail
				break;
			}

			if ( document.scrollingElement.scrollHeight >= savedScrollPosition ) {

				// restore scroll
				document.scrollingElement.scrollTop = savedScrollPosition;

				// bail
				break;
			}

			this.scrollAttempts++;

			// wait 100ms till next attempt
			await new Promise(resolve => setTimeout(resolve, 100));

		}

	}

	componentDidUpdate(prevProps) {

		if(this.props.category !== prevProps.category) {
			// see if the new category needs pagination
			this.startPagination();
		}

	}

	startPagination = async () => {
		while (this.paginationRequired) {
			const { category, lookbook } = this.props;
			const { currentRenderLimit, allRenderIncrement, categoryRenderIncrement } = this.state;
	
			if (category.key === 'all' || category.key === 'inuse') {
				if (lookbook.periods.length > currentRenderLimit) {
					await new Promise(resolve => {
						this.setState(prevState => ({
							currentRenderLimit: prevState.currentRenderLimit + (allRenderIncrement || 4)
						}), () => {
							setTimeout(resolve, 100);
						});
					});
	
					continue;  // skip the rest of the loop
				}
			} else {
				if (this.getWorkByCategory(category.key).length > currentRenderLimit) {
					await new Promise(resolve => {
						this.setState(prevState => ({
							currentRenderLimit: prevState.currentRenderLimit + (categoryRenderIncrement || 50)
						}), () => {
							setTimeout(resolve, 100);
						});
					});
	
					continue;  // skip the rest of the loop
				}
			}
	
			// Ensure there's something to paginate before attempting
			if (lookbook.paginationComplete) {
				break;
			}
	
			// Fetch more data
			await this.props.fetchLookbookPeriods();
	
			// Wait 100ms before checking again
			await new Promise(resolve => setTimeout(resolve, 100));
		}
	}

	renderPhoneTile = (phoneItems, style={}, dimensions={width: 500, height: 500}, animation) => {

		const numberOfPhoneSlides = phoneItems.length;
		let lastItemIsClone = false;

		if ( numberOfPhoneSlides > 1 && animation !== 'none' ){
			phoneItems = [...
				phoneItems,
				phoneItems[0]
			]
			// phoneItems.push({...phoneItems[0]});
			lastItemIsClone = true;
		} else if(animation === 'none'){
			phoneItems.length = 1;
		}

		const phoneFrameJSX = [];
		const phoneCaptionJSX = [];
		const phoneJSX = phoneItems.forEach((item, index)=>{
			const hash = item.media.hash;
			const name = item.media.name;
			const imgWidth = item.media.width;
			const imgHeight = item.media.height;
			const srcWidth = Math.min( imgWidth, Math.ceil((dimensions.width*2)/250)*250);
			let instagramHref = item.instagram ? item.instagram : item.inuse_instagram_url ? item.inuse_instagram_url : null;
			let instagramTag = instagramHref ? instagramHref.replace(/^(https?:\/\/)?(www\.)?instagram\.com\/?/g, '').replace('/', "") : null;

			let imageSrc = `https://freight.cargo.site/w/${srcWidth}/q/75/i/${hash}/${name}`;

			const siteDirectLink = item?.url ? item.url : item.direct_link;
			const siteTitle      = item.name;

			const isCloned = index == phoneItems.length-1 && lastItemIsClone;
			const keyHash = isCloned ? 'clone-'+hash :hash;

			// for 'too-wide' portrait items, make them contain-sized
			const containImgClass = imgWidth/imgHeight > .8 ? ' contain-image': '';

			phoneFrameJSX.push(<a href={siteDirectLink} target="_blank" className={'phone-frame-link'+containImgClass} key={'phone-frame-link'+keyHash }>
				<img className={`phone-image`} src={imageSrc} />
			</a>)

			if( !isCloned ){
				phoneCaptionJSX.push(<div className="phone-image-caption" key={'phone-image-caption-'+keyHash}>
						<span className="lookbook-image-caption">
							<a href={siteDirectLink} target="_blank">{siteTitle}</a>
						</span>
						{instagramHref? ( 
							<span className="lookbook-image-caption">
								<a href={instagramHref} target="_blank">@{instagramTag}</a> 
							</span>
						) : null}
					</div>
				)
			}

		})


		return (
			<div className={'lookbook-entry phone animation-'+animation} key={`phone-cycle-${phoneItems[0].media.hash}`} style={style}>
				<div className="phone-frame" style={{
					backgroundImage: `url('${PUBLIC_URL}/images/phone-frame.png')`
				}}>
					<div className="phone-frame-border">
						<div className="animator-frame">
						{phoneFrameJSX}
						</div>
					</div>
				</div>
				<div className="lookbook-captions phone-captions space-filler"
					tabIndex="-1"
					data-nosnippet
				>
					<div className="position-absolute">
						{phoneCaptionJSX}
					</div>			
					<span className="lookbook-image-caption">{'-'}</span>
					<span className="lookbook-image-caption">{'-'}</span>
				</div>			
			</div>
		)
	}

	// Site tile within lookbook category
	renderSiteTile = (data, style={}, dimensions={width: 500, height: 500}) => {

		const hash = data?.inuse_screenshot ? data.inuse_screenshot?.hash : data?.media.hash ? data.media.hash : null;
		const name = data?.inuse_screenshot	? data.inuse_screenshot?.name : data?.media.name ? data.media.name : null;
		const imgWidth = data?.inuse_screenshot ? data.inuse_screenshot?.width : data?.media.width ? data.media.width : null;
		const imgHeight = data?.inuse_screenshot ? data.inuse_screenshot?.height : data?.media.height ? data.media.height : null;

		const srcWidth = Math.min( imgWidth, Math.ceil((dimensions.width*2)/250)*250);

		let imageSrc = `https://freight.cargo.site/w/${srcWidth}/q/75/i/${hash}/${name}`;
		let instagramHref = data.instagram ? data.instagram : data.inuse_instagram_url ? data.inuse_instagram_url : null;
		let instagramTag = instagramHref ? instagramHref.replace(/^(https?:\/\/)?(www\.)?instagram\.com\/?/g, '').replace('/', "") : null;

		const siteDirectLink = data?.url ? data.url : data.direct_link;
		const siteTitle      = data?.name ? data.name : data.inuse_website_title;

		var isPortrait = imgHeight > imgWidth + 10;

		const productName = data?.product_name ? data.product_name : null;
		const price = data?.price ? data.price : null;

		return (
			<div className="lookbook-entry" key={`${data.id}-${hash}`} style={style}>
				{/* <div style={{'position':'absolute','color':'red','fontWeight': '900','background':'black'}}>{data.category}++{data.id}</div> */}
				<a href={siteDirectLink} className="lookbook-image-frame" target="_blank">
					<img className={`lookbook-image${isPortrait ? ' portrait' : ''}`} src={imageSrc} />
				</a>
				<div className="lookbook-captions">
					{ productName ? 
						<span className="lookbook-image-caption">
							<a href={siteDirectLink} target="_blank">{productName}</a> 
						</span>
					: null }
					<span className="lookbook-image-caption">
						<a href={siteDirectLink} target="_blank">{siteTitle}</a>
					</span>
					{ price ? 
						<span className="lookbook-image-caption">
							<a href={siteDirectLink} target="_blank">{price}</a>
						</span>
					: null }
					{instagramHref && !price ? ( 
						<span className="lookbook-image-caption">
							<a href={instagramHref} target="_blank">@{instagramTag}</a> 
						</span>
					) : null}
				</div>
			</div>
		);

	}

	// 3. Inner layer of the lookbook layout. Categories
	renderGridGallery = (gridItemMap, side) => {


		let elWidth = this.state[side+'ColumnWidth'];

		const columns = 2;
		const gutterPixelWidth = 15;

		let hasWidth = elWidth!== 0;

		// do initial render with a default width and hide the results
		if( !hasWidth ){
			elWidth = 400
		}

		const gridLayout = generateGridLayout({
			elWidth: elWidth,
			columns: columns,
			gutterPixelWidth: gutterPixelWidth,
			vAlign: 'bottom',
			itemMap: gridItemMap
		});

		let categoryItemIndex = 0;
		const gridJSX = <div className="grid-layout"
			style={{
				visibility: hasWidth ? null : 'hidden',
				maxWidth: elWidth+'px',
			}}
		>{
			gridLayout.map((row, rowIndex)=>{

				return <div className="grid-row" key={"grid-row-"+rowIndex}
					style={{
						marginBottom: rowIndex === gridLayout.length -1 ? 0 : gutterPixelWidth+'px',
						display: 'flex'
					}}>{
					row.items.map((item, columnIndex)=>{

						const style ={
							'--item-width': item.pixelWidth+'px',
							'--item-height': item.pixelHeight+'px',
							marginTop: item.marginTop+ 'px',
							marginRight: item.lastColumn ? 0 : gutterPixelWidth+'px',
						}

						const dimensions = {
							width: item.pixelWidth,
							height: item.pixelHeight
						}
							
						const tile = <LookbookSiteTile 
										data={gridItemMap[categoryItemIndex].tileItem} 
										style={style}
										dimensions = { dimensions }
										key={categoryItemIndex+rowIndex}
										category={this.props.category.key}
									/>

						categoryItemIndex++;

						return tile;
					})
				}</div>

		})}</div>;

	
		return <div className="lookbook-sites-grid" ref={(el)=> { this.saveRef(el, side) }}>{gridJSX}</div>;
	};
	
	renderJustifyGallery = (justifyItemMap, side) => {

		let elWidth = this.state[side+'ColumnWidth'];
		let gutterPixelWidth = 25;

		if( side === 'center'){
			elWidth = this.state.leftColumnWidth +this.state.rightColumnWidth + 110 // 110 == 55px inner padding * 2
			gutterPixelWidth = 0;
		} else if ( side === 'full-bleed'){
			elWidth = this.state.leftColumnWidth +this.state.rightColumnWidth + 170 // 110 == 55px inner padding * 2 + 30px edge padding *2
			gutterPixelWidth = 0;
		}

		let hasWidth = elWidth!== 0;

		// do initial render with a default width and hide the results
		if( !hasWidth ){
			elWidth = 400
		}

		const phoneWidth = Math.floor(elWidth *.3);


		const justifyLayout = generateJustifyLayout({
			elWidth: elWidth,
			gutterPixelWidth: gutterPixelWidth,
			rowPixelHeight: 200, // doesn't matter, since the map has all of its row-ends predetermined this can be anything
			rowAlign: 'fill',  
			itemMap: justifyItemMap
		});

		
		let categoryItemIndex = 0;
		const justifyJSX = <div className={`justify-layout layout-${side}`}
			style={{
				visibility: hasWidth ? null : 'hidden',
				maxWidth: elWidth+'px',
			}}
		>{
			justifyLayout.map((row, rowIndex)=>{

				const isInPhoneRow = justifyItemMap[categoryItemIndex].tileItem?.isInPhoneRow ?? false;

				return <div className={`justify-row${isInPhoneRow ? ' phone-row' : ''}`} key={"justify-row-"+rowIndex}
					style={{
						marginBottom: rowIndex === justifyLayout.length -1 ? 0 : gutterPixelWidth+'px',
						display: 'flex',
						'--item-height': row.pixelHeight+'px',						
					}}>{
					row.items.map((item, columnIndex)=>{

						let tile

						const style ={
							'--item-width': item.pixelWidth+'px',
							marginRight: (item.rowEnd || item.lastChild) ? 0 : gutterPixelWidth+'px',
						}
						const dimensions = {
							width: item.pixelWidth,
							height: row.pixelHeight
						};

						if( isInPhoneRow ){

							if(justifyItemMap[categoryItemIndex].tileItem.aspect === 'portrait'){

								dimensions.width = phoneWidth;
								style['--item-width'] = dimensions.width+'px';

								tile = this.renderPhoneTile(justifyItemMap[categoryItemIndex].tileItem.phoneItems, style, dimensions, justifyItemMap[categoryItemIndex].tileItem.anim)

							} else {

								const initialWidth = dimensions.width;
								dimensions.width = (elWidth+-gutterPixelWidth) - phoneWidth;

								const scale = dimensions.width/initialWidth;
								dimensions.height = scale * dimensions.height;
								style['--item-height'] = dimensions.height+'px';
								style['--item-width'] = dimensions.width+'px';

								tile = <LookbookSiteTile 
										data={justifyItemMap[categoryItemIndex].tileItem} 
										style={style}
										dimensions = { dimensions }
										key={categoryItemIndex+rowIndex}
										category={this.props.category.key}
									/>
							}


						} else {

							tile = <LookbookSiteTile 
										data={justifyItemMap[categoryItemIndex].tileItem} 
										style={style}
										dimensions = { dimensions }
										key={categoryItemIndex+rowIndex}
										category={this.props.category.key}
									/>
						}


						categoryItemIndex++;

						return tile;
					})
				}</div>

		})}</div>;

	
		return <div className="lookbook-sites-grid" ref={(el)=> { this.saveRef(el, side) }}>{justifyJSX}</div>;
	};

	renderContentGallery = ( contentArray ) => {

		const galleryJSX = contentArray.map((site, rowIndex) => {
			const isSecondItemInRow = (rowIndex % 4 === 2);
			return <React.Fragment key={rowIndex}>
					{isSecondItemInRow ? ( <div className="column-spacer"></div> ) : null}
					<LookbookSiteTile 
						data={site} 
						category={this.props.category.key}
					/>
				</React.Fragment>
		});

		return <>{galleryJSX}</>;
	};

	// 2. Inner layer of the lookbook layout. Laptop + in use site
	renderInUseSites = (inUseArray, availbleImageWidth, availbleBaseImageWidth) => {

		const inUseSites = [];
		
		_.each(inUseArray, (site, i) => {

			if ( !site ) { return null }

			inUseSites.push( 
				<InUseLaptopTile 
					key={site.id+i} 
					data={site} 
					category={this.props.category.key}
					availbleImageWidth={availbleImageWidth}
					availbleBaseImageWidth={availbleBaseImageWidth}
				/> );

		})

		return inUseSites;
	};

	compileInUseLayout = (period) => {
		// Half page width, - 30px padding on either side, image is 83% of remaining width, minus 6px flat for internal padding.
		const availbleBaseImageWidth = ( window.innerWidth / 2 - 60 );
		const availbleImageWidth = ( availbleBaseImageWidth * 0.83 ).toFixed(2) - 6;

		return (
			<React.Fragment key={period.work_period_id}>
				{this.renderInUseSites(period.data.inuse, availbleImageWidth, availbleBaseImageWidth)} 
			</React.Fragment>
		)
	}

	// 1. Outer layer of the lookbook layout
	compileLookbookLayout = (periodMap, periodIndex) => {

		const spreadRowItem = periodMap.spreadMaps[0]?.[0];
		let spreadType = 'center';

		const availbleBaseImageWidth = ( window.innerWidth / 2 - 60 );
		const availbleImageWidth = ( availbleBaseImageWidth * 0.83 ).toFixed(2) - 6;

		// if it's a single image landscape, make it a full-bleed spread if the image is extra wide ( ratio > 1.8 );
		// use modulo to spread more full-bleed layouts around as desired
		if( spreadRowItem && periodMap.spreadMaps[0].length == 1){
			spreadType = ((periodIndex+1) % 9 == 0 || (periodIndex+6) % 7==0 )|| (spreadRowItem.width/ spreadRowItem.height > 1.8)  ? 'full-bleed': 'center'
		}
		
		return (
			<div className="lookbook-layout" key={periodMap.period.work_period_id}>
				<div className="lookbook-left laptop-side">
					<div className="lookbook-content" style={{ paddingTop: 0 }}>
						{this.renderInUseSites(periodMap.inUseMaps[0], availbleImageWidth, availbleBaseImageWidth)}
					</div>
				</div>
				<div className="lookbook-right">
					<div className="lookbook-content" style={{ paddingTop: 0 }}>
						{this.renderJustifyGallery((periodMap.lookbookMaps[0] || []), 'right')}
					</div>
				</div>
				<div className="lookbook-spacer"></div>
				<div className="lookbook-left">
					<div className="lookbook-content">
						{this.renderJustifyGallery((periodMap.lookbookMaps[1] || []) , 'left')}
					</div>
				</div>
				<div className="lookbook-right laptop-side">
					<div className="lookbook-content">
						{this.renderInUseSites(periodMap.inUseMaps[1], availbleImageWidth, availbleBaseImageWidth)}
					</div>
				</div>
				<div className="full-spread">{this.renderJustifyGallery((periodMap.spreadMaps[0] || []), spreadType)}</div>
			</div>
		);
	}

	renderFullCategory = (data) => {

		if( !data ){
			return null
		}

		return (
			<div className="lookbook-layout" key={this.props.category}>
				<div 
					className="lookbook-content flat-map" 
				>
					{this.renderContentGallery(data)}
				</div>
			</div>
		);
	}


	getWorkByCategory = (category) => {

		return this.props.lookbook.periods.reduce((acc, period) => {
			return acc.concat(period.data[category])
		}, []);

	}

	render() {

		let categoryJSX = null;

		if( this.props.category.key === 'all' ) {

			// artificially limit so we don't render hundreds of items at the same time
			const periodsToRender = this.props.lookbookMapCollection.slice(0, this.state.currentRenderLimit);

			categoryJSX = <> 
							<div className="lookbook-throughline-left"></div>
							<div className="lookbook-throughline-right"></div>
							<div className="lookbook-throughline-center"></div>
							<div className="all-title">Endless Lookbook™</div>
							{periodsToRender.map(this.compileLookbookLayout)} 
						</>
		
		} else if( this.props.category.key === 'inuse' ) {

			// artificially limit so we don't render hundreds of items at the same time
			const periodsToRender = this.props.lookbook.periods.slice(0, this.state.currentRenderLimit);

			categoryJSX = <>
							<div className="lookbook-throughline-left"></div>
							<div className="lookbook-throughline-right"></div>
							<div className="lookbook-throughline-center"></div>
							<div className={`category-view`}>
								{/* <div className={`category-title`}>
									<hr />
									In Use
								</div> */}
								<div className={`lookbook-layout`}>
									<div className={`lookbook-content`}>
									{periodsToRender.map(this.compileInUseLayout)}
									</div>
								</div>
							</div>
						</>

		} else {

			// artificially limit so we don't render hundreds of items at the same time
			const workByCategory = (this.getWorkByCategory(this.props.category.key) || []).slice(0, this.state.currentRenderLimit);

			categoryJSX = <>
				<div className="lookbook-throughline-left"></div>
				<div className="lookbook-throughline-right"></div>
				<div className="lookbook-throughline-center"></div>
				<div className={`category-view ${this.props.category.key}`}>
				{/* <div className={`category-title`}>
					<hr />
					{this.props.category.title}
				</div> */}
					{this.renderFullCategory(workByCategory)}
				</div>
			</>

		}

		return <div className={`lookbook light-mode${this.props.category.key ? ' '+this.props.category.key : ''}`}>
			{categoryJSX}
			<div ref={this.paginationRef}></div>
		</div>
	}

}

const makeLayoutsFromCollection = (collection, ratioLimit, layoutLimit=9e9, layoutType="default" , periodIndex)=>{



	const ratios = layoutType === 'spread' ? {
		extrawide: 1.7,
		wide: 1.0,
		portrait: 1.0
	} : {
		extrawide: 1.7,
		wide: 1.2,
		portrait: .9
	}

	const [portraitModels, nonPortraitModels] = _.partition(collection, (item)=>{
		let ratio
		if( !item.media){
			ratio = item.inuse_screenshot.width / item.inuse_screenshot.height;
		} else {
			ratio = item.media.width / item.media.height;
		}
		
		return ratio  < ratios.portrait;
	});

	const [wideishModels, squarishModels] = _.partition(nonPortraitModels, (item)=>{

		let ratio
		if( !item.media){
			ratio = item.inuse_screenshot.width / item.inuse_screenshot.height;
		} else {
			ratio = item.media.width / item.media.height;
		}

		return ratio  > ratios.wide ;
	})

	let [landscapeModels, extraWideModels] = _.partition(wideishModels, (item)=>{

		let ratio
		if( !item.media){
			ratio = item.inuse_screenshot.width / item.inuse_screenshot.height;
		} else {
			ratio = item.media.width / item.media.height;
		}

		return ratio  < ratios.extrawide;
	});


	if( layoutType =='spread'){
		landscapeModels = landscapeModels.filter(item=>item.media?.width > 1500);
		extraWideModels = extraWideModels.filter(item=>item.media?.width > 1500);

		if( extraWideModels.length == 0 && landscapeModels.length > 0){

			const maxWideItem = _.maxBy(landscapeModels, (model)=>{
				if( !model.media){
					return 0
				}
				return model.media.width / model.media.height;
			});

			extraWideModels.push(maxWideItem);
		}

		landscapeModels.length =0;
	}


	const patterns = {
		'1a':{
			pattern: ['extrawide'],
			following: ['2c', '2a', '2e', '2d', '2b', '3a'],
			limit: 2,
			rarity: 2,
			rowsSince: 0,
		},		
		'phoneA':{
			pattern: ['portrait', 'portrait', 'portrait', 'landscape'],
			following: ['2b', '1a', '3a'],
			rarity: 1,
			limit: 2,
			rowsSince: 0,
		},
		'phoneB':{
			pattern: ['landscape', 'portrait', 'portrait', 'portrait'],
			following: ['2a', '1a','2c', '3a'],
			rarity: 1,
			limit: 2,
			rowsSince: 0,
		},		


		'2d':{
			pattern: ['square', 'landscape',],
			following: ['2b',  '2c', '2e', '1a', '3a'],
			rarity: 1,
			rowsSince: 0,
		},
		'2e':{
			pattern: ['landscape', 'square',],
			following: ['2a', '2d', '1a', '3a'],
			rarity: 1,
			rowsSince: 0,
		},
		'2c':{
			pattern: ['square', 'square'],
			following: ['1a','3a', '2d', '2e'],
			rarity: 1,
			rowsSince: 0,
		},


		'2a':{
			pattern: ['portrait', 'landscape'],
			following: ['2b', '1a', '3a'],
			rarity: 3,
			rowsSince: 0,
		},
		'2b':{
			pattern: ['landscape', 'portrait'],
			following: ['2a', '1a','2c', '3a'],
			rarity: 3,
			rowsSince: 0,
		},

		'3a':{
			pattern: ['portrait', 'square', 'portrait'],
			following: ['1a', '2c', '3a'],
			rarity: 1,
			rowsSince: 0,
		},
	}


	if( layoutType =='spread'){
		Object.keys(patterns).forEach(key=>{
			if( key !== '1a' && key !== '2c'){
				delete patterns[key];
			}
		})
	} 

	const rowBuckets = Object.keys(patterns).reduce((accumulator, key)=>{
		return {
			...accumulator,
			[key]: new Array()
		}
	}, {
		'spare': new Array(),
		'phoneA': new Array(),
		'phoneB': new Array(),
	});
	
	const skipped = [];

	const keys =Object.keys(patterns);
	let incrementIndex = -1;


	// just loop through all content generating rows
	while(landscapeModels.length > 0  || squarishModels.length > 0|| portraitModels.length > 0 || extraWideModels.length > 0){

		incrementIndex++;

		const rowType = keys[incrementIndex%keys.length];
		const pattern = patterns[rowType].pattern;
		const requirements = _.countBy(pattern);
		let skip = false;

		if( skipped.includes(rowType) ){
			skip = true;
		} else {

			// check if required arrays are full enough
			for (const [type, number] of Object.entries(requirements)) {

				switch(type){
					case "extrawide":
						if( extraWideModels.length < number){
							skip = true;
						}
						break;

					case "landscape":
						if( landscapeModels.length < number && extraWideModels.length < number){
							skip = true;
						}
						break;
					case "square":
						if( squarishModels.length < number){
							skip = true;
						}
						break;

					case "portrait":
						if( portraitModels.length < number){
							skip = true;
						}
						break;
				}
			}
		}

		if( pattern.limit !== undefined && rowBuckets[rowType].length-1 >= pattern.limit){
			skip = true;
		}

		if(skip){

			if( !skipped.includes(rowType) ){
				skipped.push(rowType);
			}

			// if we've exhausted all the patterns, just pop two-per-row into the rows
			if(skipped.length == keys.length){

				const rowData = {
					consistsof: '',
					rowType: 'spare',
					ratio: 0,
					row: []
				}
				for(var i = 0; i < 2; i++){

					let item
					if( squarishModels.length > 0){

						rowData.consistsof+= ' squarish'
						item = squarishModels.shift();

					} else if ( landscapeModels.length > 0 || extraWideModels.length > 0 ){

						rowData.consistsof+= ' landscape'

						if( landscapeModels.length == 0  && extraWideModels.length > 0){
							item = extraWideModels.shift();
						} else {
							item = landscapeModels.shift();							
						}

					} else if (portraitModels.length > 0 ){
						rowData.consistsof+= ' portrait'
						item = portraitModels.shift();	
					}

					if( item){

						let ratio
						if( !item.media){
							ratio = item.inuse_screenshot.width / item.inuse_screenshot.height;
						} else {
							ratio = item.media.width / item.media.height;
						}						
						rowData.ratio = rowData.ratio + ratio
						rowData.row.unshift(item);
					}
				}

				// only allow spare rows that consist of two
				if( rowData.row.length > 1){
					rowBuckets.spare.unshift(rowData);
				}
				

			}

			continue;

		} else {

			const rowData = {
				ratio: 0,
				row: []
			}
			for(var i = 0; i < pattern.length; i++){
				const type = pattern[i];


				let item;

				switch(type){
					case "extrawide":
						item = extraWideModels.shift();
						item.aspect = 'extrawide';
						break;

					case "landscape":
						if( landscapeModels.length == 0  && extraWideModels.length > 0){
							item = extraWideModels.shift();
						} else {
							item = landscapeModels.shift();	
						}
						item.aspect = 'landscape';
						break;
					case "square":
						item = squarishModels.shift();
						item.aspect = 'square';
						break;

					case "portrait":
						item = portraitModels.shift();
						item.aspect = 'portrait';
							
						break;
				}

				// incomplete
				if(!item){
					break;
				}

				let ratio

				if( !item.media){
					ratio = item.inuse_screenshot.width / item.inuse_screenshot.height;
				} else {
					ratio = item.media.width / item.media.height;
				}					
				rowData.rowType = rowType;
				rowData.ratio = rowData.ratio + ratio;
				rowData.row.unshift(item);

			}

			if( rowData.row.length > 0 ){

				// phones are more variable because they take up more or less space depending on the layout
				// so we assume a rough aspect ratio of 1.4 for phone rows, since they are generally pretty tall
				if( rowType == 'phoneA' || rowType == 'phoneB') {
					rowData.ratio = 1.4;
				}				
				rowBuckets[rowType].unshift(rowData);
			}

			

		}

	}

	// make sure not to allow 'spare' rows in full-width spreads
	if( layoutType === 'spread'){
		rowBuckets.spare = [];
		rowBuckets.phoneA = [];
		rowBuckets.phoneB = [];
	}


	// keep a list of used IDs handy so we know what to remove from the "category" listings
	const usedIds = [];

	let bucketsHaveContent = Object.keys(rowBuckets).some(key=>rowBuckets[key].length > 0 );

	const layoutChunks = [];
	const targetAspectRatio = ratioLimit ? ratioLimit : .000001 // width/height

	// prefer to start with 2e, otherwise find first populated row
	let nextPattern = '2e';

	// Spread-type layouts only have two options
	// use the period index to aim for a smattering of square rows
	// they will however default to square rows more often since there are not always valid 1a rows
	if( layoutType === 'spread'){
		nextPattern =  ((periodIndex+1)%6==0 || (periodIndex+3)%4==0 )? '2c' : '1a';
	}

	if( rowBuckets[nextPattern]?.length == 0 ){
		nextPattern = Object.keys(rowBuckets).find(key=>{
			return rowBuckets[key].length > 0 ;
		});
	}
	

	while(bucketsHaveContent && nextPattern && layoutChunks.length < layoutLimit){

		const layout = [];
		let iteration = 0;
		let phoneRowsGenerated = 0;
		const phoneItemsToGenerate = 2;


		// the aspect ratio will decrease as we add more rows
		let currentRatio = 9999;

		while (currentRatio > targetAspectRatio && bucketsHaveContent){

			let isPhoneRow = false;
			let anim = 'none'
			if( layoutType === 'default'){

				// generate phone row once every five rows
				// first using [portrait, landscape] ( phoneA )
				// then using [landscape, portrait] (phoneB)

				if( phoneRowsGenerated < phoneItemsToGenerate  && (iteration+1)%2==0){

					// use this to get a 'random-ish' yet determinate value
					const animSeed = (iteration*7.3+ layoutChunks.length*3.2+rowBuckets['spare'].length)%10;

					switch (true) {

						case animSeed < 4:
							anim = "swipeup";
							break;
						case animSeed < 8:
							anim = "swipeleft";
							break;
						default:
							anim = "swipediagonal"
							break;
					}



					if ( layoutChunks.length%2==0 ){
						nextPattern = phoneRowsGenerated%2== 0 ? 'phoneA' : 'phoneB';			
					} else {
						nextPattern = phoneRowsGenerated%2== 0 ? 'phoneB' : 'phoneA';
					}


					if( rowBuckets[nextPattern].length == 0 ){
						nextPattern = Object.keys(rowBuckets).find(key=>{
							return rowBuckets[key].length > 0 ;
						});
					}

					if( nextPattern === 'phoneA' || nextPattern==='phoneB'){
						phoneRowsGenerated++;
						isPhoneRow = true;						
					}


				// otherwise regularly insert graphic design rows
				} else if( iteration % 3 == 0 && iteration > 0 && rowBuckets['2c'].length > 0){
					nextPattern = '2c'
				} 

				
			}


			// find index of first apppropriately-sized row
			const candidateRowIndex = rowBuckets[nextPattern].findIndex((item, index)=>{

				if( index == rowBuckets[nextPattern].length -1 ){
					return true
				} else if( currentRatio == 9999){
					return item.ratio > 2
				} else {
					return 1/(1/item.ratio + 1/currentRatio) < targetAspectRatio
				}					
			})

			// grab row from bucket
			const candidateRow = rowBuckets[nextPattern][candidateRowIndex];			

			// delete row from bucket
			rowBuckets[nextPattern].splice(candidateRowIndex, 1);

			// collect flat row of items into single landscape + portrait
			// and put all portrait items into a phoneItems array
			if (isPhoneRow ){
				const [phoneItems, landScapeItems] = _.partition(candidateRow.row, (item)=>{
					return item.aspect === 'portrait';
				});

				candidateRow.row = _.uniqBy(candidateRow.row, item=>item.aspect);
				candidateRow.phoneItems = phoneItems;
				candidateRow.anim = anim;
			}
			candidateRow.isPhoneRow = isPhoneRow;

			// increment
			Object.keys(patterns).forEach(patternName=>{
				if( patternName == nextPattern){
					patterns[patternName].rowsSince = 0;
				} else if( patternName!== 'spare') {
					patterns[patternName].rowsSince = patterns[patternName].rowsSince +1;
				}
			});

			if( currentRatio == 9999){
				currentRatio = candidateRow.ratio;
			} else {
				currentRatio = 1/(1/currentRatio + 1/candidateRow.ratio);
			}

			// use iteration value to scramble the search keys
			// so it doesn't always look for the next item in the same place
			const rowBucketKeys = Object.keys(rowBuckets);

			for(var i = 0; i < iteration%rowBucketKeys.length; i++){
			   rowBucketKeys.push(rowBucketKeys.shift())
			}


			nextPattern = rowBucketKeys.find(key=>{

				return key !== 'spare' && key !=='phoneA' && key!=='phoneB' && patterns[key].rowsSince >= patterns[key].rarity && rowBuckets[key]?.length > 0 && patterns[nextPattern]?.following.includes(key);
			});

			// if nothing, loop through whatever available bucket
			if( !nextPattern ){
				nextPattern = rowBucketKeys.find(key=>{
					return rowBuckets[key]?.length > 0 ;
				});
			}

			if( !nextPattern || layoutChunks.length >= layoutLimit){
				bucketsHaveContent = false;
			}

			candidateRow.row.forEach(item=>{
				usedIds.push(item.id)
			})
			layout.unshift(candidateRow);				

			iteration++;

		}

		// take spare rows and intersperse them in the layout instead of 
		// grouping them all at the end

		const finalLayout = [];
		const [spareRows, nonSpareRows] = _.partition(layout, (row)=>{
			return row.rowType === 'spare';
		});

		var i =0;
		while(spareRows.length > 0 || nonSpareRows.length > 0){

			if( nonSpareRows.length >0 ){
				finalLayout.push(nonSpareRows.pop());
			}
			if( spareRows.length >0 ){
				finalLayout.push(spareRows.pop());
			}			

		}

		if( finalLayout.length > 0){
			// if we end in a 1a row, stick it in the middle
			if( finalLayout[finalLayout.length-1]?.rowType === '1a' ){
				const singleItemRow = finalLayout.pop();
				finalLayout.splice( Math.floor(finalLayout.length/2), 0, singleItemRow);
			}

			layoutChunks.push(finalLayout);			
		}

		
	}

	const justifyMaps = layoutChunks.map(layout=>{

		return layout.flatMap((rowData)=>{

			return rowData.row.flatMap((rowItem,index) =>{

				rowItem.isInPhoneRow = rowData.isPhoneRow;
				if( rowData.isPhoneRow && rowItem.aspect == 'portrait' ){
					rowItem.phoneItems = rowData.phoneItems;
					rowItem.anim = rowData.anim;
				}

				return {
					type: rowData.rowType,
					tileItem: rowItem,
					// these optional item-level settings can be modified on an item-by-item basis
					rowEnd: index === rowData.row.length-1,
					// the natural dimensions of the image
					width: rowItem.media?.width ?? rowItem.inuse_screenshot?.width,
					height: rowItem.media?.height ?? rowItem.inuse_screenshot?.height,

					// the separated dimensions of the content box and padding/border of the image itself
					// this is mostly for the frontend context, but if you add borders to the <img> you can compensate
					// for them here
					innerPad: {
						horizPadSize: 0,
						vertPadSize: 0,
					},

					// the separated dimensions of the content box and padding/border of the item surrounding the image
					// eg. a border that surrounds both the image and caption
					outerPad: {
						horizPadSize: 0,
						vertPadSize: 0,
					},
				}
			})

		});
	})

	return {justifyMaps, usedIds};
}


function mapReduxStateToProps(state, ownProps) {

	const category = _.find(categoryList, {
		slug: ownProps.match.params.category
	}) || categoryList[0];

	const inuseList = state.lookbook.periods.flatMap(period=>period.data.inuse)

	const inUseSitesPerLayout = 4;

	const doubledPeriods = state.lookbook.periods.reduce((accumulator, period, index)=>{

		if( index%2==0){

			return [
				...accumulator,
				_.cloneDeep(period),
			]

		} else {

			// combine two periods in order to increase image library size
			if( accumulator[accumulator.length-1]){
				Object.keys(period.data).forEach(key=>{
					accumulator[accumulator.length-1].data[key] = _.cloneDeep(period.data[key]).concat(accumulator[accumulator.length-1].data[key])				
				})
				return accumulator;

			} else {

				return [
					...accumulator,
					_.cloneDeep(period)
				]
			}
			
		}
	},[])

	const lookbookMapCollection = doubledPeriods.map((period, periodIndex)=>{

		// start by generating spreads using all available content
		let lookbookModels = Object.keys(period.data).reduce((accumulator, key)=>{

			if( key==='inuse' || key==='shop' ){
				return accumulator
			} else {

				return [
					...accumulator,
					...period.data[key]
				]
			}

		}, [])

		// generate two lists per doubled-period:
		const inUseMaps = [];
		for(var j = 0; j < 2; j++){
			inUseMaps[j] = [];
			for(var i = 0; i < inUseSitesPerLayout; i++){
				inUseMaps[j].push(inuseList.shift());
			}			
		}

		const {
			justifyMaps:spreadMaps,
			usedIds
		} = makeLayoutsFromCollection(lookbookModels, 10, 1, 'spread', periodIndex);

		lookbookModels = lookbookModels.filter(item=>!usedIds.includes(item.id))

		const {
			justifyMaps:lookbookMaps,
		} = makeLayoutsFromCollection(lookbookModels, .39, 2, 'default', periodIndex);		


		return {
			inUseMaps,
			period,
			spreadMaps,
			lookbookMaps,
		}

	})

	return {
		category,
		lookbook: state.lookbook,
		lookbookMapCollection,
		hasTemplates: state.homepageState.hasTemplates,
		loadingTemplates: state.homepageState.loadingTemplates,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchLookbookPeriods      : actions.fetchLookbookPeriods,
		fetchTemplates           : actions.fetchTemplates,
		updateHomepageState      : actions.updateHomepageState,
	}, dispatch);

}


export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(Lookbook)
