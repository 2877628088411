import React from 'react';
import _ from 'lodash'
import { NavLink, withRouter } from 'react-router-dom';

export const SectionHeader = withRouter(({ folder, isPublic, ...props }) => {

    const sectionColor = !isPublic ? folder.section.color : null;
    const sectionTitle = !isPublic ? folder.row?.title : folder.name;
    const sectionDescription =  !isPublic ? folder.row.description || `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In justo risus, tristique in finibus vitae, eleifend sed odio. Phasellus eget.` : null;
    const sectionLabel = !isPublic ? folder.row.label : null;

    return(
        <div className="section-header">
            <div className="title-nav">
                <a
                    onClick={(e) => {
                        props.history.push("/designlab/", {
                            restoreScroll: true
                        });
                    }}
                    className={`route back`}
                >
                    <div className="back-arrow"></div>
                    {/* <svg viewBox="0 0 38 38" style={{enableBackground: 'new 0 0 38 38', visibility: 'hidden'}}>
                        <path className="circle" d="M19,1L19,1C29,1,37,9,37,19l0,0c0,9.9-8.1,18-18,18l0,0C9.1,37,1,28.9,1,19l0,0C1,9,9.1,1,19,1z"/>
                        <path className="arrow" d="M28.5,19.8v-1.8H14.9l-2.3,0.1l1.9-1.6l3.4-3.4L16.7,12l-7.2,7l7.2,7l1.2-1.2l-3.5-3.4l-1.8-1.6l2.3,0.1H28.5z"/>
                    </svg> */}
                </a>

                <NavLink
                    to={'/designlab/' + folder.slug}
                    activeClassName='active'
                    exact
                    className={`route name`}
                    style={{backgroundColor: sectionColor}}
                >
                    {sectionTitle}<span className="right-arrow">&nbsp;→</span>
                </NavLink>
            </div>
            <div className="description">
                <span dangerouslySetInnerHTML={{__html: sectionDescription}}></span>
                <br/>
                { sectionLabel ? ( <div className="label">{sectionLabel}</div> ) : null }
            </div>

            {folder.is_idea ? <span className="row-badge">Ideas</span> : ''}
            {folder.is_element ? <span className="row-badge">Site Elements</span> : ''}
            {folder.is_community ? <span className="row-badge">Community</span> : ''}
        </div>
    )

})