import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import _ from 'lodash'
import { NavLink, withRouter } from 'react-router-dom';
import { helpers } from "@cargo/common";
import { DLGroupings } from "./designlabdata.js";
import DesignLabXScroll from './x-scroll.js';

import PenguinIcon from "@cargo/common/icons/penguin-lookaround.svg";

let isDev = CARGO_ENV !== 'production';

class XScrollHandler extends Component {

	constructor(props) {

		super(props);

		this.state = {
			progressXScroll: null,
			xScrollRefs: [],
			vimeoThumbs: {},
		}

		this.pushItemIndex = null;
		this.xScrollListener = _.debounce(this.xScrollListener, 5);

		this.showPenguin = false;

	}

	setupXScrollRefs = () => {

		if (!this.props.activeFolder && this.props.folders?.length > 0) {

			let xScrollRefs = [];

			this.props.folders?.map(folder => {
				if (folder) {
					xScrollRefs[folder.id] = React.createRef();
				}
			})

			this.setState({xScrollRefs: xScrollRefs})

		} else {
			this.setState({xScrollRefs: []})
		}
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.folders !== this.props.folders && this.props.folders.length > 0) {
			this.setupXScrollRefs();
		}

	}

	// scrollToSite = (siteRef) => {
	// 	console.log("scroll to", siteRef )
	// 	if (siteRef?.current) {
	// 		siteRef.current.scrollIntoView({top: 100})
	// 	}
	// }

	componentDidMount = () => {

		if (this.props.folders.length > 0) {
			this.setupXScrollRefs();
		}

		let videos = _.filter(DLGroupings[3].rows, {'type': 'video'})[0]?.vimeoList;
		_.each(videos, (video)=>{
			this.getThumb(video.id);
		});

	}

	componentWillUnmount = () => {

	}	

    getThumb = (id) => {
		var oembedURL = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+id+'&width=640';

		fetch(oembedURL, {
			mode: 'cors'
		}).then((response) => {
			response.json().then(json=>{

				this.setState((prevState)=>{
					return {
						...prevState,
						vimeoThumbs: {
							...prevState.vimeoThumbs, 
							[id]: json.thumbnail_url
						}
					}
				});;
			})
		})
	}

	onXScrollMouseDown = (e) => {

		let xScrollEl = this.state.xScrollRefs[parseInt(e.currentTarget.getAttribute('folder-id'))]?.current;

		if (xScrollEl?.classList.contains('slow-scroll')) {
			cancelAnimationFrame(this.slowScrollAnimFrame)
			this.slowScrollAnimFrame = undefined;
		}

		if ( e.button === 2 ) {
			return;
		}
		
	}

	getNextXScrollItem = (xScrollEl, forceEl, reverse = false) => {
		let xScrollPaddingLeft = parseInt(window.getComputedStyle(xScrollEl, null).getPropertyValue('padding-left')) + parseInt(window.getComputedStyle(xScrollEl.closest('.row'), null).getPropertyValue('margin-left'));
		let xScrollRect        = xScrollEl.getBoundingClientRect();
		let xScrollChildEls    = Array.from(xScrollEl.children)
		let nextSiteEl          = null;

		if (!forceEl) {

			for ( const index in xScrollChildEls ){
				const siteEl = xScrollChildEls[index];
				const siteElBounds = siteEl.getBoundingClientRect()
				const siteLeft = parseInt(siteElBounds.left);
				const siteRight = siteLeft + parseInt(siteElBounds.width);

				if ( siteRight >= xScrollRect.right && !reverse ) {
					nextSiteEl = siteEl;
					break;
				}
				
				// Not sure maybe this will work for reverse....
				// if (siteLeft <= xScrollRect.left && reverse ) {
				// 	nextSiteEl = siteEl;
				// 	break;
				// }

				if( index >= xScrollChildEls.length - 1 ){
					nextSiteEl = siteEl;
					break;
				}
			}

			

		} else {
			nextSiteEl = forceEl;
		}

		let nextSiteElBounds = nextSiteEl ? nextSiteEl.getBoundingClientRect() : null;
		let nextSiteElLeft = nextSiteElBounds ? parseInt(nextSiteElBounds.left) : 0;
		return {el: nextSiteEl, left: nextSiteElLeft - xScrollPaddingLeft};

	}

	pushXScroll = (target, pushURL, folderID) => {

		let xScrollEl = this.state.xScrollRefs[parseInt(folderID)]?.current;
		if( !xScrollEl?.children ){ return }
		let xScrollItems =  Array.from(xScrollEl.children);

		if (xScrollEl.classList.contains('slow-scroll')) {
			cancelAnimationFrame(this.slowScrollAnimFrame)
			this.slowScrollAnimFrame = undefined;
		}

		if (xScrollEl.closest('.row').classList.contains('scroll-end')) {
			this.props.history.push(pushURL);
		}

		xScrollEl.classList?.add('transition');
		let startLeft = xScrollEl.scrollLeft;
		let gap = document.documentElement.clientWidth * .02 // 2vw gap
		let distance = xScrollEl.firstElementChild.getBoundingClientRect().width;

		let transitionTime = 600;
		let frames = transitionTime/16.6666;
		let currentFrame = 0;
		let progress = 0;
		let nextItem = null;
		let scrollDistance = null;

		// if there is a current animation going, cancel and push to the next index
		if (this.scrollAnimationFrame) {
			cancelAnimationFrame(this.scrollAnimationFrame);
			nextItem = this.getNextXScrollItem(xScrollEl, xScrollItems[this.pushItemIndex + 1]);
			scrollDistance = nextItem.left;
		// just do the normal thing
		} else {
			nextItem = this.getNextXScrollItem(xScrollEl);
			scrollDistance = nextItem.left// find next spot
		}

		this.pushItemIndex = _.indexOf(xScrollItems, nextItem.el);
		let scrollDeltaX = scrollDistance - 60;

		let incrementScroll = ()=>{

			let lastProgress = progress
				progress = currentFrame/frames;
			let easedValue = helpers.easeInOutCubic(progress);

			if( progress <= 1){
				xScrollEl.scrollLeft = startLeft + scrollDeltaX*easedValue;
				this.scrollAnimationFrame = requestAnimationFrame(incrementScroll);

			} else {
				xScrollEl.classList.remove('transition');
				this.scrollAnimationFrame = null;
				this.pushItemIndex = null;
			}
		
			currentFrame++;
		}

		requestAnimationFrame(incrementScroll);
	}

	xScrollListener = (e) => {

		let folderID = e.target.getAttribute('folder-id');
		let xScrollEl = this.state.xScrollRefs[parseInt(folderID)]?.current;

		if(!xScrollEl) {
			return;
		}

		setTimeout(() => {

			if(!xScrollEl) {
				return;
			}

			let scrollLeft = xScrollEl.scrollLeft;
			let scrollRight = scrollLeft + xScrollEl.getBoundingClientRect().width;

			if (xScrollEl.scrollWidth - scrollRight <= 70) {
				xScrollEl.closest('.row').classList.add('scroll-end')
			} else {
				xScrollEl.closest('.row').classList.remove('scroll-end')
			}

		}, 60)

		if (xScrollEl.scrollLeft > 0) {
			xScrollEl.closest('.row').classList.add('scrolled')
		} else {
			xScrollEl.closest('.row').classList.remove('scrolled')
		}
		
	}

	render() {

		const renderPenguin = this.props.folders.length > 0 && !this.props.activeFolder && !this.props.sitePreview.previewingSite && this.props.folders && this.showPenguin;

		return (
			<>
                {renderPenguin ? ( 
                    <div 
                        id="penguin"
                        className={`${!this.props.authenticated ? ' unclickable' : ''}`}
                        onClick={()=>{
                            this.props.history.push("/designlab/sites-in-use");
                        }}
                    >
                        <PenguinIcon/>
                    </div> 
                ) : ( null )}

                {DLGroupings.map((section, index) => {

                    if( _.isEmpty(this.props.folders) ){ 
                        return <Fragment key={index}>{null}</Fragment> 
                    }

                    const anyFolderHasSites = _.some(this.props.folders, (folder) => {
                        return folder.sites.length > 0 && section.rows.some((row) => (isDev ? row.dev : row.live) === folder.id);
                    });

                    if( anyFolderHasSites ){
                        this.showPenguin = true;
                    }

                    return (
                        <div className="section" section-index={index} key={`section-${index}1`}>
                            <div className="section-intro">
                                <div className="section-left">
                                    <div className="title">{section.title}</div>
                                    <div className="description">{section.description}</div>
                                </div>
                                {section.hint && <div className="hint" dangerouslySetInnerHTML={{__html: section.hint}}></div>}
                            </div>

                            <div className="rows">
                                {section.rows?.map((folder, index)=>{

									const folder_id = isDev ? folder.dev : folder.live;

									return (
                                        <DesignLabXScroll 
                                            key={`folder-${folder_id}`}

                                            folderId        = { folder_id }
                                            section         = { section }
                                            xScrollRefs     = { this.state.xScrollRefs }
                                            progressXScroll = { this.state.progressXScroll }
                                            message         = { this.props.message }
                                        
                                            login 	              = { this.props.login }

                                            pushXScroll           = { this.pushXScroll }
                                            onXScrollMouseDown    = { this.onXScrollMouseDown }
                                            xScrollListener       = { this.xScrollListener }

                                            vimeoThumbs            = { this.state.vimeoThumbs }
                                            setVimeoPreview		   = { this.props.setVimeoPreview }

                                            duplicateTemplate 	   = { this.props.duplicateTemplate }
                                            setForDuplication      = { this.props.setForDuplication }
                                        
                                        />
                                    )
                                })}

                            </div>
                        </div>
                    )}
                )}
			</>
		)
	}

}

function mapReduxStateToProps(state, ownProps) {
	
	return {
		folders: state.templates,
		templates: state.templates,
		activeFolder: state.templates.find(folder => folder.slug === ownProps.match.params.folder),
		authenticated: state.auth.authenticated,
		sitePreview: state.sitePreview,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		updateHomepageState: actions.updateHomepageState,
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(XScrollHandler))