import React from 'react';
import _ from 'lodash'

export const XScrollNav = ({ folder, pushXScroll }) => {

    return(
        <div className="navigation">
            <button 
                className="next"
                onMouseDown={(e)=>{
                    pushXScroll(
                        e.currentTarget.closest('.navigation').nextElementSibling,
                        '/designlab/' + folder.slug,
                        folder.id
                    )
                }}
                folder-id={folder.id}
            >
                <svg className="row-arrow right-arrow" x="0px" y="0px" viewBox="0 0 36 36">
                    <rect width="36" height="36" rx="36"></rect>
                    <path d="M22.3,28l-10-10l10-10"></path>
                </svg>
            </button>
        </div>
    )

}
