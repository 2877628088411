import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import apiMiddleware from "./middleware/api";

import reducers from "./reducers";

export default () => {

	const enhancers = [];

	const middleware = [
		thunk,
		apiMiddleware()
	];

	const composedEnhancers = compose(
		applyMiddleware(...middleware),
		...enhancers
	);

	// Do we have preloaded state available?
	const initialState = window.__PRELOADED_STATE__ || {};

	// Create the store
	const store = createStore(
		reducers,
		initialState,
		composedEnhancers
	);

	window.store = store;
	
	// Delete it once we have it stored
	delete window.__PRELOADED_STATE__;

	return {
		store
	};

};