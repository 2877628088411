import React, {useEffect, useState, useRef} from 'react';
import { NavLink } from 'react-router-dom';
import { categoryList } from './lookbook';
import _ from 'lodash'

let prevScrollPos = window.scrollY;

const LookbookNavigation = props => {

    const [lookbookNavigationHeight, setLookbookNavigationHeight] = useState(0);
    const [mainNavigationHeight, setMainNavigationHeight] = useState(0);
    const [showNav, setShowNav] = useState(false);
    
    const lookbookNavigationRef = useRef(null);
    
    useEffect(() => {

        const handleScroll = _.throttle(() => {
            const currentScrollPos = window.scrollY;

            if (currentScrollPos > prevScrollPos ) { // Scrolling up
                setShowNav(false);
            } else { // Scrolling down
                setShowNav(true);
            }
            // setPrevScrollPos(currentScrollPos);
            prevScrollPos = currentScrollPos;
        }, 100);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    useEffect(() => {
		const onResize = (entries) => {
            _.each(entries, entry => {
                let height = entry.contentRect.height;
                if (entry.target.classList.contains('main-navigation')) {
                    setMainNavigationHeight(height);
                } else if (entry.target.classList.contains('lookbook-navigation')) {
                    setLookbookNavigationHeight(height);
                }
            })
		};

        const mainNavigationEl = document.querySelector('.main-navigation');
        const lookbookNavigationEl = document.querySelector('.lookbook-navigation');
        setMainNavigationHeight(mainNavigationEl.clientHeight)
        setLookbookNavigationHeight(lookbookNavigationEl.clientHeight)
	
		if (
		  'ResizeObserver' in window &&
		  'ResizeObserverEntry' in window &&
		  'devicePixelContentBoxSize' in window.ResizeObserverEntry.prototype
		) {
		  const observer = new ResizeObserver(onResize);

		  if (mainNavigationEl) {
			observer.observe(mainNavigationEl);
		  }
          if (lookbookNavigationEl) {
            observer.observe(lookbookNavigationEl);
		  }
	
		  
		  return () => { // Clean up the observers when the component is unmounted
			if (mainNavigationEl) {
			  observer.unobserve(mainNavigationEl);
			}
            if (lookbookNavigationEl) {
               observer.unobserve(lookbookNavigationEl);
            }
		  };
		}
	  }, []); 

      useEffect(() => { //Measure when folders are loaded.
        let mainNavEl = document.querySelector('.main-navigation');
        let lookbookNavEl = document.querySelector('.lookbook-navigation');
        setMainNavigationHeight(mainNavEl.clientHeight)
        setLookbookNavigationHeight(lookbookNavEl.clientHeight)
      }, [props.folders])

return <>
        <div 
            ref={lookbookNavigationRef}
            id="lookbook-navigation" 
            className={`light-mode hovered${showNav ? ' hovered' :''}`}
        >
            <div className={`lookbook-navigation${props.isScrolled ? ' scrolled' : ''}`}>
                <div className="folder-links" >
                    {
                        categoryList.map(category => {
                            return <NavLink
                                exact
                                key={`/lookbook/${category.slug}`} 
                                to={`/lookbook/${category.slug}`} 
                                className={`lookbook-button ${category.slug}`}>
                                    {category.navTitle || category.title}
                                </NavLink>
                        })
                    }
                </div>
                
                <div className="right">
                    <div className="lookbook-right-links">
                        Follow:{'\u00A0'}<a className="link-out" href="https://www.instagram.com/cargoworld/" target="_blank" >Instagram</a>,{'\u00A0'}<a className="link-out" href="https://blog.cargo.site/" target="_blank" >Newsletter</a>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`#lookbook-navigation {
                --nav-top-height: -${lookbookNavigationHeight}px;
                --main-nav-height: ${mainNavigationHeight}px;
            }`}
        </style>
    </>

};

export default LookbookNavigation;