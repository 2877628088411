import React, { Component } from 'react';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { withRouter } from 'react-router-dom';
import { matchPath } from "react-router";

import { generatePath } from "react-router";
import { MessageContext } from "@cargo/ui-kit";

import { API_ORIGIN, WEB_HOSTNAME, helpers } from '@cargo/common';
import cargoFetch from '@cargo/fetch';

import { store } from '../index';
import { MenuContext } from "@cargo/common/context-menu/context-menu-controller";
import { ContextMenuButton } from "@cargo/common/context-menu";

const getPreviewAuth = _.once(() => {
	const userId = store.getState().auth.data?.id;
	if (userId === undefined) {
		// In the absence of a user ID, assume we are in the public /designlab and 
		// respond with dummy authentication data
		return Promise.resolve({
			data: {
				id: 0,
				access_token: 'Design Lab'
			}
		})
	}

	return cargoFetch.get(`${API_ORIGIN}/users/${userId}/previewtoken`)
})

class SitePreviewer extends Component {

	constructor(props) {

		super(props);

		this.state = {
			dimensions: {height: null, width: null},
			measureFrame: {height: null, width: null},
			viewport: 'desktop',
			leftAlignmentValue: null,
			previewAuth: null
		}

		this.iframeRef  = React.createRef();
		this.measureRef  = React.createRef();

		getPreviewAuth().then(({data: authData}) => {

			this.setState({
				previewAuth: btoa(JSON.stringify(authData))
			})

		})

	}

	componentDidMount = () => {
		window.addEventListener('resize', this.setAllDimensions)
		window.addEventListener('keydown', this.handleHotKey)
		
		window.document.scrollingElement.style.overflow = 'hidden';

		this.setAllDimensions();

		if( this.props.site ){
			this.props.updateSitePreview({
				previewingSite: true,
				previewSiteModel: this.props.site,
				containingFolderID: this.props.parentFolder?.id ?? null
			});
		}
		
	}

	componentDidUpdate = (prevProps) => {

		if(prevProps.site !== this.props.site) {
			this.setAllDimensions();
		}

		if(    
			   ( prevProps.site && this.props.site && this.props.parentFolder && prevProps.parentFolder )
			&& ( prevProps.site.id !== this.props.site.id || this.props.parentFolder.id !== prevProps.parentFolder.id )
		){
			this.props.updateSitePreview({
				previewingSite: true,
				previewSiteModel: this.props.site ?? null,
				containingFolderID: this.props.parentFolder?.id ?? null
			});
		}

		if(
			!this.props.site
			&& this.props.hasFolders
			&& this.props.hasTemplates
		) {
			// If we're on the design lab and we recognize the folder, then the site is not paginated yet.
			if( this.props.fromDesignLab && this.props.parentFolder ){
				// Get the site outside normal pagination flow 
				this.props.fetchTemplateSite(this.props.parentFolder, this.props.siteToPreview).catch((err)=>{
					// Close preview if we can't fetch the site. Failure to fetch here returns a 404.
					this.closePreview();
				});

			} else {

				// close preview if there's no site but we have loaded
				// all folders and templates
				this.closePreview();

			}
		}

	}

	componentWillUnmount(){
		window.removeEventListener('keydown', this.handleHotKey)
		window.removeEventListener('resize', this.setAllDimensions)

		window.document.scrollingElement.style.overflow = '';

		this.props.updateSitePreview({
			previewingSite: false,
			previewSiteModel: null,
			containingFolderID: null
		});
	}

	handleHotKey = (e) => {

		if (e.keyCode === 27) {
			e.preventDefault();

			this.closePreview();

		} else if (e.keyCode === 37) {
			e.preventDefault();
			this.nextSite(true);
		} else if (e.keyCode === 39) {
			e.preventDefault();
			this.nextSite();
		}
	}

	closePreview = () => {
		// close preview by removing the `/preview/xxx` section of the route
		this.props.history.push(this.props.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, ''), {
			restoreScroll: true
		});
	}

	setAllDimensions = () => {
		this.setDimensions();
		window.requestAnimationFrame(()=>{
			this.setDupeButtonPosition();
		})
	}
	
	setDimensions = () => {

		let defaultWidth = window.innerWidth - 96;
		let	defaultHeight = window.innerHeight - 60 - 46;
		let	maxWidth = defaultWidth * .625 < defaultHeight ? defaultWidth : defaultHeight * 1.6;
		let	maxHeight = defaultWidth * .625 < defaultHeight ? defaultWidth * .625 : defaultHeight;

		if( this.state.viewport !== 'desktop' ){
			this.setState({measureFrame: {height: maxHeight, width: maxWidth}});
		}

		if (this.state.viewport === 'desktop') {

			this.setState({
				dimensions: {height: maxHeight, width: maxWidth},
				measureFrame: {height: maxHeight, width: maxWidth}
			});

		} else {

			// let	maxHeight = 'calc(100vh - 60px - 46px)';
			// let	maxHeight = calc(100vh - 40px + var(--viewport-mobile-margin-admin-side)))
			let maxWidth = 'calc(75vh - 15rem)';
			let	maxHeight = 'calc(100vh - 120px)';

			maxWidth = this.iframeRef?.current?.getBoundingClientRect()?.height;
			let width = maxWidth * .4615;
			width = Math.round(width) + 'px';

			this.setState({dimensions: {height: maxHeight, width: width }});
		}
	}

	setDupeButtonPosition = () => {
		// this.setState({leftAlignmentValue: this.iframeRef?.current?.getBoundingClientRect()?.left }) 
		this.setState({leftAlignmentValue: this.measureRef?.current?.getBoundingClientRect()?.left }) 
	}

	getTemplateSiteModel = (siteID) => {
		return _.filter(this.props.templateSites, {id: siteID})[0];
	}

	getUserSiteModel = (siteID) => {
		return _.filter(this.props.userSites, {id: siteID})[0];
	}

	nextSite = (previous) => {

		let nextSiteId;

		if ( this.props.isFeed || this.props.parentFolder.is_template_folder || this.props.fromPublicFolder ) {

			if (this.props.sitesInFolder) {
				let isSortBased = this.props.site.sort || this.props.site.sort === 0;
				let currentSiteSort = isSortBased ? this.props.site.sort : this.props.sitesInFolder.findIndex(siteInFolder => siteInFolder.id === this.props.site.id);
				let nextSiteIndex;
				let nextSiteIndexWrapped;

				let nextAmount = previous ? -1 : 1;
				
				nextSiteIndex = isSortBased ? _.findIndex(this.props.sitesInFolder, siteInFolder => siteInFolder.sort === currentSiteSort + nextAmount) : currentSiteSort + nextAmount;
				nextSiteIndex = isSortBased ? nextSiteIndex : this.props.sitesInFolder[nextSiteIndex] ? nextSiteIndex : -1;

				if( previous ){
					nextSiteIndexWrapped = nextSiteIndex === -1 ? this.props.sitesInFolder.length - 1 : nextSiteIndex;
				} else {
					nextSiteIndexWrapped = nextSiteIndex === -1 ? 0 : nextSiteIndex;
				}
				
				if( this.props.sitesInFolder[nextSiteIndexWrapped]?.id === this.props.site.id && previous ) {
					nextSiteIndexWrapped = this.props.sitesInFolder.length - 2;
				}

				nextSiteId = this.props.sitesInFolder[nextSiteIndexWrapped]?.id;

			}

		} else {

			// Copy sites array for modification
			let iterableSites = [ ...this.props.parentFolder.sites ]
			// Remove all sites with a bad sort
			iterableSites = _.filter(iterableSites, (site)=>{ return site.sort !== -1 });
			// if we still have sites...
			if (iterableSites) {
				// Find the site model we've clicked on
				let currentFolderSiteModel = _.filter(iterableSites, {site_id: this.props.site.id})[0];
				// Get it's index out of the list
				let currentFolderIndex     = _.indexOf(iterableSites, currentFolderSiteModel);
				// Total site length
				let totalSites = iterableSites.length - 1;
				// Get the next folder ( or previous ) 
				let nextFolderIndex = !!previous ? (currentFolderIndex === 0 ? totalSites : currentFolderIndex - 1) : (currentFolderIndex === totalSites ? 0 : currentFolderIndex + 1);
				// grab the model
				let nextFolderSiteModel = iterableSites[nextFolderIndex];
				// Be sure we have access
				let isNextSiteModelInUserData = this.props.userSites.find((site)=> { return site.id == nextFolderSiteModel.site_id });

				let nextFullSiteModel = isNextSiteModelInUserData ? this.getUserSiteModel(nextFolderSiteModel.site_id) : this.getTemplateSiteModel(nextFolderSiteModel.site_id); 

				nextSiteId = nextFullSiteModel?.id;

			}
		}

		if(nextSiteId) {

			// generate new route
			const nextPath = decodeURIComponent(generatePath(this.props.overlayMatch.path, {
				...this.props.overlayMatch.params,
				siteid: nextSiteId
			}));

			// push it
			this.props.history.push(nextPath);

		}

	}

	copyDuplicateButtons = () => {
		if( !this.state.leftAlignmentValue ) {
			return null
		}
		return this.props.canCopy ? (
			<>
			<MessageContext.Consumer>{(Message) => (
				<button 
					className="copy"
					style={{marginLeft: this.state.leftAlignmentValue+'px', 'display': this.state.leftAlignmentValue ? '' : 'none' }}
					onClick={(e)=>{
						let isBackdropCopy = this.props.site.website_title.toLowerCase().includes("backdrop");
						let copyText = !isBackdropCopy ? 'cargo:'+this.props.site.id : 'cargo:'+this.props.site.id+'?copybackdrop';
						navigator.clipboard.writeText( copyText );

						Message.showMessage({
							messageText: '✅ Copied.<br /><br />You can paste this into any site (⌘V)',
							duration: 4000,
							preventClickout: false,
							className: 'tall'
						});

						e.preventDefault();

					}}
				>
					Copy
				</button>
			)}</MessageContext.Consumer>
			{this.props.canDuplicate ? ( <button 
				className="duplicate"
				style={this.props.canCopy ? null : {marginLeft: this.state.leftAlignmentValue+'px', 'display': this.state.leftAlignmentValue ? '' : 'none' }}
				onClick={(e)=> {

					window.dispatchEvent(new CustomEvent('duplicate-site', {
						detail: {
							siteId: this.props.site.id
						}
					}));

				}}
			>
				{!this.props.authenticated ? 'Start with this site' : 'Duplicate'}
			</button>) : (null)}
			</>
		) : (
		<>
			{this.props.canDuplicate ? ( <button 
				className="duplicate"
				style={this.props.canCopy ? null : {marginLeft: this.state.leftAlignmentValue+'px', 'display': this.state.leftAlignmentValue ? '' : 'none' }}
				onClick={(e)=>{

					window.dispatchEvent(new CustomEvent('duplicate-site', {
						detail: {
							siteId: this.props.site.id
						}
					}));

				}}
			>
				{!this.props.authenticated ? 'Start with this site' : 'Duplicate'}
			</button>) : (null)}
			</>
		)

	}

	render = () => {

		if(!this.props.site) {
			return <site-previewer>
				<div className="background"
					onPointerDown={this.closePreview}
				></div>
				<div className="header"></div>
			</site-previewer>
		}

		const directLink = helpers.isLocalEnv ? `https://${this.props.site.site_url}.${WEB_HOSTNAME}` : this.props.site.direct_link;

		const measureFrameStyles = { height: this.state.measureFrame.height, width: this.state.measureFrame.width }
		const minimumMarginVal = this.state.leftAlignmentValue < 45 ? 45 : this.state.leftAlignmentValue;
		const useProxy = (!this.props.isLocal && !this.props.isFeed) || this.props.isLocalSsr;
		const proxyPath = this.props.isLocalSsr ? `${this.props.site.site_url}.${WEB_HOSTNAME}` : this.props.site.display_url;
		// A preview URL of /site.preview/example.cargo.site/previewTokenBase64 will securely proxy the requested site
		let previewURL = useProxy ? `/site.preview/${proxyPath}/${this.state.previewAuth || 'missing-auth'}` : directLink;

		// Cargo 2 is never proxied nor sandboxed for site previews
		if (this.props.site.version === 'Cargo2') {
			previewURL = this.props.site.direct_link;
		} else if (! this.state.previewAuth) {
			previewURL = ''
		}
		
		const userToSite = _.find(this.props.permissions, (siteItem) => { 
			return siteItem.site_id === this.props.site.id
		});

		const showEditButton = !this.props.fromDesignLab && !this.props.fromSavedFolder && !this.props.fromPublicFolder && userToSite?.role !== 'Inuse';
		return (
			<>

			<site-previewer>
				<div className="background"
					onPointerDown={this.closePreview}
				></div>
				<div className="header">
					<div>
						{ this.copyDuplicateButtons() }
						{localStorage.getItem('Dev-Test-CopyPageButton') === 'true' && <MenuContext.Consumer>
							{(Menu) => {

								return (
									<button 
										className="dotdotdot"
										onMouseDown={(e)=>{


											Menu.openMenu({
												innerUI: <>
													<MessageContext.Consumer>{(Message) => (
														<ContextMenuButton 
															onPointerUp={(e) => { 

																try {
																	const pid = this.iframeRef.current?.contentWindow.store.getState().frontendState.activePID
																	const sid = this.iframeRef.current?.contentWindow.store.getState().site.id
																	navigator.clipboard.writeText(`cargo:${sid}:${pid}`);

																	Message.showMessage({
																		messageText: '✅ Copied.<br /><br />You can paste this into any site (⌘V)',
																		duration: 4000,
																		preventClickout: false,
																		className: 'tall'
																	});
																	
																} catch(e) {
																	console.error(e);
																}
															}} 
															label="Copy current Page" 
														/>
													)}</MessageContext.Consumer>
												</>,
												type: 'button',
												offset: { x: 10, y: 10 },
												event: e
											}) 

											
										}}
									>
										<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle className="inner-circle" cx="8.5" cy="8.65747" r="7.5" />
											<circle className="outer-circle" cx="8.5" cy="8.65747" r="8" />
											<circle className="dot" cx="8.49977" cy="8.65747" r="1" />
											<circle className="dot" cx="5.07" cy="8.65747" r="1" />
											<circle className="dot" cx="11.9295" cy="8.65747" r="1" />
										</svg>
									</button>
								)
							}}
						</MenuContext.Consumer>}

						{ showEditButton &&
							<button 
								className="edit"
								style={this.props.canCopy || this.props.canDuplicate ? null : {
									marginLeft: this.state.leftAlignmentValue+'px', 
									'display': this.state.leftAlignmentValue ? '' : 'none' 
								}}
								onClick={(e)=>{
									let editLink = directLink;
									editLink = this.props.site.version === 'Cargo2' ? `${this.props.site.direct_link}/admin` : editLink + '/edit';
									editLink = userToSite?.role === 'Viewer' ? editLink + '/preview' : editLink;

									if (e.metaKey === true) {
										window.open(editLink, '_blank');
									} else {
										window.location = editLink;
									}
									return false
								}}
							>
								{userToSite?.role === 'Viewer' ? 'View Draft' : this.props.site.version === 'Cargo2' ? 'Edit in Cargo 2' : 'Edit'}
							</button>
						}
					</div>
					<div>
						<button 
							className={`desktop${this.state.viewport === 'desktop' ? ' selected' : ''}`}
							onPointerDown={(e)=>{
								this.setState({viewport: 'desktop'}, ()=>{
									this.setDimensions()
								})
							}}
						>
							<svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25 24H10V26H25V24Z" fill="black"></path><path d="M32.5 1H2.5C1.7 1 1 1.7 1 2.5V20.5C1 21.3 1.7 22 2.5 22H32.5C33.3 22 34 21.3 34 20.5V2.5C34 1.7 33.3 1 32.5 1ZM33 20.5C33 20.8 32.8 21 32.5 21H2.5C2.2 21 2 20.8 2 20.5V2.5C2 2.2 2.2 2 2.5 2H32.5C32.8 2 33 2.2 33 2.5V20.5Z" fill="black"></path></svg>
						</button>
						<button 
							className={`mobile${this.state.viewport === 'mobile' ? ' selected' : ''}`}
							onPointerDown={(e)=>{
								this.setState({viewport: 'mobile'}, ()=>{
									this.setDimensions()
								})
							}}
						>
							<svg width="17" height="26" viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 1H3.5C2.1 1 1 2.056 1 3.4V22.6C1 23.368 1.4 24.04 2 24.52C2.4 24.808 2.9 25 3.5 25H13.5C14.1 25 14.6 24.808 15 24.52C15.6 24.04 16 23.368 16 22.6V3.4C16 2.056 14.9 1 13.5 1ZM15 14.344V22.6C15 23.368 14.3 24.04 13.5 24.04H3.5C2.7 24.04 2 23.368 2 22.6V14.344V3.4C2 2.632 2.7 1.96 3.5 1.96H13.5C14.3 1.96 15 2.632 15 3.4V14.344Z" fill="black"/><rect x="5.5" y="3.5" width="6" height="1.5" rx="0.75" fill="black"/></svg>



						</button>
					</div>
					<div>
						<a 
							className="site-link" 
							href={directLink} 
							target="_blank"
							style={{marginRight: minimumMarginVal+'px', 'display': this.state.leftAlignmentValue ? '' : 'none' }}
						>Go to Site ↗</a>
					</div>
				</div>
				<div className="site-frame">
					<iframe 
						src={previewURL}
						referrerPolicy="origin"
						allow="fullscreen"
						// Setting height inline for mobile insures proper width measurement is made without needing to resize or run the dimensions function twice.
						style={this.state.viewport === 'desktop' ? this.state.dimensions : { height: 'calc(100vh - 120px)', width: this.state.dimensions.width }} 
						frameBorder="0"
						ref={this.iframeRef}
					></iframe>
				</div>
				<div className="measuring-frame">
					<div className="measure" style={measureFrameStyles} ref={this.measureRef} />
				</div>


				<div className="preview-nav">
					<div className="button-area" style={{height: this.state.measureFrame.height}}>
						<button style={ this.props.canLoop ? {} : {visibility: 'hidden', pointerEvents: 'none'}} onMouseUp={()=>this.nextSite(true)}>
							<svg className="left-arrow" x="0px" y="0px" viewBox="0 0 36 36"><polyline className="arrow-outline outer-color" points="21,29 10,18 21,7 "></polyline><polyline className="arrow-shape inner-color" points="21,29 10,18 21,7 "></polyline></svg>
						</button>
						</div>
					<div className="site-filler" style={measureFrameStyles}></div>
					<div className="button-area" style={{height: this.state.measureFrame.height}}>
						<button 
							className="close"
							onClick={this.closePreview}
						>
							<svg className="close" x="0px" y="0px" width="36" height="36" viewBox="0 0 36 36" fill="none">
							<rect width="36" height="36" rx="36"></rect><path d="M9.3,9.3L18,18l-8.7,8.7 M26.7,9.3L18,18l8.7,8.7"></path></svg>
						</button>
						<button style={ this.props.canLoop ? {} : {visibility: 'hidden', pointerEvents: 'none'}}  onMouseUp={()=>this.nextSite()}>
							<svg className="right-arrow" x="0px" y="0px" viewBox="0 0 36 36"><polyline className="arrow-outline outer-color" points="21,29 10,18 21,7 "></polyline><polyline className="arrow-shape inner-color" points="21,29 10,18 21,7 "></polyline></svg>
						</button>
					</div>
				</div>
			</site-previewer>
			</>
		)
	}
	
}

const getSitesInFolder = (state, folder) => {

	if(!folder) {
		return [];
	}

	if(folder.is_feed) {
		return state.designLab.feeds[folder.key]?.data || [];
	}

	return folder.sitesData || folder.sites || [];

}

function mapReduxStateToProps(state, ownProps) {

	let parentFolder = null;

	if( ownProps.fromPublicFolder ) {
		parentFolder = state.publicFolders.find(folder => folder.slug === ownProps.overlayMatch.params.folderSlug);
	} else {
		if( state.sitePreview.containingFolderID ){
			// On design lab homepage where no single folder is rendered, we provide the containing folder before we open the preview
			parentFolder = [...state.folders, ...state.templates].find(folder => folder.id === state.sitePreview.containingFolderID )
		} else {
			parentFolder = 
			// find based on currently rendered folder
			[...state.folders, ...state.templates].find(folder => folder.id === state.homepageState.renderedFolder )
			// try matching the id to a template folder. "containingFolderId" should be filled in this case.
			|| _.find(state.templates, folder => {
				return getSitesInFolder(state, folder).find(siteInFolder => siteInFolder.id == ownProps.siteToPreview)
			});
		}
	}
	
	const folderSlugFromUrl = ownProps.location.pathname.match(/\/(?!designlab\/)(.*?)\/preview/)?.[1] ?? null;

	if( !parentFolder && folderSlugFromUrl ){
		parentFolder = [...state.folders, ...state.templates].find(folder => { return folder.slug === folderSlugFromUrl }) ?? null;
	}

	const sitesInParentFolder = getSitesInFolder(state, parentFolder);

	const templateSites = state.templates ? state.templates.flatMap(template => template.sites) : [];
	let siteModelFromParent = parentFolder ? parentFolder.sites.find(site => site.id == ownProps.siteToPreview) : null;

	let siteModel = siteModelFromParent ? siteModelFromParent :
		// look in your own sites or templates
		[...state.sites, ...templateSites].find(site => site.id == ownProps.siteToPreview) 
		// or design lab feeds
		|| _.concat(
			..._.map(state.designLab.feeds, feed => feed.data)
		).find(site => site.id == ownProps.siteToPreview);

	const canDuplicate = ( siteModel?.can_duplicate ) && !siteModel?.is_coming_soon && !ownProps.fromPublicFolder;
	const canCopy      = siteModel?.can_copy && !siteModel?.is_coming_soon;

	return {
		isLocal     : CARGO_ENV === 'localhost',
		isLocalSsr  : CARGO_ENV === 'local-ssr',

		rootFolder   : state.folders[0],
		authenticated: state.auth.authenticated,
		templateSites: templateSites,
		userSites    : state.sites,
		permissions  : state.account.permissions,
		feedData     : state.designLab?.feeds,
		hasFolders   : state.homepageState.hasFolders,
		hasTemplates   : state.homepageState.hasTemplates,
		slugFromUrl   : folderSlugFromUrl,

		site                : siteModel,
		parentFolder    	: parentFolder,
		sitesInFolder       : sitesInParentFolder,
		fromSavedFolder     : parentFolder?.slug === 'saved',
		canLoop             : sitesInParentFolder?.length > 1,
		isFeed              : parentFolder?.is_feed,
		canDuplicate        : canDuplicate,
		canCopy             : (state.auth.authenticated && canCopy) || (!state.auth.authenticated && !canDuplicate && canCopy),

		userId			    : state.account?.id,

	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFolder: actions.updateFolder,
		addUIWindow: actions.addUIWindow,
		updateSitePreview: actions.updateSitePreview,
		fetchTemplateSite: actions.fetchTemplateSite,
	}, dispatch);
}

export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(SitePreviewer))
