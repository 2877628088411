import React, { useState, useEffect } from 'react';
import _, { set } from 'lodash';

import { HashId } from "@cargo/hash-id";
import { API_ORIGIN } from "@cargo/common";

import cargoFetch from "@cargo/fetch";
import { ProcessingAnimation } from '@cargo/ui-kit';
import { Button } from '@cargo/ui-kit';

export const InUseImageUploader = ({site, user, updateLocalSiteData, finishSave, setSaveVisbile, form}) => {

    const [imageUploading, setImageUploading] = useState(false);
    const [dragActive, setDragActive] = useState(false);
	const [quedImageSrc, setQuedImageSrc] = useState(null);
	const [quedImageData, setQuedImageData] = useState(null);

	const userId = user.id;
	const siteId = site.id;

    const previewImageInputRef = React.createRef();

	useEffect(() => {

		document.addEventListener('save-cargo-inuse-image', uploadImage);

	  return () => {
	  	// cleanup
	  	document.removeEventListener('save-cargo-inuse-image', uploadImage);
	  };

	}, []);


	useEffect(() => {
		// updateQuedImage() Sets "quedImageData" to the file.
		// showQuedImage() takes the file and displays it in preview, sets "quedImageSrc"
		// form.setFieldValue makes sure our save system has the proper image value. This is a complex event binding issue workaroud.
		if( !quedImageData ){
			form.setFieldValue('qued_image', null);
			setQuedImageSrc(null);
		}else if( quedImageData ){
			form.setFieldValue('qued_image', quedImageData);
			showQuedImage();
		}
	}, [quedImageData])

	const getS3PreSignedUploadUrl = (file, hash, options = {}) => {

        return cargoFetch.post(`${API_ORIGIN}/media/presignedurl/${siteId}/inuse`, {
			hash: hash,
			file_size: file.size,
			filename: file.name,
			mime_type: file.type,
			...(options.data || {})
		}, );

	}

	const uploadImage = async ( e ) => {

		const file = e.detail.image_to_upload;

		let modelsBeingUploaded = [];

		if(!file) {
			setImageUploading(false);

			const showRemoteAlert = new CustomEvent('open-remote-alert', {
			detail: {
				message: 'Something went wrong. No image found.',
			}
			});

			document.dispatchEvent( showRemoteAlert );
			return;
		}

		setImageUploading(true);
		setQuedImageSrc(null);

		const fileArray = [file];
		
		const getHashArray = new Promise(async (resolve) => {

			// generate a hash for every file in the array
			HashId.disableCompression();
			await HashId.enablePrefix();
			const hashIdGenerator = HashId.generator(siteId, userId);
	
			resolve(fileArray.map(() => hashIdGenerator.next().value))
	
		});

		const previousPresignedUrlPromises = [];

		// async upload all files
		const result = fileArray.map(async (file, fileIndex) => {

			if(file.size === 0) {

				setImageUploading(false);

				const showRemoteAlert = new CustomEvent('open-remote-alert', {
				detail: {
					message: 'Could not upload file. If your files are hosted on a cloud storage service, first ensure they are downloaded to your local machine.',
				}
				});

				document.dispatchEvent( showRemoteAlert );
				return;
			}

			const hashArray = await getHashArray;

			// grab the pre-generated hash
			const hash = hashArray[fileIndex];

			if(file.onHash) {
				file.onHash(hash);
			}

			// add dashes and remove unsafe chars
			const safeFileName = file.name
				.replace(/[#&?+,;"\'\\\^\ ]/g, "-")
				.replace(/([^a-zA-Z0-9-_\.]+)/g, "");

			let crop = false;
			let cropHeight = 80;
			// Crop the image
			let croppedImage = await cropImageFile(file, cropHeight);
			// convert file to generic blob so we can change the name
			// and any backend process the file gets uploaded to will correctly read it
			const renamedFile = crop ? new Blob([croppedImage], { type: croppedImage.type }) : new Blob([file], { type: file.type });

			renamedFile.name = safeFileName;

			const s3SignRequestData = {};

			if( !file.type.startsWith('image') ){

				setImageUploading(false);

				const showRemoteAlert = new CustomEvent('open-remote-alert', {
					detail: {
						message: 'Please use an image. Other media types are not supported.',
					}
					});
	
					document.dispatchEvent( showRemoteAlert );
					return;
			}

			if( file.type.startsWith('image') ){

				const imgEl = await convertFileToImage(renamedFile);

				if(imgEl) {
					// width and height only for valid image uploads
					s3SignRequestData.width = imgEl.naturalWidth;
					s3SignRequestData.height = imgEl.naturalHeight;
				}

			}

			const presignedURLPromise = new Promise((resolve, reject) => {

				// first wait till all other presigned URL requests came through. This ensures
				// the server creates the models in the order they were dropped.
				Promise.all([...previousPresignedUrlPromises]).finally(() => {
	
					// all previous models have been created. Now it's this file's turn
					getS3PreSignedUploadUrl(renamedFile, hash, {
						data: s3SignRequestData
					})
					.then((results)=>{

						resolve(results);

					}).catch((err) => {
	
						let message = err?.response?.data?.message ? err?.response?.data?.message : 'Something went wrong.';
	
						const showRemoteAlert = new CustomEvent('open-remote-alert', {
						   detail: {
							   message: message,
						   }
						});
	
						document.dispatchEvent( showRemoteAlert );
		
						reject(message)
						
					});
	
				});
	
			})
	
			// add to the queue so upcoming uploads know to wait
			previousPresignedUrlPromises.push(presignedURLPromise);
	
			const presignedURLResult = await presignedURLPromise;
	
			if( !presignedURLResult ){ return }
	
			let mediaModel;

			mediaModel = {
				...presignedURLResult.data.media,
				loading: true
			};

			if( !mediaModel ){
				return;
			}

			// upload to S3
			await cargoFetch.put(presignedURLResult.data.url, renamedFile, {
				headers: {
					"Content-Type": renamedFile.type,
					// prevent auth header from being sent
					"Authorization": null
				}
			}).catch((err) => {

					let message = err?.response?.data?.message ? err?.response?.data?.message : 'Something went wrong.';

					const showRemoteAlert = new CustomEvent('open-remote-alert', {
						detail: {
							message: message,
						}
					});

					document.dispatchEvent( showRemoteAlert );

			}).finally(() => {
				// remove the model from the list of uploads in progress
				modelsBeingUploaded = modelsBeingUploaded.filter(model => model !== mediaModel);
			});

			return {
				file,
				model: mediaModel
			}
			
		});

		Promise.all(result).then((data) => {
			let res = data[0];
			onUploadComplete(res);
		})
	
		return result;

	}

	const cropImageFile = (file, cropHeight) => new Promise((resolve, reject) => {
			// Create an image element
			const img = new Image();

			img.onload = () => {
				// When it loads create a new canvas
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				// Remove the top --px from the top of the image
				const croppedHeight = img.naturalHeight - cropHeight;
				
				canvas.width = img.naturalWidth;
				canvas.height = croppedHeight;
				// redraw boundaries
				ctx.drawImage(img, 0, cropHeight, img.naturalWidth, croppedHeight, 0, 0, img.naturalWidth, croppedHeight);
				// blob it to a file
				canvas.toBlob((blob) => {
					const croppedFile = new File([blob], file.name, { type: file.type });
					resolve(croppedFile);
				}, file.type);
			};
			
			img.onerror = () => {
				reject(new Error('Unable to load image'));
			};
			// set the source of the image to the file so we can pull the dimensions
			img.src = URL.createObjectURL(file);
	});



	const convertFileToImage = file => new Promise(function (resolve, reject) {

		switch ( true ) {
            case getSupportedFileTypes('image').includes(file.type): {

                let img = new Image();

                img.onload = function () {
                    // Resolve the promise
                    img.setAttribute('width', img.naturalWidth);
                    img.setAttribute('height', img.naturalHeight);
                    img.onload = null;

                    resolve(img);
                };

                img.onerror = function () {
                    // unable to load image, resolve empty
                    resolve(null)
                };

                img.src = URL.createObjectURL(file);

                break;
            }


            default:

                resolve(null);

        }

	});
	
	const getSupportedFileTypes = (base = null) => {
		const supported = [
			"image/png",
			"image/jpeg",
			"image/jpg",
			"image/gif",
			"image/svg",
			"image/svg+xml",
		];
		return supported.reduce((prevVal, currVal, currIndex) => {
			if ( base === null || currVal.startsWith(`${base}/`) ) {
				return prevVal.concat([currVal]);
			}
			return prevVal;
		}, [])
	}

	const onUploadComplete = (result) => {
		updateLocalSiteData(site.id, {inuse_screenshot: result.model});
		finishSave();
	}

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
		  setDragActive(true);
		} else if (e.type === "dragleave") {
		    setDragActive(false);
		}
	}

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false)
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
		  // at least one file has been dropped so do something
			// uploadImage(e.dataTransfer.files);
			updateQuedImage(e.dataTransfer.files);
		}
	}

	const showQuedImage = () => {
		let file = quedImageData;
		if (file) {
			let reader = new FileReader();
			reader.onload = function(e) {
				// imageElement.src = e.target.result;
				setQuedImageSrc( e.target.result );
			}
			reader.readAsDataURL(file);
		}
	}

	const updateQuedImage = (data) => {
		const file = data ? data[0] : previewImageInputRef?.current?.files[0];
		setSaveVisbile(true);
		setQuedImageData(file);
	}

	const hasCustomImage = site?.inuse_screenshot?.hash ? true : false;
	const hash = site?.inuse_screenshot?.hash && form.values['delete_image'] !== true ? site.inuse_screenshot.hash : site.screenshot.hash;
	const name = site?.inuse_screenshot?.name && form.values['delete_image'] !== true ? site.inuse_screenshot.name : site.screenshot.name;
	// const width = site?.inuse_screenshot?.width ? site.inuse_screenshot.width : site.screenshot.width;
	const imageURL = quedImageSrc ? quedImageSrc : `https://freight.cargo.site/w/1000/q/75/i/${hash}/${name}`;

	return (
        <div 
            className={`preview-image-area${dragActive ? ' droppable' : ''}`}
            onDragEnter={handleDrag} 
            onDragLeave={handleDrag} 
            onDragOver={handleDrag} 
            onDrop={handleDrop}
        >

            <input 
                type='file'
                onChange={e => updateQuedImage(null)} 
                hidden 
                id="preview-image" 
                ref={previewImageInputRef}
            />
				
				{ ( hasCustomImage && form.values['delete_image'] !== true ) || quedImageData ? 
					<div className="close delete-image">
						<Button
							onClick={()=>{ 
								if( imageUploading ){
									return;
								}
								if( quedImageData ){
									setQuedImageData(null);
								} else {
									form.setFieldValue('delete_image', true);
								}
							}}
							label={
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
									<circle opacity="var(--ui-close-circle-opacity)" cx="10" cy="10" r="10" fill="var(--ui-color-flat-reverse)"/>
									<path fillRule="evenodd" clipRule="evenodd" d="M10.0002 11.0607L14.3099 15.3703L15.3705 14.3096L11.0609 10L15.3705 5.69036L14.3099 4.6297L10.0002 8.93934L5.69054 4.62964L4.62988 5.6903L8.93958 10L4.62988 14.3097L5.69054 15.3704L10.0002 11.0607Z" fill="var(--ui-color-flat)"/>
								</svg>
							}
						/>
					</div>
				: null}			

                <div 
                    className={`preview-image upload`}
                    onMouseDown={e => {
						if (e.button === 2) {
							return
						}
                        previewImageInputRef.current?.click();
                    }}
                >
                    <div className="preview-image-frame">
						{imageUploading ? <ProcessingAnimation className="white-bg" /> : null }
                        <img 
                            className="preview-image-element"
                            src={ imageURL }
                            onLoad={() => { 
								// Image is finished uploading and is in the DOM
								if( imageUploading && quedImageData.name === name ){
									setQuedImageData(null);
									setImageUploading(false);
								}
                            }}
                            onError={e => {
                                // e.target.src = placeHolderImg;
                            }}
							style={imageUploading ? {visibility: 'hidden'} : {}}
                        />
                    </div>
                </div>
                
        </div>
    )

}
