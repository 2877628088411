import React, { Component, Fragment, useState, useRef, useLayoutEffect } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { useHistory } from "react-router-dom";
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";
import { HOMEPAGE_ORIGIN } from "@cargo/common";


const FolderLinkContextUI = props => {

	const history = useHistory();

	let isMobile = props.isMobile;


	return (
		<>
			
			<ContextMenuButton
				label={ props.name && props.name !== '' ? props.name : props.email}
				onPointerUp={()=>{
					// history.push('/templates');
					history.push('/account/account');
				}}
			/>

			<hr/>
			 
			<ContextMenuButton 
				label="Account Settings" 
				onPointerUp = { e => { 
					history.push('/account');
				}}
			/>

			<ContextMenuButton 
				label={<>Docs <em>↗</em></>}
				onPointerUp = { e => { 
					window.open('https://docs.cargo.site', '_blank').focus();
				}}
			/>

			<ContextMenuButton 
				label="Trash" 
				onPointerUp = { e => {
					history.push( 'trash' );
				}}
				disabled={!props.trashFetched || !props.hasDeletedSites ? true : null }
			/>
				
			<hr/>
			
			{ props.authenticated === false ?
				<ContextMenuButton 
					label="Login" 
					onPointerUp = { e => {

						props.authenticate().then(() => {
							props.fetchAccount();
							props.fetchFolders();
						});

					}}
				/>
			:
				<ContextMenuButton 
					label="Logout" 
					onPointerUp = { e => {

						props.updateHomepageState({
							suppressLoginForm: true
						})

						let logoutLocation = CARGO_ENV !== 'production' ? HOMEPAGE_ORIGIN : 'https://cargo.site';
							
						props.logout().then(() => { 
							window.location.href = logoutLocation;
						})
						
					}}
				/>
			}

		</>
	);

};

function mapReduxStateToProps(state, ownProps) {

	return {
		authenticated: state.auth.authenticated,
		email: state.account.email,
		name: state.account.full_name,
		homepageState: state.homepageState,
		sites: state.sites,
		isMobile: state.homepageState.isMobile,
		trashFetched: state.homepageState.trashFetched,
		hasDeletedSites: state.deletedSites?.length > 0
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		logout: actions.logout,
		authenticate: actions.authenticate,
		fetchAccount: actions.fetchAccount,
		fetchFolders: actions.fetchFolders,
		updateHomepageState: actions.updateHomepageState,
		addUIWindow: actions.addUIWindow
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(FolderLinkContextUI);