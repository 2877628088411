import { actionTypes } from "../actions";
import { combineReducers } from 'redux';

import { devFeeds } from '../components/design-lab/designlabdata';

import _ from "lodash";

let isDev = CARGO_ENV !== 'production';


const defaultDesignLabState = { 
	templates: [], 
	feeds: { 
		usefulMusic: { 
			paginationCount: 0,
			data: []
		}
	},
}

export default function(state = defaultDesignLabState, action){

	if( !action.payload && !action.meta ) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_CATEGORY_SITES_FULFILLED: 

			if( action.payload.data ) {
				// update state here
				let newState = { ...state }
				
				// let responseData  = action.payload.data;
				let processedData = null;
				processedData = action.payload.data;
				
				newState.feeds[action.meta.rowPage].data = [
					...newState.feeds[action.meta.rowPage].data,
					...processedData
				]

				newState.feeds[action.meta.rowPage].paginationCount = newState.feeds[action.meta.rowPage].paginationCount += 1;

				return newState
				
			}

			break;

			case actionTypes.FETCH_CATEGORY_SITES_DEV: 

			if( isDev ) {
				// update state here
				let newState = { ...state }
				let responseData  = devFeeds[action.meta.rowPage];
				let processedData = null;
				processedData = responseData;

				newState.feeds[action.meta.rowPage].data = processedData;
				newState.feeds[action.meta.rowPage].paginationCount = newState.feeds[action.meta.rowPage].paginationCount += 1;

				return newState
			}
			

	}

	return state;

};