import React, {useState, useEffect, useRef} from 'react';
import { useIntercom } from 'react-use-intercom';
import _ from 'lodash';
import { Button } from "@cargo/ui-kit/button/button";

import './intercom-button.scss';

let intercomLoadTimeout;
let onIntercomLoadError = null;
let intercomLoaded = false;

export const IntercomButton = ({userData, location, hasAnnexUpgrade, sentry, Alert}) => {

    const { boot, shutdown, hide, show, update, isOpen } = useIntercom();
    const [ hasUnreadMessages, setHasUnreadMessages ] = useState(false);

    if (!userData) return null

	useEffect(() => {
		if (!userData) return;

        const link = document.querySelector('#intercom-launcher');

        Intercom('onUnreadCountChange', function(unreadCount) {

            if(location === 'c3-admin'){
                setHasUnreadMessages(unreadCount > 0);
            }

            if(!intercomLoaded) {
                intercomLoaded = true;
                console.log('[Intercom] Loaded successfully');
            }

        });

        window.Intercom.beforeInit = ([method, args]) => {
            if(method === 'showArticle' || method === 'showNewMessage' || method === "show") {
                // Intercom has not loaded yet...
                onIntercomLoadError?.();
            }
        }

        // const iconColor = location === 'c3-admin' ? 'blue' : 'orange';
        // const headerColor = location === 'c3-admin' ? 'red' : 'orange';
        const horizontalPadding =   location === 'u.cargo' ? 30 : window.innerWidth  - link.getBoundingClientRect().x + 17;
        const verticalPadding   =   location === 'u.cargo' ? 30 : window.innerHeight - link.getBoundingClientRect().y - 100;
        const displayName = userData.full_name && userData.full_name.length > 0 ? userData.full_name : userData.email;
        boot({
            // actionColor: iconColor,
            // backgroundColor: headerColor,
            customLauncherSelector: '#intercom-launcher',
            horizontalPadding: horizontalPadding,
            verticalPadding  : verticalPadding,
            hideDefaultLauncher: true,
			name: displayName,
			email: userData.email,
            customAttributes:{
                user: 'https://admin.cargo.site/accountdetail/'+userData.id,
                errors:'https://cargo3.sentry.io/issues/?project=4504992645578752&query=user.id%3A'+userData.id,
                has_subsription: userData.has_subscription,
                annexed: hasAnnexUpgrade,
                version: 'C3'
            }
        });

	}, [userData])

    useEffect(() => {

        onIntercomLoadError = () => {

            console.log('[Intercom] Attempt to interact with support widget failed');

            if(sentry) {
                sentry.captureMessage("[Intercom] Attempt to interact with support widget failed");
            }

            if(Alert) {
                Alert.openModal?.({
                    htmlHeader: `It appears that Cargo Support cannot load.<br />This might be due to an ad blocker in your browser. Try disabling it, or get in touch via <a style='color: #222222; text-decoration: underline;' href="mailto:support@cargo.site">support@cargo.site</a>`, 
                    type: 'notice'
                });
            }

        }

    }, [Alert?.openModal])

    return (
        <Button 
            icon={<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="-1" width="40" height="40" fill="white"></rect>
                <path fillRule="evenodd" clipRule="evenodd" d="M8.5 19.0043C8.5 12.3549 13.6679 7 20 7C26.3321 7 31.5 12.3549 31.5 19.0043C31.5 22.8497 29.7688 26.2676 27.0818 28.4629L26.8901 28.6195L26.8984 28.867C26.9327 29.8838 27.379 31.562 29.2014 32.8963C28.7325 32.993 28.1763 33.0391 27.5652 32.959C26.448 32.8127 25.0979 32.2393 23.7232 30.6841L23.5109 30.4438L23.2039 30.5366C22.1874 30.8438 21.1125 31.0085 20 31.0085C13.6679 31.0085 8.5 25.6536 8.5 19.0043ZM20 6C13.0772 6 7.5 11.8418 7.5 19.0043C7.5 26.1667 13.0772 32.0085 20 32.0085C21.1013 32.0085 22.1699 31.8602 23.1881 31.5816C24.642 33.1328 26.1247 33.7789 27.4353 33.9506C28.7981 34.129 29.9261 33.7884 30.5828 33.5049L31.5395 33.0919L30.6188 32.6041C28.5494 31.5076 28.0128 29.9893 27.9147 29.0703C30.7161 26.6836 32.5 23.0581 32.5 19.0043C32.5 11.8418 26.9228 6 20 6ZM20.347 22.2714H19.0651V21.3762C19.0554 20.1095 19.6145 19.3095 20.9735 18.4524C22.188 17.6714 22.641 17.0143 22.641 15.9762V15.9571C22.641 14.6143 21.5518 13.6333 20 13.6333C18.4578 13.6333 17.388 14.5857 17.2627 16.081L17.253 16.0905L16 16.1L16.0096 16.081C16.1157 14.0714 17.5614 12.5 20.0482 12.5C22.3711 12.5 24 13.9667 24 15.9V15.919C24 17.319 23.2867 18.319 21.9663 19.1571C20.7036 19.9571 20.347 20.5 20.347 21.5571V22.2714ZM20.8964 25.0762C20.8964 25.6952 20.453 26.1429 19.8265 26.1429C19.1904 26.1429 18.747 25.6952 18.747 25.0762C18.747 24.4476 19.1904 24 19.8265 24C20.453 24 20.8964 24.4476 20.8964 25.0762Z" fill="var(--ui-color-flat-reverse)"/>
                </svg>}
            id="intercom-launcher"
            onClick={() => {
                if(!intercomLoaded) {
                    // clicking the intercom help button but we are not booted yet
                    onIntercomLoadError?.();
                }
            }}
            tooltip="Help"
            className={`${isOpen ? 'popover-open ' : ''}${hasUnreadMessages ? 'has-unread-messages' : ''}${location === 'u.cargo' ? ' get-help' : ''}`}
        />
    )

};
