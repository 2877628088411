import React, { Component, Fragment, useState, useRef, useLayoutEffect, useEffect } from 'react';

import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";

import { useHistory } from "react-router-dom";

import { HotKeyProxy } from "./ui-kit";
import { Alert, AlertContext } from "@cargo/ui-kit";
import { ContextMenuButton } from "@cargo/common/context-menu";


const FolderContextUI = props => {

	const history = useHistory();
	const prevFolderSlugRef = useRef(props.folder.slug);

	return (
		<>

		<AlertContext.Consumer>
		    {(Alert) =>
		    	<>
				<ContextMenuButton
					label={"Rename"} 
					onPointerUp={
						(e) => {

		            		Alert.openModal({
			            		header: 'Rename',
			            		type: 'dialog',
			            		placeholder: 'Rename Folder...',
			            		existingValue: props.folder.name,
			            		focusInput: true,
			            		ignoreUnmount: true,
			            		HotKeyProxy: HotKeyProxy,
			            		maxInputLength: 100,
			            		onConfirm: ( folderName ) => {
									
			            			if( folderName && props.folder){

			            				props.updateFolder({
			            					...props.folder,
			            					name: folderName
			            				}).then((response)=>{

			            					if( response.status !== 200 ){ return }

											let currentLowercaseSlug = history.location.pathname.toLowerCase();
											let prevLowercaseSlug    = prevFolderSlugRef.current ? prevFolderSlugRef.current.toLowerCase() : '';
											
											if( currentLowercaseSlug === `/${prevLowercaseSlug}` ){
				            					history.replace( response.data.slug );
											}

			            					// props.updateFolder({
				            				// 	name: response.data.name,
				            				// 	slug: response.data.slug
				            				// })


			            				});

			            			}
		            			}
		            		})


						}
				}></ContextMenuButton>
		    
				<ContextMenuButton 
					label="Remove" 
					onPointerUp = { e => { 

	            		Alert.openModal({
		            		header: 'Remove Folder?',
		            		message: '(Sites will not be deleted)',
		            		type: 'confirm',
		            		ignoreUnmount: true,
		            		HotKeyProxy: HotKeyProxy,
		            		onConfirm: ( folderName ) => {
		
	            				props.deleteFolder( props.folder?.id );
								if( history.location.pathname === `/${props.folder?.slug}` ){
									history.push('/');
								}
	            			}
	            		})
					}}
				/>	
				<hr/>
				<ContextMenuButton 
					label={ props.isPrivate ? "Share" : "Share (enabled)" }
					onPointerUp = { e => { 

						props.addUIWindow({
							group: 'right-bar',
							component: import('./share-folder-window'),
							id: `share-folder-window`,
							props: {
								type: 'popover', 
								positionType: 'center', 
								windowName: 'share-folder',
								clickoutLayer: true,
								clickoutLayerDim: true,
								draggingUploadedImage: false,
								disableDragging: false,
								waitForHeightBeforeRender: true,
								minimumRenderHeight: 40,
								folderId: props.folder.id,
							}
						})
					}}

				/>	
		
				</>
		    }
		</AlertContext.Consumer>


		</>
	);

};

function mapReduxStateToProps(state, ownProps) {

	let permissions = state?.account?.permissions;
	const isInUseEditor = permissions ? permissions.find((site)=> site.role === 'Inuse' ) ? true : false : false;

	return {
		isInUseEditor: isInUseEditor,
		isPrivate: ownProps.folder.access_level === "PRIVATE"
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateFolder: actions.updateFolder,
		deleteFolder: actions.deleteFolder,
		addUIWindow: actions.addUIWindow
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(FolderContextUI);