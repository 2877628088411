export const lazyLoadImageObserver = new IntersectionObserver(entries => {
	// console.log("entries", entries )
	entries.forEach(entry => {
		if(entry.isIntersecting) {
			entry.target.runLazyLoad?.();
			lazyLoadImageObserver.unobserve(entry.target);
		}
	})

}, {
	root: document,
	// load everything in 2 x screen height horizontally and 600px vertically
	rootMargin: (screen.height * 2) + 'px 600px',
	threshold: [0,1]
});