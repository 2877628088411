import React from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Folder from "../folder";

export default ({ item, id, isDisabled }) => {
    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        animateLayoutChanges: () => false,
        disabled: isDisabled
    });

    // console.log(attributes, listeners, transform, transition)

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <div 
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            <Folder 
                isDragging={isDragging} 
                isDisabled={isDisabled} 
                item={item} 
                key={`item-${id}`} 
                id={id} 
            />
        </div>
    )
}