import _ from 'lodash';
import { isServer, isMobile } from "@cargo/common/helpers";
import React, { Component } from 'react';

const baseUnitData = {
	offset: 0,
	size: 50,
	mobile: {
		chrome: 0, // the size of the user interface, calculated by subtracting window height from maxheight
		maxHeight: 720, // the size of the viewport when the chrome is hidden, caused by scrolling
	},
	window: {
		w: 1280,
		h: 720,
	},
	document: {
		w: 1280,
		h: 1280
	}
}

const BaseUnitContext = React.createContext(_.cloneDeep(baseUnitData));

class BaseUnit extends Component {

	constructor(props){
		super(props);

		this.data = {
			main_margin: 0,
			mobile_padding: 0,
			mobile_zoom: 20
		}

		this.state = Object.assign({}, _.cloneDeep(baseUnitData));
		this.maxHeightMeasuringStick = React.createRef();
		this.minHeightMeasuringStick= React.createRef();

		if(!isServer) {
			this.SvhSupported = CSS.supports("height", "100svh");			
			this.resizeObserver = new ResizeObserver(this.onBodyResize);
		}
	}

	render (){

		return (
			<>
	 		<BaseUnitContext.Provider value={this.state}>
	 		{this.props.children}
	 		</BaseUnitContext.Provider>
			{this.SvhSupported ? <div ref={this.minHeightMeasuringStick} style={{
				width: '1px',
				height: '100svh',
				position:'fixed',
				zIndex: '-1',
				pointerEvents: 'none',
				top: '0px',
				left: '-1px',
				visibility: 'hidden'
			}}></div> : null}
			<div ref={this.maxHeightMeasuringStick} style={{
				width: '1px',
				height: '100vh',
				position:'fixed',
				zIndex: '-1',
				pointerEvents: 'none',
				top: '0px',
				left: '-1px',
				visibility: 'hidden'
			}}></div>	
	 		</>
 		)
	}

	componentDidMount(){

		if ( !isServer ){

			this.onWindowResize();	
			this.onBodyResize();

			window.addEventListener('resize', this.onWindowResize, {passive: true});
			this.resizeObserver.observe(document.body)
		}
	}

	componentWillUnmount() {
		if ( !isServer ){
			window.removeEventListener('resize', this.onWindowResize, {passive: true});
			this.resizeObserver.unobserve(document.body)
		}
	}

	getBaseUnit(options) {
		options = options || {};

		var baseUnit = 0;
		options = _.extend(true, {
			height: this.state.window.h,
			width : this.state.window.w,
			min   : 11,
			max   : false,
			weight: 5,
			round : false,
			offset: 1
		}, options);

		// Start height or width, depending upon which is smallest
		baseUnit = options.width < options.height ? options.width : options.height;
		baseUnit *= options.offset

		// Set as a percentage of the width/height
		baseUnit /= (100 / options.weight);

		// Minimums and maximums
		if (options.min && baseUnit < options.min) baseUnit = options.min;
		if (options.max && baseUnit > options.max) baseUnit = options.max;

		// Round the number
		if (options.round) baseUnit = Math.floor(baseUnit);

		// Return the calculated unit
		return baseUnit;
	};

	onBodyResize =() =>{

		this.setState((prevState)=>{

			const newState = _.cloneDeep(prevState)

			const docW = document.documentElement.scrollWidth;
			const docH = document.documentElement.scrollHeight;

			newState.document.h = docH;
			newState.document.w = docW;

			if (
				newState.document.h != prevState.document.h ||
				newState.document.w != prevState.document.w
			) {
				return newState				
			}

		});

	}

	onWindowResize =() =>{

		const cache = Object.assign({}, this.state);
		const {data} = this;

		let w = document.documentElement.clientWidth;
		let h = 0;
		let chrome = 0;


		let minHeight = 0;
		let maxHeight = this.maxHeightMeasuringStick.current.getBoundingClientRect().height;

		if( this.SvhSupported ){

			minHeight = this.minHeightMeasuringStick.current.getBoundingClientRect().height;		

		} else {

			let increment = 100;
			minHeight = 0;
			let lastResult;

			while(true) {

				const result = window.matchMedia.call(window, '(min-height:' + (minHeight) + 'px)')['matches'];

				if(lastResult !== undefined && result !== lastResult) {
					// we've overshot the value. Increase granularity
					increment /= 2;
					// start searching below the current value
					increment *= -1;
				}
				
				minHeight += increment;
				lastResult = result;

				// we've honed in on a value with 2 decimal precision
				if(Math.abs(increment) < 0.01) {
					minHeight = Math.round(minHeight)
					break;
				}

			}

		}

		chrome = maxHeight-minHeight;
		h = minHeight;

		let _diff   = 0;
		let _offset = data.mobile_padding;
		let _scale  = data.mobile_zoom - 15; // -15 keeps it sane in portrait
		let _size   = 0;
		let _weight = this.props.isTouch && !this.props.isPortrait ? 16 : 9; // default value

		// Is this portrait?
		if (h+chrome > w) {
			_diff = ((h+chrome) / w) - 1;
		}

		// Ratio
		_diff = (_diff / 0.777777778);

		// iPhone vertical orientation ratio
		if (_diff > 1) {
			_diff = 1;
		}

		// Padding offset
		_offset = Math.abs(_offset) / 10;
		_offset = (data.main_margin * _offset) * _diff * -1;

		// Base unit
		_size = this.getBaseUnit({
			weight: _weight + (_scale * _diff),
			min: 20,
			width: w,
			height: h
		});


		this.setState((prevState)=>{

			const newState = _.cloneDeep(prevState)

			newState.window.h = h;
			newState.window.w = w;
		
			newState.mobile.maxHeight = maxHeight;
			newState.mobile.chrome = newState.mobile.maxHeight - newState.window.h;
			newState.size= _size;

			// setting this concurrent with state under the assumption that this
			// is the tightest possible way to keep these values synced for other
			// measurements ( eg resize-card , elementresizer ), which may not be
			// true. it may be better off set before state
			
			if ( !_.isEqual(newState.size, prevState.size) && this.props.setFontSize ) {
				document.documentElement.style.setProperty('font-size', _size + '%'); 
				document.documentElement.style.setProperty('--viewport-height', h+'px'); 

			}

			return newState
		});		
		

	}

}

BaseUnit.defaultProps = {
	setFontSize: true
}

const withBaseUnit = (Component) => {

	class HOC extends Component {
		constructor(props) {
			super(props);
		}

		render() {
			return <Component baseUnit={this.context} {...this.props} />;
		}

		// removing this will break it! I have no idea why!
		componentDidUpdate(prevProps){

		}
	}

	HOC.contextType = BaseUnitContext

	return HOC;
}


export default BaseUnit;
export {BaseUnitContext, withBaseUnit};
