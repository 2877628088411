import React from 'react';

import { XScrollNav } from "./x-scroll-nav";
import { SectionHeader } from "./section-header";

import _ from 'lodash'

export const VideoFolder = ({ 
    sectionFolder,
    folder,
    xScrollRefs,

    onXScrollMouseDown,
    xScrollListener,
    pushXScroll,
    vimeoThumbs,
    setVimeoPreview
}) => {

    return (
        <div 
            className={`${`row vimeo-list`}`} 
            key={`folder-${folder.id}`}
            row-id={folder.id}
        >
            <XScrollNav
                pushXScroll={pushXScroll}
                folder={folder}
            />
            <div 
                className={`x-scroll siteslist`}
                ref={xScrollRefs ? xScrollRefs[folder.id] : null}
                onMouseDown={onXScrollMouseDown}
                onScroll={xScrollListener}
                folder-id={folder.id}
            >
                
                <SectionHeader folder={ folder } />
                
                <div className="site placeholder"></div>
                {sectionFolder.vimeoList.map((vimeo, index)=>{
                    return (<div 
                        className={`vimeo site ${vimeoThumbs[vimeo.id] ? 'image-loaded' : ''} `}
                        vimeo-id={vimeo.id} 
                        key={`vimeo-${index}`}
                    >
                        <a 
                            onClick={()=>{
                                setVimeoPreview( vimeo.id )
                            }
                        }>
                            <div className="site-preview">
                                <img src={`${vimeoThumbs[vimeo.id]}`} />
                            </div>
                        </a>
                        <div className="details">
                            <div
                                draggable={false}
                                className="site-links"
                            >
                                <span draggable={false} className="direct-site-links">
                                    <a draggable={false} className="title-wrapper link" onClick={()=>{
                                        setVimeoPreview( vimeo.id )
                                    }}>
                                        <span className="title">{vimeo.title}</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    {/* <div className="details">
                        <a draggable="false" className="link title" onClick={()=>{
                            setVimeoPreview( vimeo.id )
                        }}>
                            <span className="title">{vimeo.title}</span>
                        </a>
                        <div className="spacer">
                        </div>
                    </div> */}
                   
                    </div>
                    )
                    
                })}
            </div>

        </div>
    )

}