import React, {useEffect, useRef, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { NavLink, withRouter } from 'react-router-dom';

import { VideoFolder } from "./video-folder";
import { XScrollNav } from "./x-scroll-nav";
import { SectionHeader } from "./section-header";
import _ from 'lodash'
import Site from "../site";

let isDev = CARGO_ENV !== 'production';

const DesignLabXScroll = (
    {   //Data:
        section, 
        folderId, 
        vimeoThumbs,
        xScrollRefs, 
        progressXScroll,
        message,
        //Functions:
        login, 
        duplicateTemplate,
        setForDuplication,
        pushXScroll,
        updateHomepageState,
        // Event Handlers:
        onXScrollMouseDown, 
        xScrollListener,
        // From connect redux state to props...
        sites,
        folders,
        sitePreview,
        designLab,
    }) => {

        if( !folders || !folderId ){
            return null;
        }

        const [sitePreviewIdOnOpen, setSitePreviewIdOnOpen] = useState(null);
        
        const prevSitePreview = useRef(sitePreview);

        useEffect(() => {
            // Do something when sitePreview changes
            if (
                prevSitePreview.current !== null &&
                prevSitePreview.current?.containingFolderID === folderId &&
                prevSitePreview.current?.previewingSite === true &&
                sitePreview?.previewingSite === false
            ) {

                if( sitePreviewIdOnOpen === prevSitePreview.current?.previewSiteModel.id ){ 
                    setSitePreviewIdOnOpen(null)
                    return 
                }

                let xScrollEl = xScrollRefs[folder.id].current;
                if( !xScrollEl ) return;

                let siteElement = xScrollEl.querySelector(`.site[s-id="${prevSitePreview.current.previewSiteModel.id}"]`);
                if( !siteElement ) return;

                let startLeft = xScrollEl.scrollLeft;
                let siteElementRect = siteElement.getBoundingClientRect();

                let xScrollPaddingLeft = parseInt(window.getComputedStyle(xScrollEl, null).getPropertyValue('padding-left')) + parseInt(window.getComputedStyle(xScrollEl.closest('.row'), null).getPropertyValue('margin-left'));
                let xScrollLeftPoint = parseInt(xScrollEl.getBoundingClientRect().left) + xScrollPaddingLeft;

                // Calculate the scroll position to center the site element in the x-scroll
                let scrollPosition = startLeft + siteElementRect.left - xScrollLeftPoint - xScrollEl.clientWidth / 2 + siteElementRect.width / 2;
                // Scroll the x-scroll to the calculated position
                xScrollEl.scrollLeft = scrollPosition;

                setSitePreviewIdOnOpen(null)
            }

            if(    sitePreview.previewingSite === true
                && prevSitePreview.current?.previewingSite === false
                && sitePreview.containingFolderID === folderId
            ){
                setSitePreviewIdOnOpen(sitePreview.previewSiteModel.id);
            }

            // Track prevProps (prevSitePreview) for comparison in next render
            prevSitePreview.current = sitePreview;
        }, [sitePreview]);


        const setVimeoPreview = (vimeoID) => {
            updateHomepageState({previewingVimeoID: vimeoID});
        }

        const folder = folders.find(folder => folder.id === folderId);

        const folderSites = !folder?.is_feed ? folder?.sites : designLab.feeds[folder.key].data;

        if (
            folder && 
            folderSites &&
            folderSites.length > 0 
            // && section.rows.some((row)=>(isDev ? row.dev : row.live) === folder.id)
        ) {

            if (folder.row.vimeoList?.length > 0) {
                return (
                    <VideoFolder
                        key={`videoFolder-${folder.id}`}
                        sectionFolder={folder.row}
                        folder={folder}
                        section={section}
                        xScrollRefs={xScrollRefs}

                        onXScrollMouseDown={onXScrollMouseDown}
                        xScrollListener={xScrollListener}
                        pushXScroll={pushXScroll}
                        vimeoThumbs={vimeoThumbs}
                        setVimeoPreview={setVimeoPreview}
                    />
                )
            }

            // if ( folder?.row?.localStorageKey) { 
            //     let hasLocalStorageItem = localStorage.getItem( folder?.row?.localStorageKey ) === 'true';
            //     if (hasLocalStorageItem) {
            //         // let it render
            //     } else {
            //         return null;
            //     }
            // }
            if( !folder || !folderSites ) return null;

            return (
                <div 
                    className={`${`row`
                        +`${folder.is_idea ? ' idea' : ''}`
                        +`${folder.is_element ? ' element' : ''}`
                        +`${folder.is_community ? ' community' : ''}`
                    }`} 
                    key={`folder-${folder.id}`}
                    row-id={folder.id}
                >
                    { ( folder.is_feed || folderSites.length >= 5 ) ? ( 
                        <XScrollNav
                            pushXScroll={pushXScroll}
                            folder={folder}
                        />
                    )  : null }

                    <div 
                        className={`x-scroll siteslist`}
                        ref={xScrollRefs ? xScrollRefs[folder.id] : null}
                        onMouseDown={onXScrollMouseDown}
                        onScroll={xScrollListener}
                        folder-id={folder.id}
                    >

                        <SectionHeader folder = { folder } />

                        <div className="site placeholder"></div>

                        {folderSites && folder.feed_type === 'page-scrape' ? 
                            <>
                            {folderSites?.map((siteModel)=>{
                                return !siteModel.display ? ( null ) : (
                                    <div 
                                        className={`link`} 
                                        key={`site-${siteModel.id}-${folder.id}}`} 
                                        dimensions={siteModel?.thumb_meta?.thumbnail_crop?.imageModel?.width === siteModel?.thumb_meta?.thumbnail_crop?.imageModel?.height ? 'square' : 'landscape'}
                                    >
                                        <div dangerouslySetInnerHTML={ {__html: siteModel.content_partial_html.split('</a>')[0].replace(/data-src/g, 'src')} }></div>
                                        <div className="details"><span className="title">&nbsp;</span></div>
                                    </div>
                                )
                            })}
                            </>

                        : (
                            <>
                            {folderSites?.map((siteModel, index)=>{		

                                // Only return first batch of paginated sites in x-row.
                                if( index > 99 ){ return null }

                                const siteUnavailable = siteModel?.is_coming_soon ?? false;

                                return siteModel && (
                                        <Site  
                                            key={`site-${siteModel.id}-${folder.id}-item`}  
                                            index={siteModel.id}  
                                            site={siteModel}
                                            isFeed={ folder.is_feed }
                                            containingFolderID={folder.id}
                                            login={login}
                                            duplicateTemplate={duplicateTemplate} 
                                            setForDuplication={setForDuplication}
                                            progressXScroll={siteModel.id === progressXScroll}
                                            message={message}
                                            siteUnavailable={ siteUnavailable }
                                        /> 
                                    )
                                })}
                            </>
                        )
                        }
                    </div>

                </div>
            )
        } else {
            return null
        }

}

function mapReduxStateToProps(state, ownProps) {

	return {
		folders     : state.templates,
		designLab   : state.designLab,
        sitePreview : state.sitePreview,
	};

}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
        updateHomepageState: actions.updateHomepageState,
	}, dispatch);
}

export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(DesignLabXScroll))