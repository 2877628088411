import React, { Component } from 'react';

import _ from 'lodash'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { withRouter } from 'react-router-dom';

import { Login } from "@cargo/common/login";
import { feeds } from "./design-lab/designlabdata";
import RowPage from "./design-lab/row-page";
import DesignLabHeader from "./design-lab/design-lab-header";
import XScrollHandler from './design-lab/x-scroll-handler';

import Navigation from "./navigation";
import BetaWelcomeMessageComponent2 from './beta-welcome-2';
import VideoFrame from "./video-frame";

let initialLoad = true;

let isDev = CARGO_ENV !== 'production';

class DesignLab extends Component {

	constructor(props) {

		super(props);

		this.state = {
			requireLogin: false,
			sticker: 1,
			previousScroll: 0,
		}

		if( !props.hasTemplates && !props.loadingTemplates ){
			this.props.fetchTemplates()
		}

		// this.onDragOver = _.throttle(this.onDragOver, 10);
		// this.clearXScrollScrubbing();
		// this.justClosedSitePreview = false;

		this.headerFrameRef = React.createRef();

		this.showPenguin = false;

		// Create an array of promises for each feed
		const feedPromises = Object.values(feeds).map((feed) => {
			// Check if we've loaded anything
			if ( this.props.designLab.feeds[feed.key].paginationCount <= 0 ) {
				if( isDev ){
					return this.props.fetchRowSitesDev(feed.key, feed.tags);
				}
				// If we haven't, do an initial fetch.
				return this.props.fetchRowSites(feed.key, feed.tags);
			} else {
				// else resolve this part of the promise chain
				return Promise.resolve(); 
			}
		});
		
		// Wait for all promises to complete
		Promise.all(feedPromises);

	}

	componentDidUpdate(prevProps, prevState) {

		if(prevProps.activeFolder !== this.props.activeFolder) {
			// update state with actively rendered folder
			this.props.updateHomepageState({
				renderedFolder: this.props.activeFolder?.id || null
			});
		}

		if( prevProps.authenticated !== this.props.authenticated
			&& this.props.authenticated === true 
			&& this.state.requireLogin
			&& !this.props.siteQueuedForDuplication
		){
			this.setState({ requireLogin: false })
		}

		// if( prevProps.sitePreview.previewingSite === true && this.props.sitePreview.previewingSite === false ){
		// 	// Prevent clicking / pointer events on things after closing the site preview with mousedown.
		// 	this.justClosedSitePreview = true;
		// 	window.addEventListener('mouseup', ()=>{ 
		// 		this.justClosedSitePreview = false;
		// 	}, {once: true})
		// }

	}

	componentDidMount = () => {

		// update state with actively rendered folder
		this.props.updateHomepageState({
			renderedFolder: this.props.activeFolder?.id || null
		});

		// set title
		document.title = 'Cargo Design Lab';

		setTimeout(()=>{
			initialLoad = false;
		}, 1500)

		window.addEventListener('duplicate-site', this.onDuplicateSiteEvent )

	}

	openBetaWelcomeMessage3 = (e) => {

		if( this.props.DLNoticeSeen && !e ){
			return
		}
		
		this.props.addUIWindow({
			component: <BetaWelcomeMessageComponent2 />,
			id: `beta-welcome-message2`,
			props: {
				type: 'popover', 
				positionType: 'center', 
				windowName: 'BetaWelcomeMessage2',
				className: `beta-extra-info ${e ? 'higher' : ''}`,
				ignoreClickout: true,
				clickoutLayer: true,
				clickoutLayerDim: true,
				preventClickout: false,
				closeButton: false,
				draggingUploadedImage: false,
				width: 355,
				waitForHeightBeforeRender: true,
				minimumRenderHeight: 300,
				closeCallback: ()=>{ 
				},
				disableDragging: false,
				enableDragging: false,
			}
		}, { removeGroup: false });

		this.props.updateHomepageState({activeWindow: 'BetaWelcomeMessage2'})

	}

	componentWillUnmount = () => {
		
		window.removeEventListener('duplicate-site', this.onDuplicateSiteEvent )

		this.props.removeUIWindow(uiWindow => { 
			return uiWindow.id === 'beta-welcome-message'
		});

		this.props.removeUIWindow(uiWindow => { 
			return uiWindow.id === 'beta-welcome-message2'
		});

		this.props.updateHomepageState({
			renderedFolder: null
		});

	}

	setForDuplication = (siteID) => {
		this.props.updateHomepageState({'siteQueuedForDuplication': siteID })
	}

	onDuplicateSiteEvent = e => {

		if (!this.props.authenticated) {
			
			this.setForDuplication(e.detail.siteId);

			setTimeout(()=> {
				this.login()
			})
		
		} else {

			this.duplicateTemplate(e.detail.siteId);

		}

	}

	duplicateTemplate = ( siteID, withLogin ) => {

		if( this.props.sitePreview.previewingSite ){
			// close preview by removing the `/preview/xxx` section of the route
			this.props.history.push(this.props.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, ''), {
				preventScroll: true
			});
		}

		if( withLogin ){
			setTimeout(()=> {
				this.continueTemplateDuplication( siteID );
			}, 1000 )
			return
		} else {
			this.continueTemplateDuplication( siteID );
		}

	}

	continueTemplateDuplication = ( siteID ) => {
		// navigate to the root folder
		this.props.history.push("/");

		// scroll to top
		document.documentElement.scrollTo(0, 0);

		this.props.setDuplicatingSiteId('clone');

		let removeLoginFormCheck = new CustomEvent('remove-login-form');

		if( removeLoginFormCheck ){
			document.dispatchEvent( removeLoginFormCheck );
		}
		// duplicate the site into the root folder
		this.props.duplicateSite(siteID, this.props.rootFolder).then((res) => {
			this.props.setDuplicatingSiteId(res.data.id);
			// Immediatly fetch permissions...
			this.props.syncAfterSiteDuplication( this.props.rootFolder, res );
		})

	}

	firstTimeTemplateDupe = ( siteID ) => {
		this.props.duplicateSite(siteID, this.props.rootFolder).then((res) => {
		
			let link = res?.data?.direct_link;
		
			if( !link ){
		
				console.warn('Template flow failed at site forward.')
		
				this.setState({requireLogin: false}, ()=>{
		
					const showRemoteAlert = new CustomEvent('open-remote-alert', {
					   detail: {
					       message: "Something went wrong. Please try again later.",
					   }
					});
		
					document.dispatchEvent( showRemoteAlert );
		
				})
		
				return
			}
		// 
		// 	window.location.href = link+"/edit";
		// 
		})
	}

	login = async () => {

		this.setState({requireLogin: true})

		let removeLoginFormCheck = null;
		// If we're able to click out because we're loggingin from the templates page.
		if( window.location.pathname.includes('/designlab/') ){
			// bind context to this
			const _this = this;	
			// create function to remove login form
			function removeLoginForm(e){
				_this.setState({requireLogin: false})
			}

			// Bind event listener for custom event emitted by form. Will remove itself after
			// single execution.
			document.addEventListener('remove-login-form', removeLoginForm, {once: true} )
		}

		await new Promise(resolve => {
			// await successful login
			const unsubscribe = store.subscribe(() => {

				const state = store.getState();

				if(state.auth.authenticated) {
					// stop listening
					unsubscribe();
					if ( state.homepageState.siteQueuedForDuplication ) {
							// fetch site package
							store.dispatch( actions.fetchAccount() ).then(() => { 

								this.duplicateTemplate( state.homepageState.siteQueuedForDuplication, true )

								this.props.updateHomepageState({'siteQueuedForDuplication': null })
								// resolve the promise and keep initing
								resolve();
							});

					} else {
						// fetch site package
						store.dispatch(actions.fetchAccount()).finally(() => {
							// resolve the promise and keep initing
							resolve();

							if( removeLoginFormCheck ){
								document.dispatchEvent( removeLoginFormCheck );
							}
							
						});

					}

					
				}

			})

			document.addEventListener('remove-login-form', ()=>{ unsubscribe() }, {once: true} )

		})
	}

	render() {

		return <>
			{!this.props.authenticated && <Navigation userColor={null} login={this.login} />}

			{this.state.requireLogin && 
				<Login 
					getAuthTokenAction={actions.getAuthToken} 
					canCreateNewAccount={true} 
					templateID={this.props.siteQueuedForDuplication} 
					canClickout={ this.state.requireLogin ? true : false }
					noOverlay={true}
					onClickout={()=> { 
						this.setState({requireLogin: false}) 
						this.setForDuplication(null)	
					}}
					loadingTillFormRemoval={true}
					animateIn={initialLoad===true ? false : null}
				/>
			}

			{this.props.previewingVimeoID && <VideoFrame/>}


			{!this.props.authenticated && <DesignLabHeader 
				openBetaWelcomeMessage3={this.openBetaWelcomeMessage3}
			/>}

			<div id="designlab" className={`${this.props.authenticated ? '' : 'no-auth'}${this.props.activeFolder ? ' filtered' : ''}`}>

				{/* detail view */}
				{this.props.activeFolder ? 
					<>
						{<RowPage
							
							login             = {this.login}
							message           = {this.props.message}
							
							setForDuplication = {this.setForDuplication}
							duplicateTemplate = {this.duplicateTemplate}
						/>}
	 				</> 
				: (
					<div className="sections">
						<XScrollHandler
							login={this.login}
							message={this.props.message}
							history={this.props.history}

							setForDuplication = {this.setForDuplication}
							duplicateTemplate = {this.duplicateTemplate}
						/>
					</div>
				)}

			</div>
		</>

	}

}

function mapReduxStateToProps(state, ownProps) {

	return {
		userSites: state.sites,
		folders: state.templates,
		activeFolder: state.templates.find(folder => folder.slug === ownProps.match.params.folder && folder.slug !== 'saved' ),
		authenticated: state.auth.authenticated,
		rootFolder: state.folders.find(folder => folder.slug === 'all'),
		siteQueuedForDuplication: state.homepageState.siteQueuedForDuplication,
		previewingVimeoID: state.homepageState.previewingVimeoID,
		designLab: state.designLab,
		isLocal: CARGO_ENV === 'localhost',
		userID: state.account?.id,
		sitePreview: state.sitePreview,
		hasTemplates: state.homepageState.hasTemplates,
		loadingTemplates: state.homepageState.loadingTemplates,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchTemplates: actions.fetchTemplates,
		fetchRowSites: actions.fetchRowSites,
		fetchRowSitesDev: actions.fetchRowSitesDev,
		duplicateSite: actions.duplicateSite,
		syncAfterSiteDuplication: actions.syncAfterSiteDuplication,
		setDuplicatingSiteId: actions.setDuplicatingSiteId,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		updateHomepageState: actions.updateHomepageState,
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(DesignLab))