import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import SortableFolder from './sortable-folder';
import _ from 'lodash';
import { actions } from "../../actions";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, horizontalListSortingStrategy, rectSortingStrategy } from '@dnd-kit/sortable';
import {restrictToHorizontalAxis, restrictToWindowEdges, restrictToParentElement} from '@dnd-kit/modifiers';

export default ({ folders, isMobile }) => {

    const [activeId, setActiveId] = useState(null);

    const [items, setItems] = useState(folders);

    const dispatch = useDispatch()

    useEffect(()=> {
        setItems(folders)
    }, [folders])

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 2
            }
        }),
    );

    return (
        <>

            {items && <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                modifiers={[restrictToParentElement]}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                autoScroll={false}
            >
                {items && <SortableContext
                    items={items}
                    strategy={rectSortingStrategy}
                >
                    {items.map((item, index) => 
                        item.name !== 'All' && item.name.toLowerCase() !== 'saved' && <SortableFolder isDisabled={item.name === 'All' || isMobile} item={item} key={index} id={item.id} />
                    )}
                </SortableContext>}
            </DndContext>}
        </>
    )

    function handleDragStart(event) {
        const { active } = event;
        setActiveId(active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (!event || activeId === null || over === null) {
            // Drag hasn't started yet so just ignore.
            return;
        }

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex(x => x.id === active.id);
                const newIndex = items.findIndex(x => x.id === over.id);
                const result = arrayMove(items, oldIndex, newIndex);

                dispatch(
                    actions.saveFolderSort(
                        _.map(result, 'id')
                    )
                );
            
                return result;
            });
        }

        setActiveId(null);
    }
}




