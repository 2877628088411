import React, { useRef, useState, useEffect } from 'react';
import _, { set } from 'lodash'
import { lazyLoadImageObserver } from './lazy-load-image-observer';

export const LookbookSiteTile = ({ data, style={}, dimensions={width: 500, height: 500}, category }) => {

    const hash = data?.inuse_screenshot?.hash ?? data?.media?.hash ?? null;
    const name = data?.inuse_screenshot?.name ?? data?.media?.name ?? null;	
    const imgWidth  = data?.inuse_screenshot?.width ?? data?.media?.width ?? null;
    const imgHeight = data?.inuse_screenshot?.height ?? data?.media?.height ?? null;

    const tileRef = useRef(null);

    const [shouldLoad, setShouldLoad] = useState(false);

	useEffect(() => {

		if(tileRef.current) {
			lazyLoadImageObserver.observe(tileRef.current);
			tileRef.current.runLazyLoad = () => {
				setShouldLoad(true);
			}
		}

		return () => {
			if(tileRef.current) {
				lazyLoadImageObserver.unobserve(tileRef.current);
				delete tileRef.current.runLazyLoad;
			}
		}

	}, [])
    
    const srcWidth = Math.min( imgWidth, Math.ceil((dimensions.width*2)/250)*250);

    let imageSrc = `https://freight.cargo.site/w/${srcWidth}/q/75/i/${hash}/${name}`;
    let instagramHref = data.instagram ? data.instagram : data.inuse_instagram_url ? data.inuse_instagram_url : null;
    let instagramTag = instagramHref ? instagramHref.replace(/^(https?:\/\/)?(www\.)?instagram\.com\/?/g, '').replace('/', "") : null;

    const siteDirectLink = data?.url  ? data.url  : data.direct_link;
    const siteTitle      = data?.name ? data.name : data.inuse_website_title;

    var isPortrait = imgHeight > imgWidth + 10;

    const productName = data?.product_name ?? null;
    const price       = data?.price        ?? null;

    return (
        <div 
            className="lookbook-entry" 
            key={`${data.id}-${hash}`} 
            style={style}
            ref={tileRef}
        >
            {/* <div style={{'position':'absolute','color':'red','fontWeight': '900','background':'black'}}>{data.category}++{data.id}</div> */}
            <a href={siteDirectLink} className={`lookbook-image-frame${isPortrait ? ' portrait' : ''}`} target="_blank">
            {shouldLoad ? (
                <img 
                    className={`lookbook-image`} 
                    src      ={imageSrc} 
                />
            ) : ( 
                <img 
                    className={`lookbook-image`} 
                    src      ={null} 
                />
            )}
            </a>
            <div className="lookbook-captions">
                { productName ? 
                    <span className="lookbook-image-caption">
                        <a href={siteDirectLink} target="_blank">{productName}</a> 
                    </span>
                : null }
                <span className="lookbook-image-caption">
                    <a href={siteDirectLink} target="_blank">{siteTitle}</a>
                </span>
                { price ? 
                    <span className="lookbook-image-caption">
                        <a href={siteDirectLink} target="_blank">{price}</a>
                    </span>
                : null }
                {instagramHref && !price ? ( 
                    <span className="lookbook-image-caption">
                        <a href={instagramHref} target="_blank">@{instagramTag}</a> 
                    </span>
                ) : !instagramHref && !price && category !== 'all' ? (
                    <span className="lookbook-image-caption">
                        <a href="#" target="_blank">{'\u00A0'}</a>
                    </span>
                ) : null}
            </div>
        </div>
    );

};