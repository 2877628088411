import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import _ from "lodash";

export default function(state = {}, action){

	if( !action.payload && !action.meta ) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_ACCOUNT_FULFILLED: 

			if(action.payload.data) {
				// update state here
				let newState = action.payload.data;
				
				return {
					...newState,
					duplicating: null,
				}
				
			}

		break;

		case actionTypes.UPDATE_ACCOUNT_PENDING: 

			if( action.meta ) {
				
				const logState = { ...state, ...action.meta }

				return {
					...state,
					...action.meta
				}
			}

		break;

		case actionTypes.UPDATE_ACCOUNT_FULFILLED: 

			if( action.payload ) {

				return {
					...state,
					...action.payload.data
				}
				
			}

		break;

		case actionTypes.UPDATE_USER_META_PENDING: 

			if( action.meta ) {
				return {
					...state,
					meta: action.meta
				}
			}

		break;

		case actionTypes.UPDATE_USER_META_FULFILLED: 

			if( action.payload ) {
				return {
					...state,
					meta: action.payload.data.meta
				}
			}

		break;

		case actionTypes.SET_DUPLICATING_SITE_ID:
			if( action.payload ) {

				let siteId = action.payload;
				return {
					...state,
					duplicating: siteId
				}

			} else {

				return {
					...state
				}

			}

		break;

		case actionTypes.LOGOUT_USER_FULFILLED:

			return {};

		break;
	}

	return state;

};