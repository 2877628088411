    import React, { useEffect, useRef, useState } from 'react';
    import _ from "lodash";

    const C2FooterIframe = ({ account, authenticated }) => {
        const iframeRef = useRef(null);

        useEffect(() => {

            const iframe = iframeRef.current;
        
            // Add event listener for received messages
            window.addEventListener("message", handleFooterPostMessage);
            iframe.addEventListener('load', handleIframeLoaded);
           
            // remove event listener for cleanup
            return () => {
                window.removeEventListener("message", handleFooterPostMessage);
                iframe.removeEventListener('load', handleIframeLoaded);
            };

        }, []);

        useEffect(() => {
            handleIframeLoaded(null);

        }, [account]);

        const handleIframeLoaded = (e) => {
            
            // There is a scope issue on the event listener, just pull from the store
            const accountData = window.store.getState().account; 
      
            const c2Sites = accountData?.sites && accountData?.sites.length > 0 ? _.filter(accountData.sites, site => { return site.version === 'Cargo2'; }) : [];
            const hasC2Sites = c2Sites.length > 0;

            if( hasC2Sites ){
                const iframe = iframeRef.current;
                if( !iframe || !iframe.contentWindow ) return;
                iframe.contentWindow.postMessage({type: 'command', message: 'hasC2Sites'}, '*');
            }

        }

        const handleFooterPostMessage = (event) => {
            if (event.origin !== "https://cargo.site") return;
            if( event.data.type === 'command' && event.data.message === 'openLink' ){
                const link = event.data.link;
                window.open(link, '_blank')
            }
            if( event.data.type === 'command' && event.data.message === 'footerReady' ){
                handleIframeLoaded(null);
            }
        }

        return (
            <div className="footer-frame">
                <iframe 
                    ref={iframeRef} 
                    id="c2-frame-footer" 
                    src={`https://cargo.site/19147043?ucargo=true${authenticated ? '&ucargofooterlinks=true' : ''}`} 
                />
            </div>
        );
    }

    export default C2FooterIframe;