import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import _ from "lodash";

export default function(state = [], action){

	if(!action.payload && !action.meta) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_ACCOUNT_FULFILLED: 

			if(action?.payload.data) {

				// Adding sites to redux

				const sites = action.payload.data.sites_as_editor || action.payload.data.sites;

				if(!sites) {
					return state;
				}

				sites.forEach(site => {
					delete site.folders;
				})

				// Remove "deleted" sites from list
				// const filteredSites = sites.filter(({is_deleted}) => is_deleted != true);

				return [ ...sites ];
				
			}

		case actionTypes.UPDATE_SITE_LOCAL:
		case actionTypes.UPDATE_INUSE_SITE_PENDING:
		case actionTypes.UPDATE_SITE_PENDING:

			if( action?.meta ){

				// Duplicate state
				let newState = [ ...state]
				// Find site obj in state
				let objIndex = newState.findIndex((item) => item.id === action?.meta.site_id );

				if( objIndex === -1 ){
					return state
				}

				// Create array of keys from change obj.
				let changedKeys = Object.keys( action?.meta.data );
				// iterate through keys, replace each value in obj manually.
				_.each(changedKeys, (key) => {
					newState[objIndex][key] = action?.meta.data[key];
				})

				return newState
			}

		break;

		case actionTypes.DELETE_INUSE_SITE_IMAGE_PENDING:

		if( action?.meta ){

			// Duplicate state
			let newState = [ ...state]
			// Find site obj in state
			let objIndex = newState.findIndex((item) => item.id === action?.meta.site_id );

			if( objIndex === -1 ){
				return state
			}
			
			if( action?.meta && action?.meta.type === 'delete-inuse-image' ){
				newState[objIndex].inuse_screenshot = null;
			}

			return newState
		}

	break;
		
		case actionTypes.UPDATE_INUSE_SITE_FULFILLED:
		case actionTypes.UPDATE_SITE_FULFILLED:

			if( action?.payload.data ){
				// Update a site...
				let newState = [ ...state]
				let objIndex = newState.findIndex((item) => item.id === action.payload.data.id );

				if( objIndex === -1 ){
					return state
				}

				if( _.isEqual( newState[objIndex], action.payload.data ) ){
					// if equal return state
					return state
				} else {
					// else return new value
					newState[objIndex] = action.payload.data
					return newState
				}

			}

		break;

		// Figure out how to look at history to do this right...
// 		case actionTypes.UPDATE_SITE_REJECTED:
// 
// 
// 			}
// 
// 		break;

		case actionTypes.DELETE_SITE_PENDING:

			if( action?.meta ){
				// Duplicate state
				let newState = [ ...state]
				// Find site obj in state
				let objIndex = newState.findIndex((item) => item.id === action?.meta.siteId );

				if( objIndex === -1 ){
					return state
				}

				if( newState[objIndex]?.is_deleted === false && newState[objIndex]?.version !== 'Cargo2' ){
					// C3 site soft delete where is_deleted has flipped from true -> false.
					newState[objIndex].is_deleted = true
					return newState 

				} else {
					// Permanent delete. Filter it out of the collection. 
					newState = newState.filter((item) => item.id !== state[objIndex].id )
					return newState

				}
				
				return newState
			}

		break;

		case actionTypes.DELETE_SITE_FULFILLED:

			if( action?.payload.data ){
				// Delete a site...
				let newState = [ ...state]

				let objIndex = newState.findIndex((item) => item.id === action.payload.data.id || item.id === action.meta.siteId );
				// objIndex = objIndex === -1 ? newState.findIndex((item) => item.id === action.meta.siteId ) : objIndex;

				if( objIndex === -1 ){
					return state
				}

				// All Store mutation is now handled before the API result is returned in DELETE_SITE_PENDING.
				// Rather than modify anything, lets replace the current item with the server response just to
				// be sure everything is in proper sync if a soft delete took place.

				newState[objIndex] = action.payload.data
				return newState 
				
			}

		break;

		case actionTypes.DELETE_SITE_REJECTED:
			// Handle failure by reversing change...
			if( action.payload && action.meta ){
				// Duplicate state
				let newState = [ ...state]
				// Find site obj in state
				let objIndex = newState.findIndex((item) => item.id === action?.meta.siteId );

				const showRemoteAlert = new CustomEvent('open-remote-alert', {
				   detail: {
				       message: 'Delete failed. Please try again.',
				   }
				});

				document.dispatchEvent( showRemoteAlert );

				if( objIndex === -1 ){
					return state
				}

				if( _.isEqual(newState[objIndex], action.payload.data ) ){
					return state
				} else if( newState[objIndex] ) {
					// Revert state if possible. This will probably require a re-pull of the site collection
					newState[objIndex].is_deleted = false;
					return newState
				}

				return newState
				
			}

		break;

		case actionTypes.LEAVE_SITE_PENDING:
			
			if( action?.meta.siteId ){

				// Optimistically delete site from local data.
				let newState = [ ...state]
				// Find site obj in state
				let objIndex = newState.findIndex((item) => item.id === action?.meta.siteId );

				if( objIndex === -1 ){
					return state
				}

				newState[objIndex].is_deleted = true

				return newState;

			}

		break;

		case actionTypes.LEAVE_SITE_FULFILLED:

			if( action?.meta.siteId ){
				// Do nothing...
			}

		break;

		case actionTypes.LEAVE_SITE_REJECTED:

			if( action?.meta.siteId ){
				// Do nothing...
			}

		break;

		case actionTypes.DUPLICATE_SITE_PENDING:

			const progenitorSite = action.meta?.site;
			const withoutModel = action.meta?.withoutModel;

			if(!progenitorSite) {
				return state;
			}
			
			let index = state.findIndex(({id}) => id === progenitorSite.id);
			// no index? assume it is not from this acct and go directly to 0
			index = index === -1 ? 0 : index;

			if( withoutModel ){
				index = 0;
			}

			if( index !== null && index !== undefined && index !== -1 ){
				
				/* When a site is in the process of being duplicated:
				1. Immediately clone the site in the store, but add an is_clone flag
				2. Once the site is duplicated, remove the clone and add the duplicate to the store (done in the DUPLICATE_SITE_FULFILLED action)
				*/

				const clone = _.cloneDeep(progenitorSite);
				clone.is_clone = true;
				clone.id = "clone";
				clone.is_deleted = false;
				clone.deleted_at = null;
				if( withoutModel ){
					clone.screenshot = null
				}
				const newState = [...state];

				// insert it before the cloned item
				newState.splice(index, 0, clone);

				return newState;

			
			}
			
		break;

		case actionTypes.DUPLICATE_SITE_FULFILLED:

			if(action.payload?.data){

				const duplicate = action.payload.data;
				
				return state.map(site => {

					if(site.id === "clone"){
						// replace clone with new item
						return {
							...duplicate
						}

					} else {
					
						return site;

					}
				});
			}

		break;

		case actionTypes.DUPLICATE_SITE_REJECTED:

			console.error("The site couldn't be duplicated", action);

			if(action){
				const newState = state.filter(({id}) => id !== "clone");

				return [...newState]
			}

		break;

		case actionTypes.CONFIRM_DUPLICATION:

			if(action.payload){

				const newState = state.map(site => {

					if(site.progress_id === action.payload){
						return {
							...site,
							progress_id: null
						}
					} else return site
				});

				return newState;
			}
			
		break;

		case actionTypes.REMOVE_SITE_LOCAL:

			if( action?.meta.siteId ){

				let newState = [ ...state]
				// Find site obj in state
				let objIndex = newState.findIndex((item) => item.id === action?.meta.siteId );

				if( objIndex === -1 ){
					return state
				}

				newState[objIndex].is_deleted = true

				return newState;

			}

		break;

		case actionTypes.LOGOUT_USER_FULFILLED:

			// clear loaded sites
			return [];

		break;

		case actionTypes.RESTORE_SITE_FULFILLED:

			if( action?.payload.data ){
				// Update a site...
				let newState = [ ...state];

				let objIndex = newState.findIndex((item) => item.id === action.payload.data.id );

				let siteToRestore = action.meta.site;
				let payloadData = action.payload.data;

				const restoredSite = {
					...siteToRestore,
					...payloadData,
					is_deleted: false
				}

				newState = [ restoredSite,...newState ];

				return newState

			}

			return state

		break;

		case actionTypes.RESTORE_SITE_REJECTED:

			const showRemoteAlert = new CustomEvent('open-remote-alert', {
			   detail: {
			       message: 'Restore failed. Please try again.',
			   }
			});

			document.dispatchEvent( showRemoteAlert );

		break;

	}

	return state;

};