import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { deviceType } from "@cargo/common/helpers";
import _ from "lodash";

let localStorageContents = {};

try {
	localStorageContents = JSON.parse(localStorage.getItem('homepageState.localstorage'))
} catch(e) {
	if (! helpers.isServer || ! helpers.isLocalEnv) {
		console.warn('Unable to retrieve homepage state from localStorage');
	}
}

// Method to clear localstorage from the console
window.clearHomepageLocalStorage = () => {
	localStorage.clear()
	window.location.reload()
}

const defaultHomepageState = {
	currentSearchTerm: null,
	hasFolders: false,
	hasTemplates: false,
	loadingTemplates: false,
	renderedFolder: null,
	hasAccount: false,
	isMobile: false,
	darkMode: false,
	hasUserMeta: false,
	trashFetched: false,
	designLabIframeSeen: false,
	siteQueuedForDuplication: null,
	queryStringDuplicateId: null,
	previewingVimeoID: null,
	suppressLoginForm: false,
	localStorage: localStorageContents || {},
	deviceType: deviceType()
}

export default function(state = defaultHomepageState, action){

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.UPDATE_HOMEPAGE_LOCALSTORAGE:

			let newStateAssign = _.assign({}, defaultHomepageState, state, action.payload);

			// new localStorage stuff came in
			if(action.payload.localStorage) {

				newStateAssign = {
					...newStateAssign,
					localStorage: {
						...state.localStorage,
						...action.payload.localStorage 
					}
				}

				// save to localStorage
				localStorage.setItem('homepageState.localstorage', JSON.stringify(newStateAssign.localStorage))

			}

			return newStateAssign;
		
		case actionTypes.UPDATE_HOMEPAGE_STATE: 
			return _.merge({}, defaultHomepageState, state, action.payload);

		case actionTypes.FETCH_TEMPLATES_PENDING: 

			return {
				...state,
				loadingTemplates: true
			}

		case actionTypes.FETCH_TEMPLATES_FULFILLED: 

			return {
				...state,
				hasTemplates: true,
				loadingTemplates: false
			}

		case actionTypes.FETCH_FOLDERS_FULFILLED: 

			return {
				...state,
				hasFolders: true
			}
			
		case actionTypes.FETCH_DELETED_SITES_FULFILLED: 

			return {
				...state,
				trashFetched: true
			}

		case actionTypes.FETCH_ACCOUNT_FULFILLED:
			const newState = {
				...state,
				hasAccount: true
			}

			if(action.payload.data?.folders) {
				newState.hasFolders = true;
			}

			return newState;

		case actionTypes.UPDATE_USER_META_FULFILLED: 

			const newState1 = {
				...state,
				hasUserMeta: true
			}

			return newState1;


		case actionTypes.LOGOUT_USER_FULFILLED:

			return {
				...state,
				hasAccount: false,
				hasFolders: false,
				trashFetched: false
			}

	}

	return state 

};
