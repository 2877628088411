import React, { Component, useEffect } from 'react';
import { matchPath } from "react-router";
import { NavLink, withRouter, Route, Switch, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../actions";
import "../css/homepage.scss";
import BaseUnit from "./baseunit";
import UIWindowLayer from "./ui-window-layer";
import { UIWindow, RemoteAlert, HotKey } from "./ui-kit";
import { ContextMenu, MenuContext } from "@cargo/common/context-menu";
import { ToolTip } from "@cargo/common/tooltip";
import { IntercomButton } from '@cargo/common/intercom';
import { DroppableWrapper } from "./droppable-context-wrapper";
import Navigation from "./navigation";
import SitesList from "./siteslist";
import DesignLab from "./design-lab";
import Lookbook from "./lookbook";
import PublicFolder from './public-folder';
import C2FooterIframe from './c2-footer-iframe';
import { toggleDarkMode, storeDarkMode } from '../darkmode';
import { Login } from "@cargo/common/login";
import _ from 'lodash';
import { deviceType, isSafari, isMac, isIOS } from "@cargo/common/helpers";
import { Alert, AlertContext, Message, MessageContext } from "@cargo/ui-kit";
import * as Sentry from "@sentry/browser";
import SitePreviewer from "./site-previewer";
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ID } from "@cargo/common";
import CargoLogo from "./svg/cargo-logo.svg";

// import { colors } from "@cargo/common/users";
let colors = [
	'#25AF3C'
]

let proxiedModal;
let currentHomepageViewport;
let fetchingInitialData = false;
let initialLoad = true;

export const paths = {
	LOGIN: '/login',
	FOLDER: '/:folder?',
	PUBLIC_FOLDER: '/folder/:userid/:folder',
	PUBLIC_FOLDER_PREVIEW: '/folder/:userid/:folderSlug/preview/:siteid',
	LOOKBOOK: '/lookbook/:category',
	DESIGN_LAB: '/designlab/:folder?',
	PREVIEW: '(.*)?/preview/:siteid',
	ACCOUNT: '/account/:path?/:param1?/:param2?',
}

class App extends Component {
	
	constructor(props) {

		super(props);

		this.userColor = colors[Math.floor(Math.random()*colors.length)];

		this.windowData = {

			baseUnit: -1,

			mobile: {
				active: false,
				chrome: 0, // the size of the user interface, calculated by subtracting window height from maxheight
				maxHeight: 720, // the size of the viewport when the chrome is hidden, caused by scrolling
			},

			window: {
				w: 1280,
				h: 720,
			}

		}

		this.state = {
			attemptedCookieAuth: false
		}
		
		// check stored path and navigate there
		if(this.props.location.pathname === "/") {
			try {
				const duplicatingViaQueryString = this.props?.location?.search && this.props.location?.search?.includes('?duplicate=')
				const joinSiteQueryString = this.props?.location?.search && this.props.location?.search?.includes('?site-join')
				const lastVisitedFolder = localStorage.getItem('last-visted-slug')

				if( lastVisitedFolder && !duplicatingViaQueryString && !joinSiteQueryString ){
					this.props.history.replace(`/${lastVisitedFolder}`);
				}

				if( joinSiteQueryString ){
					this.props.history.replace(`/`);
				}

			} catch(e) { console.error(e) }
		}

		// grab data needed to render
		if( this.props.authenticated && !fetchingInitialData ) {
			fetchingInitialData = true;
			this.fetchInitialData().then(()=>{
				this.props.fetchDeletedSites();
			});
		}

		this.overlayJSX = null;

		this.overlayRoutes = {
			[paths.PUBLIC_FOLDER_PREVIEW] : match => {

				this.overlayJSX = this.previewOverlayJSX(match, true);

			},
			[paths.PREVIEW] : match => {
				
				this.overlayJSX = this.previewOverlayJSX(match, false);

			},
			[paths.ACCOUNT] : match => {

				if(!this.props.authenticated) {
					return false
				}

				// if the locked location is the account manager route, render
				// u.cargo's root in the background
				if(this.lockedUnderlayLocation.pathname === match.url) { 
					this.lockedUnderlayLocation.pathname = '/'
				}

				if(this.props.activeUIWindows.hasOwnProperty('c3-account-manager-window')) {

					this.props.updateUIWindow('c3-account-manager-window', {
						overlayLocation: match
					})

				} else {

					this.fetchInitialData().then(() => {

						this.props.addUIWindow({
							group: 'overlay',
							component: import('./account-manager-window'),
							id: `c3-account-manager-window`,
							props: {
								type: 'popover', 
								positionType: 'center', 
								windowName: 'c3-account-manager',
								overlayHistory: this.props.history,
								overlayLocation: match,
								underlayLocation: this.lockedUnderlayLocation,
								clickoutLayer: true,
								clickoutLayerDim: true,
								draggingUploadedImage: false,
								disableDragging: false,
								preventClickout: true,
								storeDarkMode: storeDarkMode
							}
						});
					});

				}

			}

		}

		this.checkForOverlayPath(this.props.location);

		this.SvhSupported = CSS.supports("height", "100svh");

		if( this.SvhSupported ){

			this.minHeightMeasuringStick = document.createElement('div');
			this.minHeightMeasuringStick.setAttribute('style', 'position: fixed; top: 0;left: 0;z-index: -99; visibility: hidden; height: 100svh;');
			this.minHeightMeasuringStick.setAttribute('tabindex', -1);
			this.minHeightMeasuringStick.setAttribute('aria-hidden', true);
			document.body.appendChild(this.minHeightMeasuringStick);				
		}

		this.maxHeightMeasuringStick = document.createElement('div');
		this.maxHeightMeasuringStick.setAttribute('style', 'position: fixed; top: 0;left: 0;z-index: -99; visibility: hidden; height: 100vh;');
		this.maxHeightMeasuringStick.setAttribute('tabindex', -1);
		this.maxHeightMeasuringStick.setAttribute('aria-hidden', true);
		document.body.appendChild(this.maxHeightMeasuringStick);		

		this.portraitAspectQuery = window.matchMedia(`(max-aspect-ratio: 4/5)`);
		this.touchQuery = window.matchMedia(`(pointer: coarse)`);
		this.setupMobileListener();
		this.onWindowResize();
		this.setOSClass();

		window.addEventListener('resize', this.onWindowResize, {passive: true});

	}

	previewOverlayJSX = ( match, isPublicFolder ) => {
		const fromDesignLab = matchPath(this.props.history.location.pathname, { path: paths.DESIGN_LAB, exact: false }) ? true : false;
				// strip the preview URL part out of the underlay location so we render the location in the background
				this.lockedUnderlayLocation.pathname = this.props.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, '');

		return( 
			<SitePreviewer 
				siteToPreview={match.params.siteid}
				overlayMatch={match}
				fromDesignLab={fromDesignLab}
				fromPublicFolder={isPublicFolder}
			/>
		)
	}

	setMobileChange = (isMobile) => {
		this.props.updateHomepageState({
			isMobile
		});

		this.setMobileClass(isMobile);

	}

	setMobileClass = (state) => {

		if (state) {
			document.body.classList.add('mobile')
			document.documentElement.classList.add('mobile')
		} else {
			document.body.classList.remove('mobile')
			document.documentElement.classList.remove('mobile')
		}
	}

	setOSClass = () => {
		if (!isMac() && !isIOS()) {
			document.body.classList.add('f-f');
		}
	}

	getBaseUnit(options) {
		options = options || {};

		var baseUnit = 0;
		options = _.extend(true, {
			height: this.windowData.window.h,
			width : this.windowData.window.w,
			min   : 11,
			max   : false,
			weight: this.touchQuery.matches && !this.portraitAspectQuery.matches ? 16 : 5,
		}, options);

		// Start height or width, depending upon which is smallest
		baseUnit = options.width < options.height ? options.width : options.height;
		
		// Set as a percentage of the width/height
		baseUnit /= (100 / options.weight);

		// Minimums and maximums
		if (options.min && baseUnit < options.min) baseUnit = options.min;
		if (options.max && baseUnit > options.max) baseUnit = options.max;

		// Return the calculated unit
		return baseUnit;
	};

	onWindowResize =() =>{

		const {windowData} = this;

		let w = document.documentElement.clientWidth;
		let h = 0;
		let chrome = 0;

		let minHeight = 0;
		let maxHeight = this.maxHeightMeasuringStick.getBoundingClientRect().height;

		if( this.SvhSupported ){

			minHeight = this.minHeightMeasuringStick.getBoundingClientRect().height;		

		} else {

			let increment = 100;
			minHeight = 0;
			let lastResult;

			while(true) {

				const result = window.matchMedia.call(window, '(min-height:' + (minHeight) + 'px)')['matches'];

				if(lastResult !== undefined && result !== lastResult) {
					// we've overshot the value. Increase granularity
					increment /= 2;
					// start searching below the current value
					increment *= -1;
				}
				
				minHeight += increment;
				lastResult = result;

				// we've honed in on a value with 2 decimal precision
				if(Math.abs(increment) < 0.01) {
					minHeight = Math.round(minHeight)
					break;
				}

			}

		}

		chrome = maxHeight-minHeight;
		h = minHeight;

		let _diff   = 0;
		let _scale  = 5;
		let _size   = 0;
		let _weight = this.touchQuery.matches && !this.portraitAspectQuery.matches ? 16 : 9; // default value

		// Is this portrait?
		if (h+chrome > w) {
			_diff = ((h+chrome) / w) - 1;
		}

		// Ratio
		_diff = (_diff / 0.777777778);

		// iPhone vertical orientation ratio
		if (_diff > 1) {
			_diff = 1;
		}

		// Base unit
		_size = this.getBaseUnit({
			weight: _weight + (_scale * _diff),
			min: 20,
			width: w,
			height: h
		});
		
		if ( _size !== windowData.size ) {
			document.documentElement.style.setProperty('--base-size', _size + '%'); 
			document.documentElement.style.setProperty('--viewport-height', h+'px'); 
		}

		windowData.window.h = h;
		windowData.window.w = w;
	
		windowData.mobile.maxHeight = maxHeight;
		windowData.mobile.chrome = windowData.mobile.maxHeight - windowData.window.h;
		windowData.baseUnit= _size;

		this.windowData = windowData;
	}

	onMobileChange = () => {

		let isMobile;

		// admin viewport overrides mediaQuery results
		if(currentHomepageViewport) {
			isMobile = currentHomepageViewport === 'mobile';
		} else {
			isMobile = this.portraitAspectQuery.matches || this.touchQuery.matches || /Android/i.test(navigator.userAgent || '');
		}

		if(isMobile !== this.windowData.mobile.active) {
			this.windowData.mobile.active = isMobile;
			// this.emit('mobile-change', [isMobile]);
			this.setMobileChange(isMobile)
		}

	}

	setupMobileListener =()=>{

		this.portraitAspectQuery.removeEventListener('change', this.onMobileChange);

		// trigger immediately to test current state
		this.onMobileChange();

		// bind listener for future changes
		const matchListener = this.portraitAspectQuery.addEventListener('change', this.onMobileChange);

	}

	fetchInitialData = () => {

		// don't run this multiple times
		if(this.initialDataPromise) {
			return this.initialDataPromise
		}

		const promises = [];

		if( !this.props.hasAccount ){
			promises.push(this.props.fetchAccount())
		}

		return this.initialDataPromise = Promise.all(promises);

	}

	componentDidUpdate(prevProps, prevState) {

		if(
			prevProps.authenticated !== true
			&& this.props.authenticated === true
		) {
			// clear old initial data promise
			delete this.initialDataPromise;
			// went from not authed to authed
			this.fetchInitialData().then(()=>{
				this.props.fetchDeletedSites();
				this.checkForOverlayPath(this.props.location);
			});
		}

		if( !prevProps.hasAccount && this.props.hasAccount ){
			this.duplicateFromQueryString(this.props.location);
		}

		if (this.props.location !== prevProps.location) {

			this.addBodyClassOfPath(prevProps);

			if (!this.props.location.pathname.match('account') && !prevProps.location.pathname.match('account')) {
				document.documentElement.scrollTo(0, 0)
			}

		}

		if( this.props.suppressLoginForm === true ){
			setTimeout(()=>{
				this.props.updateHomepageState({ suppressLoginForm: false })
			}, 1000)
		}

	}

	componentDidMount() {

		// preload CSS to prevent window flash
		import('@cargo/subscription-manager/css/account-settings-window.scss');

		this.addBodyClassOfPath();
		window.addEventListener("keydown", this.handleGlobalKeyDown);
		window.addEventListener("keyup", this.handleGlobalKeyUp);

		this.checkForOverlayPath(this.props.location);

		if( !this.props.authenticated ){
			this.duplicateFromQueryString(this.props.location);
		}

		setTimeout(()=>{
			initialLoad = false;
		}, 1500)

	}

	handleGlobalKeyDown = (e) => {
		if ( ( isMac() && e.metaKey === true ) || ( !isMac() && e.ctrlKey === true ) ) {
			document.body.classList.add('meta-key-pressed')
		}
	}

	handleGlobalKeyUp = (e) => {
		if ( ( isMac() && e.metaKey !== true ) || ( !isMac() && e.ctrlKey !== true ) ) {
			document.body.classList.remove('meta-key-pressed')
		}
	}

	addBodyClassOfPath = (prevProps) => {

		if ( this.props.isAccountManager || ( prevProps && prevProps.isAccountManager ) ) { return; }

		if (prevProps) {

			let classToRemove = null;

			if( prevProps.isPublicFolder ){
				classToRemove = 'public-folder';
			}

			if( prevProps.isDesignLab ){
				classToRemove = 'designlab';
			}

			if( prevProps.isLookbook ){
				classToRemove = 'lookbook';
			}

			if( classToRemove ){
				document.body.classList.remove(classToRemove)
			}

		}
	
		if ( !this.props.isDesignLab && !this.props.isPublicFolder && !this.props.isLookbook ) return;

		let classToAdd = this.props.isDesignLab ? 'designlab' :
						this.props.isPublicFolder ? 'public-folder' :
						this.props.isLookbook ? 'lookbook' :
						null;
						
		if( classToAdd ){
			document.body.classList.add(classToAdd)
		}
		
	}

	shouldComponentUpdate(nextProps) {

		if (this.props.location !== nextProps.location) {
			this.checkForOverlayPath(nextProps.location);
		}

		return true;
	}

	checkForOverlayPath = (nextLocation) => {

		for( const path in this.overlayRoutes ) {

			const match = matchPath(nextLocation.pathname, {
				path
			})

			if(match) {

				if(_.isEqual(match, this.lastMatch)) {
					return;
				}

				this.lastMatch = match;
				
				// lock the underlay location
				if(!this.lockedUnderlayLocation) {
					this.lockedUnderlayLocation = {...this.props.location};
				}

				// run the callback
				this.overlayRoutes[path](match);

				// bail
				return;

			} else {
				
				delete this.lastMatch;

			}

		}

		// if we made it here it means there's no overlay active.
		// If we're still locking the underlay, unlock it and ensure
		// remaining overlays are closed
		if(this.lockedUnderlayLocation) {

			// remove all overlays
			this.props.removeUIWindow(uiWindow => {
				if( !uiWindow.props.clickoutLayer && !uiWindow.props.preventClickout ){
					return false;
				}
				return true;
			});

			this.overlayJSX = null;
			this.lockedUnderlayLocation = null;


		}

	}


	duplicateFromQueryString = (currLocation) => { 
		let searchParams = new URLSearchParams(window.location.search);
		let templateIdentifier = null;

		if( searchParams && searchParams.has('duplicate') ){

			// If we have ?duplicate=xxxx in the URL, get it
			templateIdentifier = searchParams.get('duplicate');
			// delete it
			searchParams.delete('duplicate');
			// remove query string from URL without reloading page
			window.history.replaceState({}, document.title, window.location.origin + window.location.pathname );
		}

		// If we're not authenticated, save the query string to redux for use after auth.
		if( !this.props.authenticated && templateIdentifier ){
			this.props.updateHomepageState({
				queryStringDuplicateId: templateIdentifier
			});
			// Turn back, will re-run function after authentication
			return
		}

		// If we are authenticated
		if( this.props.hasAccount && this.props.authenticated ){
			// turn back if we don't have an ID to duplicate...
			if( !this.props.queryStringDuplicateId && !templateIdentifier ){
				return
			}
			// Otherwise use the value from redux, or straight from the URL
			templateIdentifier = this.props.queryStringDuplicateId ? this.props.queryStringDuplicateId : templateIdentifier;
			// and start duplicating a site.
			window.dispatchEvent(new CustomEvent('duplicate-site-from-query', {
				detail: {
					siteId: templateIdentifier
				}
			}));
			// reset homepage state
			this.props.updateHomepageState({
				queryStringDuplicateId: null
			});
			
		} 

	}

	render() {

		if ((
			this.props.homepageState.deviceType === 'touch' 
			|| this.props.homepageState.isMobile === true
			)
			&& this.props.isDesignLab
		) {

			// log to Sentry
			Sentry.captureMessage('Prevented non-desktop browser from accessing designlab', {
				extra: {
					isHover         : window.matchMedia(`(hover:hover)`).matches,
					isHoverNone     : window.matchMedia(`(hover:none)`).matches,
					isPointerFine   : window.matchMedia(`(any-pointer:fine)`).matches,
					isPointerCoarse : window.matchMedia(`(pointer:coarse)`).matches,
					isPointerNone   : window.matchMedia(`(pointer:none)`).matches
				}
			});

			return (<div className="design-lab-mobile">
				To use Cargo 3, open this page on a desktop&nbsp;browser.
				<br/><br/>You can also manage your existing sites on your phone (both Cargo 2 and Cargo 3), by <NavLink to={'/'} exact className={``}>logging&nbsp;in&nbsp;here</NavLink>.
				
			</div>)
		}
		

		if (!this.props.authenticated || this.props.isLogin){

			if( this.props.isDesignLab || this.props.isPublicFolder ) {
				
				// login is optional in the design lab. First try to login. If cookie
				// auth is completed, just fall through and render
				if(this.state.attemptedCookieAuth !== true) {
					return <Login 
						getAuthTokenAction={actions.getAuthToken} 
						cookieAuthOnly={true}
						onCookieAuthComplete={() => {
							this.setState({
								attemptedCookieAuth: true
							})
						}}
					/>
				}

			} else {

				if( this.props.suppressLoginForm ){ 
					return null 
				}

				// force login
				return (<>
					<Login 
						getAuthTokenAction={actions.getAuthToken} 
						animateIn={initialLoad===true ? false : null}
						canCreateNewAccount={this.props.isLogin ? false : true}
						onLoginSuccess={data => {

							if(this.props.isLogin) {
								// if on the login page, go to home after successfully logging in
								this.props.history.push(`/`);
							}

						}}
					/>
					<Switch location={this.lockedUnderlayLocation || this.props.location}>
						<Redirect from="/design-lab/:folder?" to="/designlab" />
					</Switch>
				</>)

			}

		}

		return (
			<Alert>
				<div id="index-anchor" style={{height:0,width:0}}></div>
				<Message>
					<AlertContext.Consumer>
						{
							modal => {
								proxiedModal = modal;
							}
						}
					</AlertContext.Consumer>
					<AlertContext.Consumer>
					    {(Alert) => (
					    	<RemoteAlert alert={Alert} />
					    )}
					</AlertContext.Consumer>
					<ToolTip>
					<ContextMenu>
					<MessageContext.Consumer>
						{(Message) => (
							<div id="homepage" className={`${!this.props.authenticated ? 'unauthenticated' : ''}${isSafari() ? ' safari' : ''}`}>
								<BaseUnit isTouch={this.touchQuery.matches} isPortrait={this.portraitAspectQuery.matches}>
									<DroppableWrapper>
										{this.overlayJSX}
										<Switch location={this.lockedUnderlayLocation || this.props.location}>
											<Redirect from="/design-lab/:folder?" to="/designlab/:folder?" />
											<Route 
												exact 
												path={paths.DESIGN_LAB}  
												render={ routeProps => {
													return <>
														{this.props.authenticated && <Navigation userColor={this.userColor} />}
														<DesignLab {...routeProps} message={Message} authenticated={this.props.authenticated} />
													</>
											}} />
											<Route 
												exact 
												path={paths.LOOKBOOK} 
												render={ routeProps => {
													return <>
														{this.props.authenticated && <Navigation userColor={this.userColor} />}
														<Lookbook message={Message} {...routeProps} />
													</>
											}} />
											<Route 
												exact 
												path={paths.PUBLIC_FOLDER} 
												render={ routeProps => {
													return <PublicFolder {...routeProps} />
											}} />
											<Route 
												exact 
												path={paths.FOLDER} 
												render={ routeProps => {
													return <>
														<Navigation userColor={this.userColor} />
														<SitesList message={Message} {...routeProps} />
													</>
											}} />
											<Route 
												path={'*'} 
												render={ () => {
													if(!this.lockedUnderlayLocation) {
														// no overlay nor underlay route has been matched.
														return <Redirect to="/" />;
													}
											}} />
										</Switch>
									</DroppableWrapper>
								</BaseUnit>
								{this.props.isDesignLab && <>
									{this.props.authenticated && <CargoLogo />}
									<C2FooterIframe authenticated={this.props.authenticated} account={this.props.account} />
								</>}
							</div>
							)}
						</MessageContext.Consumer>
						{!this.props.isDesignLab && !this.props.isPublicFolder && <AlertContext.Consumer>
							{(Alert) => (
								<IntercomProvider
									appId={INTERCOM_APP_ID}
									autoBoot={false}
									tunnelEnabled={INTERCOM_TUNNEL_ENABLED}
									tunnelPath={'/intercom.tunnel/'}
								>
									<IntercomButton 
										userData={this.props.account} 
										hasAnnexUpgrade={this.props.hasAnnexUpgrade}
										location='u.cargo' 
										sentry={Sentry}
										Alert={Alert}
									/>
								</IntercomProvider>
							)}
						</AlertContext.Consumer>}
						
						<UIWindowLayer history={this.props.history} />
						<HotKey 
							shortcut="ctrl+shift+i"
							boundTo="all"
							callback={toggleDarkMode} 
						/>

					</ContextMenu>
					</ToolTip>
				</Message>
			</Alert>
		);

	}

}

function mapReduxStateToProps(state, ownProps) {

	const isLogin = matchPath(ownProps.history.location.pathname, { path: paths.LOGIN, exact: true }) ? true : false;
	const isPublicFolder = matchPath(ownProps.history.location.pathname, { path: paths.PUBLIC_FOLDER, exact: false }) ? true : false;
	const isDesignLab = matchPath(ownProps.history.location.pathname, { path: paths.DESIGN_LAB, exact: false }) ? true : false;
	const isAccountManager = matchPath(ownProps.history.location.pathname, { path: paths.ACCOUNT, exact: false }) ? true : false;
	const isLookbook = matchPath(ownProps.history.location.pathname, { path: paths.LOOKBOOK, exact: true }) ? true : false;

	return {
		homepageState: state.homepageState,
		hasAccount: state.homepageState.hasAccount,
		queryStringDuplicateId: state.homepageState.queryStringDuplicateId,
		authenticated: state.auth.authenticated,
		activeUIWindows: state.uiWindows.byId,
		account: state.account,
		userMeta: state.account?.meta,
		userColorTheme: state.account?.meta?.User_ColorTheme,
		hasAnnexUpgrade: state.sites.find(site => site.has_annex_upgrade === true) ? true : false,
		suppressLoginForm: state.homepageState.suppressLoginForm,
		sitePreview: state.sitePreview,
		isLogin,
		isDesignLab,
		isPublicFolder,
		isAccountManager,
		isLookbook
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchAccount: actions.fetchAccount,
	fetchFolders: actions.fetchFolders,
	fetchDeletedSites: actions.fetchDeletedSites,
	addUIWindow: actions.addUIWindow,
	updateUIWindow: actions.updateUIWindow,
	removeUIWindow: actions.removeUIWindow,
	updateHomepageState: actions.updateHomepageState,
	updateHomepageLocalStorage: actions.updateHomepageLocalStorage,
	updateUserMeta : actions.updateUserMeta,
}, dispatch)

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(App));
