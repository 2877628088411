import React, { useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSortable } from '@dnd-kit/sortable';
import _ from 'lodash'
import { CSS } from '@dnd-kit/utilities';
import { DroppableContext } from "./droppable-context-wrapper"
import { NavLink } from 'react-router-dom'
import { actions } from "../actions";
import { MenuContext } from "@cargo/common/context-menu/context-menu-controller";
import FolderContextUI from "./folder-context-ui";

const Folder = ({isDisabled, ...props}) => {

    let item = props.item,
        id = props.id,
        updateFolder = props.updatefolder

    const {activeItem, setShouldCancelDrop, setShouldAnimate} = useContext(DroppableContext);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ 
        id,
        disabled: isDisabled
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const folder = useMemo(() => item, [item])
    let isRootFolder = folder.id === props.account.root_folder_id;

    const handlePointerUp = e => {
    }

    const handlePointerEnter = () => {
        setShouldCancelDrop(true)
    }

    const handlePointerLeave = () => {
        setShouldCancelDrop(false)
    }

    let folderRoute = folder.slug === 'All' ? '/' : folder.slug;

    if( !folder.slug ){ return ( null ) }

    return (
        <>
            {folder && <MenuContext.Consumer>
                {(Menu) =>
                    <span
                        style={style}
                    >
                        <NavLink
                            draggable={false}
                            ref={setNodeRef}
                            to={'/' + folderRoute}
                            onPointerUp={handlePointerUp}
                            onPointerEnter={handlePointerEnter}
                            onPointerLeave={handlePointerLeave}
                            activeClassName='active'
                            exact={isRootFolder || folderRoute === 'lookbook'}
                            style={{
                                pointerEvents: props.isDragging ? 'none' : ''
                            }}
                            folder-id={folder.id}
                            className={`route${props.isDragging ? ' dragging' : ``}`}
                            onClick={(e) => {
                                // Disable animations when navigating to new folder
                                setShouldAnimate(false);
                            }}
                            onContextMenu={e => {

                                // don't do a context menu on the root folder
                                if (isRootFolder) return;
                                if( e.ctrlKey ) return null //context menu is open
                                e.preventDefault();

                                // clear the user's selection
                                (window.getSelection ? window.getSelection() : document.selection).empty()

                                Menu.openMenu({
                                    innerUI: <FolderContextUI folder={folder} />,
                                    type: 'mouse',
                                    yFromButton: true,
                                    event: e,
                                })
                            }
                            }
                        >
                            {folder.name}
                        </NavLink>
                    </span>
                }
            </MenuContext.Consumer>}
        </>
    )

}

function mapReduxStateToProps(state, ownProps) {

    return {
        account: state.account,
    };

}


function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateFolder: actions.updateFolder,
	}, dispatch);

}

export default connect(
    mapReduxStateToProps, 
    mapDispatchToProps
)(Folder);