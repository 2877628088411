import React, { Component, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, NavLink} from 'react-router-dom';
import { matchPath } from "react-router";
import { actions } from "../actions";
import Fuse from 'fuse.js';
import _ from "lodash";
import { paths } from './App';

let isDev = CARGO_ENV !== 'production';

import FolderLinks from "./folder-links";
import BetaWelcomeMessageComponent2 from './beta-welcome-2';
import LookbookNavigation from './lookbook-navigation';

const Navigation = props => {

	const [ revealSearch, setRevealSearch ] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const [hasLookbookLocalStorage, setHasLookbookLocalStorage] = useState(false);

	let searchRef = useRef(null);
	let savedFolder = props.folders.filter(folder => folder.name.toLowerCase() === 'saved')[0]
	let hasSavedSites = savedFolder?.sites?.length > 0;
	let hasC3Sites = props.account?.sites?.filter( (site)=>{if (site?.version === 'Cargo3') {return site}} )?.length > 0;

	useEffect(() => {
		if( localStorage.getItem('c3-lookbook-test') === 'true' ){
			setHasLookbookLocalStorage(true)
		}
	}, []);

	const searchShortcut = (e) => {
		if (e.metaKey && e.which === 70 && !props.isLookbook && !props.isDesignLab) {
			e.preventDefault();
			e.stopImmediatePropagation();
			setRevealSearch(true)
			setTimeout(()=>{
				searchRef?.current?.focus();
			},50)
		}
	}

	const handlePostMessage = (event) => {
		if (event.origin !== "https://cargo.site") return;
		if( event.data.message === 'showLoginForm' ){
			props.login();
		}
	}

	const openBetaWelcomeMessage3 = (e) => {

		// if( this.props.DLNoticeSeen && !e ){
		// 	return
		// }
		

		props.addUIWindow({
			component: <BetaWelcomeMessageComponent2 />,
			id: `beta-welcome-message2`,
			props: {
				type: 'popover', 
				positionType: 'center', 
				windowName: 'BetaWelcomeMessage2',
				className: `beta-extra-info ${e ? 'higher' : ''}`,
				ignoreClickout: true,
				clickoutLayer: true,
				clickoutLayerDim: true,
				preventClickout: false,
				closeButton: false,
				draggingUploadedImage: false,
				width: 355,
				waitForHeightBeforeRender: true,
				minimumRenderHeight: 300,
				closeCallback: ()=>{ 
				},
				disableDragging: false,
				enableDragging: false,
			}
		}, { removeGroup: false });

		props.updateHomepageState({activeWindow: 'BetaWelcomeMessage2'})

	}

	useEffect(() => {
		const onIntersection = (entries) => {
		  if (entries[0].boundingClientRect.y < 0) {
			setIsScrolled(true);
		  } else {
			setIsScrolled(false);
		  }
		};
	
		if (
		  'IntersectionObserver' in window &&
		  'IntersectionObserverEntry' in window &&
		  'intersectionRatio' in window.IntersectionObserverEntry.prototype
		) {
		  const observer = new IntersectionObserver(onIntersection);
		  const anchorElement = document.querySelector('#index-anchor');

		  if (anchorElement) {
			observer.observe(anchorElement);
		  }
	
		  // Clean up the observer when the component is unmounted
		  return () => {
			if (anchorElement) {
			  observer.unobserve(anchorElement);
			}
		  };
		}
	  }, []); // Run the effect only once on mount

	useEffect(() => {

		window.addEventListener("message", handlePostMessage, false);

		return(() => {
			window.removeEventListener("message", handlePostMessage, false);

		});	
	}, []);

	useEffect(() => {

		if (!props.location.pathname.match('designlab')) {
			window.addEventListener("keydown", searchShortcut, false);
		}

		window.addEventListener("message", handlePostMessage, false);
		
		return () => {
			window.removeEventListener("keydown", searchShortcut, false);
			window.removeEventListener("message", handlePostMessage, false);
		}
		
	}, [props.location.pathname]);

	useEffect(() => {
		if (props.isDesignLab === true) {
			// if going to /design-lab — clear the search
			setRevealSearch(false)
			props.updateHomepageState({
				currentSearchTerm: null
			});
		}
	}, [props.isDesignLab])

	return !props.auth.authenticated ? (<>
		{props.isDesignLab ? 
			<div className="header-frame">
				<iframe id="c2-frame-header" src="https://cargo.site/19147043?ucargo=true"></iframe>
			</div>
		: null }
	</>) : (
		<>
		<div className="main-navigation">
			<div 
				id={`navigation`} 
				className={`${isScrolled ? 'scrolled' : ''}${props.folders && props.folders.length > 0 ? ' loaded' : ''}`} 
				folder-count={props.folders.length}
			>
				{props.folders && props.folders.length > 0 ? ( <>
					<FolderLinks folders={props.folders} userColor={props.userColor} />
				
					<div className="right">
						{hasC3Sites && !props.isLookbook && !props.isDesignLab && <div id="search-area" className={`${revealSearch ? 'revealed' : ''}${props.isDesignLab || props.isLookbook ? ' invisible' : ''}`}>
							{!revealSearch ?
								<button 
									id="search-reveal"
									onPointerDown={(e)=>{
										setRevealSearch(true)
										setTimeout(()=>{
											searchRef?.current?.focus();
										},200)
									}}
								>
									<svg x="0px" y="0px" viewBox="0 0 35 35" enableBackground="new 0 0 35 35">
									<path fill="#979797" d="M32.682,28.888l-6.421-6.421c1.634-2.48,2.459-5.538,2.085-8.797C27.663,7.706,22.878,2.856,16.921,2.107
										C8.299,1.024,1.019,8.307,2.108,16.931c0.753,5.956,5.605,10.736,11.57,11.416c3.257,0.371,6.311-0.454,8.79-2.086l6.421,6.421
										c0.246,0.246,0.645,0.246,0.891,0l2.903-2.903C32.928,29.533,32.928,29.134,32.682,28.888z M5.025,15.218
										c0-5.62,4.573-10.193,10.193-10.193c5.62,0,10.192,4.572,10.192,10.193c0,5.62-4.572,10.193-10.192,10.193
										C9.598,25.41,5.025,20.838,5.025,15.218z"></path>
									</svg>
								</button>
							: 
								<div className="search-form">
									<input 
										id="search" 
										ref={searchRef}
										type="text" 
										autoComplete="off"
										placeholder="Search…"
										onInput={e => {
											props.updateHomepageState({
												currentSearchTerm: e.currentTarget.value === '' ? null : e.currentTarget.value
											});
										}}
									/>
									<button 
										className="clear-search"
										onPointerDown={()=>{
											setRevealSearch(false);

											// clear search
											props.updateHomepageState({
												currentSearchTerm: null
											});
										}}
									>
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none"><circle opacity="var(--ui-close-circle-opacity)" cx="10" cy="10" r="10" fill="var(--ui-color-flat-reverse)"></circle><path fillRule="evenodd" clipRule="evenodd" d="M10.0002 11.0607L14.3099 15.3703L15.3705 14.3096L11.0609 10L15.3705 5.69036L14.3099 4.6297L10.0002 8.93934L5.69054 4.62964L4.62988 5.6903L8.93958 10L4.62988 14.3097L5.69054 15.3704L10.0002 11.0607Z" fill="var(--ui-color-flat)"></path></svg>
									</button>
								</div>
							}
							
						</div>}


						{(props.isDesignLab || props.isLookbook) && <div className="info-buttons">
							<img
								onClick={(e)=>{
									openBetaWelcomeMessage3(e)
								}} 
								className={`info-icon`}
								src={`${PUBLIC_URL}/images/info.png`}
							/>
							<img
								onClick={(e)=>{
									window.open('https://instagram.com/cargoworld', '_blank')
								}} 
								className={`cargoworld`}
								src={`${PUBLIC_URL}/images/svg-eased-newcolor-quicker.svg`}
							/>
						</div>}

						<span>

							{hasSavedSites ? <NavLink
								className={`route saved-button`}
								draggable={false}
								to={'/saved'}
								activeClassName='active'
								onContextMenu={(e)=>{
									e.preventDefault();
								}}
								onClick={(e) => {
								}}
							>
								Saved
							</NavLink> 
							: <span className="blank saved-button"></span>}

							<NavLink
								className={`route design-lab-button${ props.isLookbook || props.isSaved ? ' gray-inactive' : ''}`}
								draggable={false}
								to={'/designlab'}
								activeClassName='active'
								onContextMenu={(e)=>{
									e.preventDefault();
								}}
								onClick={(e) => {
								}}
							>
								Design Lab
							</NavLink>

							{hasLookbookLocalStorage || isDev ? (
							<NavLink
								className={`route lookbook-category-button${props.isLookbook ? ' active' : ''}`}
								draggable={false}
								to={'/lookbook/all'}
								// activeClassName='active'
								onClick={(e) => {
								}}
							>
								👀 Lookbook
							</NavLink> 
						) : null }

						</span>
						
					</div>
				</>) : ( null )}

				<div>

				</div>
			</div>
		</div>
		{props.isLookbook && <LookbookNavigation isScrolled={isScrolled} folders={props.folders} />}
		</>
	)

}

function mapReduxStateToProps(state, ownProps) {
	
	return {
		folders: state.folders,
		auth: state.auth,
		account: state.account,
		isDesignLab: matchPath(ownProps.history.location.pathname, { path: paths.DESIGN_LAB, exact: false }) ? true : false,
		isLookbook: matchPath(ownProps.history.location.pathname, { path: paths.LOOKBOOK, exact: true }) ? true : false,
		isSaved: matchPath(ownProps.history.location.pathname, { path: '/saved', exact: true }) ? true : false,
		DLNoticeSeen: localStorage.getItem('DLNoticeSeen') === 'true',
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		updateHomepageState: actions.updateHomepageState,
		authenticate: actions.authenticate,
		logout: actions.logout,
		createFolder: actions.createFolder,
		fetchAccount: actions.fetchAccount,
		fetchFolders: actions.fetchFolders,
		addUIWindow: actions.addUIWindow,
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(Navigation));

