import React, {useState, useEffect, useContext } from 'react';
import { DroppableContext } from "./droppable-context-wrapper"
import { NavLink } from 'react-router-dom';
import SortableFolders from './sortable-folder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter} from 'react-router-dom';
import { actions } from "../actions";
import _ from 'lodash';
import { MenuContext } from "@cargo/common/context-menu/context-menu-controller";
import FolderLinkContextUI from "./folder-link-context-ui";
import { useLocation, useHistory } from 'react-router-dom';
import { isServer, isServerRendered } from "@cargo/common/helpers";
import { Alert, AlertContext, Button } from "@cargo/ui-kit";
import { HotKeyProxy } from "./ui-kit";
import { ProfileIcon } from "@cargo/common/users";

const FolderLinks = ({ folders, ...props }) => {

	const { setShouldAnimate } = useContext(DroppableContext);
	const history = useHistory();
	const [items, setItems] = useState(folders); 
	
	useEffect(() => {
		setItems(folders);
	}, [folders]);

	const createFolder = props.createFolder;

	const isAlphanumeric = (str) => {
		const regex = /^(?=.*[a-z0-9])[a-z0-9\s]+$/i;
		return regex.test(str);
	}

	let pointerEventsNone = { pointerEvents: 'none' }

	const badgeLetter = props.name ? isAlphanumeric(props.name) ? props.name.charAt(0).toLowerCase() : props.email?.charAt(0).toLowerCase()  : props.email?.charAt(0).toLowerCase();
	
	const hasUserFolders = folders?.length > 2;

	return (
		<div className="folder-links">
			{ !props.isMobile ? 
			<MenuContext.Consumer>
				{(Menu) => {
						return ( <button
							className="profile"
							// style={{backgroundColor: props.userColor}}
							onPointerDown = { e => {
									// e.preventDefault();
									Menu.openMenu({
										innerUI: <FolderLinkContextUI />,
										type: 'button',
										event: e,
										offset: { x: 15, y: 15 },
									}) 
								}
							}
							onContextMenu = { e => {
								e.preventDefault();
							}}
						>
							<span 
								style={{pointerEvents: 'none', 'color': props.userColor}}
							>
								{/* {badgeLetter ? badgeLetter : '\u00A0' } */}
								<ProfileIcon
									letter = {badgeLetter}
									color = {props.userColor}
								/>
							</span>
						</button> )
					}
				}
			</MenuContext.Consumer>

			: (null)}

			<div className="folders">

				{ props.isMobile ? 
				
				<MenuContext.Consumer>
					{(Menu) => {
							return ( <button
								className="profile"
								onPointerDown = { e => {
										// e.preventDefault();
										Menu.openMenu({
											innerUI: <FolderLinkContextUI />,
											type: 'button',
											event: e
										}) 
									}
								}
								onContextMenu = { e => {
									e.preventDefault();
								}}
							>
								<span 
									style={{pointerEvents: 'none', 'color': props.userColor}}
								>
									{/* {badgeLetter ? badgeLetter : '\u00A0' } */}
									<ProfileIcon
										letter = {badgeLetter}
										color = {props.userColor}
									/>
								</span>
							</button> )
						}
					}
				</MenuContext.Consumer>

				: (null)}

					<div role="button">
						<span role="button">
							<NavLink
								to={'/'}
								activeClassName=""
								exact
								className={`route ${hasUserFolders && window.location.pathname === '/' ? 'active' : ''}`}
								onClick={() => {
									setShouldAnimate(false);
								}}
								onDragStart={(e)=>{
									e.preventDefault();
								}}
								onContextMenu={(e)=>{
									e.preventDefault();
								}}
							>
								All
							</NavLink>
						</span>
					</div>

					<SortableFolders folders={ items } isMobile={ props.isMobile }  />

					<AlertContext.Consumer>
						{(Alert) => <Button
							className="add-folder"
							onClick={
							(e) => {

									Alert.openModal({
										header: 'New Folder',
										type: 'dialog',
										placeholder: 'Folder title...',
										focusInput: true,
										ignoreUnmount: true,
										maxInputLength: 100,
										HotKeyProxy: HotKeyProxy,
										onConfirm: ( folderName ) => {
					
												if( folderName ){
													createFolder( folderName )
												}

										}
									})
								}
							}
						>
							<svg x="0px" y="0px" viewBox="0 0 31 22">
							<path d="M21.5,7.9V4.1h1v3.8h3.8v1h-3.8v3.8h-1V8.9h-3.8v-1H21.5z"/>
							<path style={{fillRule: 'evenodd', clipRule: 'evenodd'}} d="M15.9,5.1c-1.9,0-3.6,0-3.9,0c-0.5,0-0.9-0.2-1.3-0.5l0,0c-0.4-0.3-0.7-0.6-0.9-0.8C9.6,3.7,9.5,3.6,9.3,3.5
								l-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0l-0.1,0c-0.2,0-0.4,0-0.8,0l-3.8,0c-0.4,0-0.7,0-1,0
								c-0.3,0-0.5,0.1-0.8,0.2C2.2,3.7,1.9,4,1.8,4.4C1.6,4.6,1.6,4.8,1.6,5.1c0,0.3,0,0.6,0,1v11.3c0,0.5,0,0.9,0,1.2
								c0,0.3,0.1,0.6,0.2,0.9c0.2,0.4,0.6,0.8,1,1c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.7,0,1.2,0h14.3c0.5,0,0.9,0,1.2,0
								c0.3,0,0.6-0.1,0.9-0.2c0.4-0.2,0.8-0.6,1-1c0.1-0.3,0.2-0.6,0.2-0.9c0-0.3,0-0.7,0-1.2v-2c3.6-0.3,6.5-3.3,6.5-7c0-3.9-3.1-7-7-7
								C19.4,1.4,17.1,2.9,15.9,5.1z M28,8.4c0,3.3-2.7,6-6,6s-6-2.7-6-6c0-3.3,2.7-6,6-6S28,5.1,28,8.4z M21.5,15.4
								c-3.5-0.2-6.2-3-6.5-6.5H4.9c-0.5,0-0.9,0-1.1,0C3.5,9,3.3,9,3.2,9.1C3,9.2,2.8,9.4,2.7,9.6c-0.1,0.1-0.1,0.2-0.1,0.5
								c0,0.3,0,0.6,0,1.1v6.2c0,0.5,0,0.9,0,1.1c0,0.3,0.1,0.4,0.1,0.5c0.1,0.2,0.3,0.4,0.6,0.6c0.1,0.1,0.3,0.1,0.5,0.1
								c0.3,0,0.6,0,1.1,0h14.3c0.5,0,0.9,0,1.1,0c0.3,0,0.4-0.1,0.5-0.1c0.2-0.1,0.4-0.3,0.6-0.6c0.1-0.1,0.1-0.3,0.1-0.5
								c0-0.3,0-0.6,0-1.1V15.4z M15.1,7.9c0-0.7,0.2-1.3,0.4-1.9c-1.7,0-3.1,0-3.4,0c-0.8,0-1.4-0.3-1.9-0.7C9.7,5.1,9.4,4.8,9.2,4.7
								C9.1,4.6,9,4.5,9,4.5c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0l0,0l0,0l0,0l0,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.6,0H4.3
								c-0.4,0-0.7,0-0.9,0c-0.2,0-0.3,0-0.4,0.1C2.9,4.5,2.7,4.6,2.6,4.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.5,0,0.9v2.2
								c0.1-0.1,0.2-0.1,0.3-0.1C3.1,8,3.4,8,3.7,8c0.3,0,0.7,0,1.2,0H15.1z"/>
							</svg>
						</Button>

						}
					</AlertContext.Consumer>



			</div>
		</div>
	);
  



}

function mapReduxStateToProps(state, ownProps) {

	return {
		sites: state.sites,
		email: state.account.email,
		name: state.account.full_name,
		isMobile: state.homepageState.isMobile,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		createFolder: actions.createFolder
	}, dispatch);

}

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(FolderLinks);

