import { combineReducers } from 'redux';

/* import reducers */
import auth from "./auth";
import account from "./account";
import folders from "./folders";
import templates from "./templates";
import sites from "./sites";
import homepageState from "./homepage-state";
import uiWindows from "./ui-windows";
import collaborators from "./collaborators";
import deletedSites from "./deleted-sites";
import subscriptions from "./subscriptions";
import designLab from "./design-lab";
import sitePreview from "./site-preview";
import lookbook from "./lookbook";
import publicFolders from "./public-folders";

export default combineReducers({
	auth,
	account,
	folders,
	templates,
	sites,
	collaborators,
	homepageState,
	uiWindows,
	deletedSites,
	subscriptions,
	designLab,
	sitePreview,
	lookbook,
	publicFolders
});