import _ from 'lodash';
import cargoFetch from '@cargo/fetch';
import { helpers } from "@cargo/common";
import { actionTypes } from "../actions";
import * as Sentry from "@sentry/browser";

const defaultState = {
	authenticating: false,
	authenticated: false
}

function authReducer(state = defaultState, action) {

	switch(action.type) {

		case actionTypes.AUTHENTICATE_USER_PENDING: 
			
			return {
				...state,
				authenticating: true
			}

		case actionTypes.AUTHENTICATE_USER_FULFILLED:

			if(action.payload?.data?.access_token) {

				cargoFetch.setAuthorization(action.payload.data.access_token);

				Sentry.setUser({ id: action.payload.data.id });

				// just to make double sure nobody else can read this.
				delete action.payload.data.access_token;

				return {
					...state,
					authenticating: false,
					authenticated: true,
					data: {
						id: action.payload.data.id
					}
				}

			} else {

				cargoFetch.setAuthorization(null);

				return {
					...state,
					authenticating: false,
					authenticated: false
				}

			}

		case actionTypes.AUTHENTICATE_USER_REJECTED: 
		case actionTypes.LOGOUT_USER_FULFILLED:

			cargoFetch.setAuthorization(null);

			return {
				...state,
				authenticating: false,
				authenticated: false
			}

	}

	return state;

};

export default authReducer;