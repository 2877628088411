import React, { useMemo, useState, useRef, useContext, useEffect } from 'react';
import { DroppableContext } from "./droppable-context-wrapper"
import _, { stubTrue } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { matchPath } from "react-router";
import { deviceType, isLocalEnv } from "@cargo/common/helpers";
import { MenuContext } from "@cargo/common/context-menu/context-menu-controller";
import SiteContextUI from "./site-context-ui";
import { MoreButton } from "./ui-kit"
import ProgressBar from "./progress-bar"
import { LoadingAnimation } from '@cargo/ui-kit';
import { withRouter } from 'react-router-dom';
import SitePrivateIcon from "@cargo/common/icons/site-private.svg";
import MoreIcon from "@cargo/common/icons/more-icon.svg";
import ReadOnlyIcon from "@cargo/common/icons/read-only.svg";
import { generatePath } from "react-router";

import { paths } from "./App";


const lazyLoadImageObserver = new IntersectionObserver(entries => {

	entries.forEach(entry => {
		if(entry.isIntersecting) {
			entry.target.runLazyLoad?.();
			lazyLoadImageObserver.unobserve(entry.target);
		}
	})

}, {
	root: document,
	// load everything in 2 x screen height horizontally and 600px vertically
	rootMargin: (screen.height * 2) + 'px 600px',
	threshold: [0,1]
});

const SiteItem = ({index, site, duplicationState, isDragging, draggingXScroll, transitioning, message, siteUnavailable, containingFolder, withinFolder, userToSite, isCurrentlyInUse, ...props}) => {

	const { setShouldAnimate } = useContext(DroppableContext);
	const isDuplicating = useMemo(() => ( site.is_clone || site.progress_id ) && site.version === 'Cargo2', [site]);
	const [ simulateDuplication, setSimulateDuplication ] = useState( duplicationState === site.id ? true : false);
	const [downState, setDownState] = useState();
	const [highestZIndex, setHighestZIndex] = useState(false);
	const [hoverState, setHoverState] = useState(false);
	let [imageLoaded, setImageLoadedState] = useState( site.is_clone || duplicationState === site.id ? true : false );
	const [imageState, setImageState ] = useState( 'main' ) // main >> email >> fallback
	const [menuOpenFromButton, setMenuOpenFromButton] = useState(false);
	const siteRef = useRef(null);
	const isTouch = deviceType() === 'touch';

	let [dupeButtonMouseDown, setDupeButtonMouseDown] = useState( false );

	// const userToSite = _.find(props.user.permissions, (siteItem) => { 
	// 	return siteItem.site_id === site.id
	// });
	const isSitesInUseEditor = props.authenticated ? props.user.in_use_editor === true : false;
	
	// lazy loading
	const [shouldLoad, setShouldLoad] = useState(false);

	useEffect(() => {

		if(siteRef.current) {
			lazyLoadImageObserver.observe(siteRef.current);
			siteRef.current.runLazyLoad = () => {
				setShouldLoad(true);
			}
		}

		return () => {
			if(siteRef.current) {
				lazyLoadImageObserver.unobserve(siteRef.current);
				delete siteRef.current.runLazyLoad;
			}
		}

	}, [])

	useEffect(() => {
		if( !simulateDuplication && site.version === 'Cargo3' && duplicationState === site.id ){
			setSimulateDuplication(true);
		} else if( simulateDuplication && site.version === 'Cargo3' && duplicationState !== site.id ){
			setSimulateDuplication(false);
		}
	}, [duplicationState])

	useEffect(() => {
		// If we've dropped a site
		if( props.dropping ){
			// set z-index to highest
			setHighestZIndex(true);
			// wait for transition end
			siteRef.current.addEventListener('transitionend', ()=> {
				// unset z-index
				setHighestZIndex(false);
			}, { once: true })
		}
	}, [props.dropping])

	// Recreate downState state...
	const handleSiteDownState = (e) => {

		let type = e.type;
		if ( isDragging && ( type === 'mouseup' || type === 'click') ) {
			// Stop links from firing.
			e.preventDefault();
			e.stopPropagation();
			if( type === 'mouseup' ){
				props.onDraggingMouseUp(e);
			}
		}

		if( e.target.closest('a') ){

			if( e.target.closest('a').classList.contains('site-instagram') ){
				if( downState ){
					setDownState( false )
				} else {
					return
				}
			}

			if( e.type == "pointerdown" || e.type == "dragstart" && !downState ){
				setDownState( true )
			} else if( downState ) {
				setDownState( false )
			}
		
		} else if( e.type !== "pointerdown" ) {
			setDownState( false )
		}

	}

	const showCopyMessage = () => {
		message.showMessage({
			messageText: '✅ Copied.<br /><br />You can paste this into any site (⌘V)',
			duration: 4000,
			preventClickout: false,
			className: 'tall'
		});
	}
		
	const onSubmenuClose = () => {
		setMenuOpenFromButton(false)
	}

	const openSubscriptionInfoPanel = () => {
		props.addUIWindow({
			component: import('./subscriptionInfo'),
			id: `subscription-info`,
			props: {
				type: 'popover', 
				positionType: 'center', 
				windowName: 'subscriptionInfo',
				clickoutLayer: true,
				clickoutLayerDim: true,
				disableDragging: true,
				preventClickout: false,
				draggingUploadedImage: false,
				width: 355,
				waitForHeightBeforeRender: true,
				minimumRenderHeight: 50,
			}
		});
	}

	const copyShortCode = () => {
		let isBackdropCopy = site.website_title.toLowerCase().includes("backdrop");
		let copyText = !isBackdropCopy ? 'cargo:'+site.id : 'cargo:'+site.id+'?copybackdrop';
		navigator.clipboard.writeText( copyText );
		showCopyMessage();
	}

	const TemplateButtons = () => {

		const dontShowContextMenuButton = ( userToSite?.role !== 'Admin' && site.is_deleted ) || props.draggingInProgress || draggingXScroll || props.isPublicFolder;
		if( dontShowContextMenuButton ){ 
			return( null )
		}

		let directLink = site.direct_link;

		if( isLocalEnv ){
			directLink = directLink?.replace('dev.cargo.site', 'local.dev.cargo.site')
		}

		return (
			<MenuContext.Consumer>
				{(Menu) => 
					<MoreButton
						labelIcon={ <MoreIcon /> }
						id={directLink}
						onPointerDown = { e => {
								e.preventDefault();

								if( !userToSite && containingFolder?.slug !== 'saved' ){
									console.warn('Account not associated with site.')
									return
								}

								Menu.openMenu({
									innerUI: <SiteContextUI 
										site={site} 
										userToSite={userToSite} 
										isSitesInUseEditor={isSitesInUseEditor}
										isDesignLab={props.isDesignLab} 
										duplicateTemplate={props.duplicateTemplate} 
										canCopy={props.canCopy}
										canDuplicate={props.canDuplicate}
										isFeed={ props.isFeed }
										isIdea={ props.isIdea }
										copyShortCode={copyShortCode}
									/>,
									type: isTouch ? 'button' : 'mouse',
									event: e,
									offset: isTouch ? { x: 72, y: 0 } : null,
									onClose: onSubmenuClose
								}) 
								setMenuOpenFromButton(true)
							}
						}
						onPointerEnter = { (e) => {
							setHoverState(true);
						}}
					/>
				}
			</MenuContext.Consumer>	
		)
	}

	const SiteDetails = () => {

		let sitePrivate = ( site.is_private || ( site.has_annex_upgrade === false && site.is_upgraded === false ) ) && !props.isDesignLab && !site.is_deleted;

		let directLink = site.direct_link;
		if( isLocalEnv ){
			directLink = directLink?.replace('dev.cargo.site', 'local.dev.cargo.site')
		}

		// If we have an in use title, the role is in use or we're on the design lab, use the in use title. Else if we have a website title, display it. Otherwise, display the site url.
		const title = site?.inuse_website_title && ( userToSite?.role === 'Inuse' || props.isDesignLab ) ? site.inuse_website_title.trim() : site.website_title ? site.website_title.trim() :  site.site_url ?? '';
		let instagramHandle = null;
		let showInstagramHandle = false;

		if( 
			( site?.inuse_instagram_url && userToSite?.role === 'Inuse' && !props.isDesignLab ) || 
			( site?.inuse_instagram_url && props.isDesignLab ) 
		){
			instagramHandle = site?.inuse_instagram_url ? site.inuse_instagram_url.replace(/^(https?:\/\/)?(www\.)?instagram\.com\/?/g, '').replace('/', "") : null;
			showInstagramHandle = ( userToSite?.role === 'Inuse' || props.isDesignLab ) && instagramHandle;
		}

		const isStacked  = site.domain || showInstagramHandle;
		const showDomain = site.domain && site.domain_active;

		return(
			<div className={`details${isStacked ? ' stacked' : ''}`}>

				{site.is_deleted ? 
					<div className="site-title">
						<span className="title deleted">
							{title}
						</span>
					</div>
				: <>
					<div
						draggable={false}
						className="site-links"
					>
						<span draggable={false} className="direct-site-links">
							<a draggable={false} href={directLink} target="_blank" className="title-wrapper link">
								<span className="title">{title}</span>
							</a>
							{showDomain ? (
								<span className="domain-wrapper">
									<a draggable={false} className="domain link" href={directLink} target="_blank">{site.domain}</a>
								</span>
							) : null }
						</span>
						{showInstagramHandle ? (
							<span>
								<a draggable={false} className="site-instagram" href={site?.inuse_instagram_url} target="_blank">@{instagramHandle}</a> 
							</span>
						) : null}						
					</div>

				</>}

				<div className="spacer"></div>

				{<div className="indicators">

					{sitePrivate && !props.fromSavedFolder && !props.isDesignLab ? 
						<span className="icon site-private">
							<SitePrivateIcon />
						</span>
					: null}

					{userToSite?.role === 'Viewer' && !props.fromSavedFolder ? ( 
						<span className="icon read-only">
							<ReadOnlyIcon />
						</span>
					) : ( 
						<>
						{ props.isDesignLab || props.fromSavedFolder ? 
							<>
								{ ( props.canCopy ) && <button
									className={`copy${props.fromSavedFolder ? ' saved' : ''}`}
									onClick={(e)=>{
										copyShortCode();
										e.preventDefault();
										e.stopPropagation();
									}}
								>
									Copy
								</button>}
								{props.canDuplicate && !draggingXScroll && 
								<button
									className={`duplicate${props.fromSavedFolder ? ' saved' : ''}`}
									onMouseDown={(e)=>{ setDupeButtonMouseDown(true); }}
									onPointerDown={(e)=>{ setDupeButtonMouseDown(true); }}
									onMouseUp={(e)=>{
										e.preventDefault();
										e.stopPropagation();

										if( !dupeButtonMouseDown ){ 
											return 
										} else {
											setDupeButtonMouseDown(false)
										}

										if (!props.authenticated) {		
											props.setForDuplication(site.id)	

											props.login()

										} else {
											props.duplicateTemplate(site.id)
										}
									}}
								>
									{!props.authenticated ? 'Start with this site' : 'Duplicate'}
									{/* Duplicate */}
								</button>
								}
							</>
						:
							null
						}
						</>)}

				</div>}
				
			</div>
		)
	}

	const Badges = () => {
		const showTemplateBadge = site.is_template && !props.isDesignLab && containingFolder?.slug !== 'saved';
		const showC2Badge = ( site.version === 'Cargo2' && !props.isDesignLab );
		const showInUseBadge = userToSite?.role === 'Inuse' && isCurrentlyInUse && !props.isDesignLab;

		return (
			<>
			{showInUseBadge ? <div className={`site-badges`}><site-badge type="inuse">In Use</site-badge></div> : null}
			{showC2Badge ?  <div className="site-badges"><site-badge class={`${showInUseBadge ? 'second-badge' : ''}`} type="cargo-2">Cargo 2</site-badge></div> : null}
			{showTemplateBadge ? <div className="site-badges"><site-badge type="template">Template</site-badge></div> : null}
			</>
		)
	}

	// Gate transition styles behind dragging and transition props.
	let transitionStyle = transitioning ? props.transitionStyles : {};

	if( highestZIndex ){
		if( !transitionStyle ){
			transitionStyle = {};
		}
		transitionStyle.zIndex = 200;
	}

	let classList =`site${downState ? ' active' : ''}`+
						`${hoverState ? ' hovering' : ''}`+
						`${imageLoaded ? ' image-loaded' : ''}`+
						`${menuOpenFromButton ? ' menu' : ''}`+
						`${isDuplicating || simulateDuplication ? ' clone' : ''}`+
						`${props.isDesignLab && !draggingXScroll ? ' duplicable': ''}`+
						`${isDragging ? ' dragged' : ''}`+
						`${transitioning ? ' transitioning' : ''}`+
						`${props.dropping ? ' dropping' : ''}`+
						`${site.is_deleted ? ' deleted' : ''}`+
						`${siteUnavailable ? ' unavailable' : ''}`;

	const siteIdPrefix = (CARGO_ENV !== 'production') ? 'dev.' : '';
	const emailScreenshotUrl = `https://freight.cargo.site/w/1000/q/75/i/screenshot/${siteIdPrefix}${site.id}.jpg`;
	const imageHash = ( ( (userToSite?.role === 'Inuse' && !props.isDesignLab ) || props.isDesignLab && props.isIdea ) && site?.inuse_screenshot?.hash ) ? site?.inuse_screenshot?.hash : site?.screenshot?.hash;
	const imageName = ( ( (userToSite?.role === 'Inuse' && !props.isDesignLab ) || props.isDesignLab && props.isIdea )  && site?.inuse_screenshot?.name ) ? site?.inuse_screenshot?.name : site?.screenshot?.name;

	const imageObject = {
		main: `https://freight.cargo.site/w/1000/q/75/i/${imageHash}/${imageName}`,
		email: emailScreenshotUrl,
		fallback: 'https://freight.cargo.site/w/1000/q/75/i/692fc6a7f6de41ef426ea5182a42cab1e7c15ff89fcc457e9dcd1c3b9bbe5704/placeholder.jpg'
	}

	let imageURL = imageObject[imageState];

	let directLink = site.direct_link;
	if( isLocalEnv ){
		directLink = directLink?.replace('dev.cargo.site', 'local.dev.cargo.site')
	}

	return (
		<>
			<div 
				s-url={site.site_url}
				s-id={site.id}
				site-sort={props.sort}
				className={classList}
				ref={props.forwardedRef ? props.forwardedRef : siteRef}
				onPointerDown = { e => {
					handleSiteDownState(e)
				}}
				onClick = { e => { 
					handleSiteDownState(e)
					// don't propagate if within the .details container
					if (e.button == 2 || e.target.classList.contains('details') || e.target.closest('.details')) return;

					if (!e.metaKey && !e.ctrlKey && !isDragging && !isTouch) {
						e.preventDefault();
						e.stopPropagation();

						if(
							!props.progressXScroll 
							&& !site.is_deleted 
							&& !siteUnavailable 
							&& site.id 
						) {
							if( props.containingFolderID ){
								props.updateSitePreview({
									containingFolderID: props.containingFolderID
								});
							}
							props.history.push(props.match.url.replace(/\/$/, '') + '/preview/' + site.id, {
								preventScroll: true
							});
						}

					}
					
					props.onDragStop && props.onDragStop();
				}}
				onDragStart    = { e => { handleSiteDownState(e) } }
				onDragEnd      = { e => { handleSiteDownState(e) } }
				onMouseUp      = { e => { handleSiteDownState(e) } }
				onMouseDown    = { e => { 
									// let ref = props.forwardedRef ? props.forwardedRef : siteRef;
									if (props.onMouseDown) {
										props.onMouseDown(e)
									} 
								}} 
				onPointerLeave = { e => { handleSiteDownState(e) } }
				onContextMenu  = { e => { 
					if (!props.isDesignLab) {
						handleSiteDownState(e) 
					}
				}}
				style={transitionStyle}
			>	
					{ site.is_deleted ? 
						<>
							<div className="deleted-site" >				
								<MenuContext.Consumer>
									{(Menu) => {

										return (
											<div className="site-preview"
												onContextMenu ={ e => {
													e.preventDefault();

													if( !userToSite ){
														console.warn('Account not associated with site.')
														return
													}

													if( userToSite?.role !== 'Admin' ){
														// Only Admin can restore deleted sites.
														return
													}

													if( isDragging ){
														return
													}
													// enable animation in case of animation / deletion
													setShouldAnimate(true);
													Menu.openMenu({
														innerUI: <SiteContextUI 
																	site={site} 
																	isDesignLab={props.isDesignLab} 
																	login={props.login} 
																	duplicateTemplate={props.duplicateTemplate} 
																	authenticated={props.authenticated} 
																	userToSite={userToSite}
																	isSitesInUseEditor={isSitesInUseEditor}
																	canCopy={props.canCopy}
																	canDuplicate={props.canDuplicate}
																	isFeed={ false }
																	isIdea={ false }
																	copyShortCode={copyShortCode}
																/>,
														type: isTouch ? 'button' : 'mouse',
														offset: isTouch ? { x: 45, y: 0 } : null,
														event: e
													}) 
												}}
												onMouseEnter = { (e) => {
													setHoverState(true);
												}}
												onMouseLeave = { (e) => {
													setHoverState(false);
												}}
											>
											<img 
												className={isDuplicating || simulateDuplication ? 'clone' : ''}
												src={ shouldLoad ? imageURL : null }
												width="1000"
												height="625"
												onLoad={() => {
													setImageLoadedState( true )
												}}
												onError={e => {
													if( imageState === 'main' ){
														// console.log("image load failed", site.website_title, '::', site.site_url )
														setImageState( 'email' )
													} else if (imageState === 'email' ){
														// console.warn("email image broken", site.website_title, '::', site.site_url )
														setImageState('fallback')
													}
												}}
											/>
										</div>
										)
									}}
								</MenuContext.Consumer>
							</div>
							{!props.isDesignLab ?TemplateButtons() : null }
						</>
					: 
						<>
							<a 
								href={directLink}
								draggable={false}
							>					
								{ site.version === 'Cargo2' && isDuplicating || site.version === 'Cargo3' && simulateDuplication ? (
									<ProgressBar url={site.display_url} site={site} isClone={site.is_clone} progressId={site.progress_id} version={site.version} simulateDuplication={simulateDuplication} />
								) : ( null )}
								{ simulateDuplication || isDuplicating || site.is_clone ? ( 
									<LoadingAnimation height={'60px'} width={'60px'} className={'linear large duplicate center'} /> 
								) : null }
								<MenuContext.Consumer>
									{(Menu) => {

										const hasImage = site?.screenshot?.hash;

										return (
											<div className={`site-preview ${ isDuplicating || simulateDuplication ? ' cloning' : ''}`}
												onContextMenu ={ e => {
													e.preventDefault();

													if( !props.isDesignLab && !userToSite && containingFolder?.slug !== 'saved' ){
														console.warn('Account not associated with site.')
														return
													}

													// enable animation in case of animation / deletion
													setShouldAnimate(true);
													Menu.openMenu({
														innerUI: <SiteContextUI 
																	site={site} 
																	isDesignLab={props.isDesignLab} 
																	login={props.login} 
																	duplicateTemplate={props.duplicateTemplate} 
																	authenticated={props.authenticated} 
																	userToSite={userToSite ?? null}
																	isSitesInUseEditor={isSitesInUseEditor}
																	canCopy={props.canCopy}
																	canDuplicate={props.canDuplicate}
																	isFeed={ props.isFeed }
																	isIdea={ props.isIdea }
																	copyShortCode={copyShortCode}
																/>,
														type: isTouch ? 'button' : 'mouse',
														offset: isTouch ? { x: 45, y: 0 } : null,
														event: e
													}) 
												}}
												onMouseEnter = { (e) => {
													setHoverState(true);
												}}
												onMouseLeave = { (e) => {
													setHoverState(false);
												}}
											>
											<img 
												className={isDuplicating || simulateDuplication ? 'clone' : ''}
												src={ shouldLoad ? imageURL : null }
												width="1000"
												height="625"
												onLoad={() => {
													setImageLoadedState( true )
												}}
												onError={e => {
													if( imageState === 'main' ){
														// console.log("image load failed", site.website_title, '::', site.site_url )
														setImageState( 'email' )
													} else if (imageState === 'email' ){
														// console.warn("email image broken", site.website_title, '::', site.site_url )
														setImageState('fallback')
													}
												}}
											/>
										</div>
										)
									}}
								</MenuContext.Consumer>
							</a>
							{!props.isDesignLab ? TemplateButtons() : null }
						</>
					}
				{Badges()}
				{SiteDetails()}
			</div>
		</>
	)
}

function mapReduxStateToProps(state, ownProps) {

	const slug = ownProps.location.pathname.substring(1).toLowerCase();
	const isDesignLab = matchPath(ownProps.location.pathname, { path: paths.DESIGN_LAB, exact: false }) ? true : false;
	const isPublicFolder = matchPath(ownProps.location.pathname, { path: paths.PUBLIC_FOLDER, exact: true }) ? true : false;
	const templateFolder = state.templates.find(folder => folder.id === ownProps.containingFolderID);
	const userFolder = state.folders.find(folder => folder.id === ownProps.containingFolderID);
	const containingFolder = isDesignLab ? templateFolder : userFolder;

	const fromSavedFolder = slug === 'saved';

	const permissions = state?.account?.permissions ?? [];

	const userToSite = _.find(permissions, (sitePermission) => { 
		return sitePermission.site_id === ownProps.site.id
	});

	let isCurrentlyInUse = false;

	if( !isDesignLab && !fromSavedFolder && userToSite?.role === 'Inuse' ){ 
		isCurrentlyInUse = ownProps.site?.is_inuse ?? false;
	}

	let canDuplicate = ownProps.site.can_duplicate && !ownProps.site.is_coming_soon;

	return {
		canDuplicate: canDuplicate,
		canCopy     : (state.auth.authenticated && ownProps.site.can_copy) || (!state.auth.authenticated && ownProps.site.can_copy && !canDuplicate) && !ownProps.site.is_coming_soon,
		containingFolder: ownProps.containingFolder ? ownProps.containingFolder : containingFolder,
		rootFolder: state.folders[0],
		templateFolders: state.templates?.folders,
		fromSavedFolder: fromSavedFolder,
		authenticated: state.auth.authenticated,
		user: state.account,
		duplicationState: state.account.duplicating,
		isFeed: containingFolder?.is_feed ? true : false,
		isIdea: containingFolder?.is_idea ? true : false,
		userToSite: userToSite,
		isCurrentlyInUse: isCurrentlyInUse,
		isDesignLab: isDesignLab,
		isPublicFolder: isPublicFolder
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		updateFolder: actions.updateFolder,
		addUIWindow: actions.addUIWindow,
		updateSitePreview: actions.updateSitePreview,
	}, dispatch);
}

export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(SiteItem))
