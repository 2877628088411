import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import _ from 'lodash'
import { NavLink, withRouter } from 'react-router-dom';
import Site from "./site";
import { SectionHeader } from "./design-lab/section-header";

import GlobeIcon from "../svg-icons/globe.svg";


class PublicFolder extends Component {

	constructor(props) {

		super(props);

		this.state = {
			siteRefs: [],
			loadFailed: false
		}

		this.ticking = false;

	}


	setupSiteRefs = () => {

		if ( this.props.sitesInFolder.length > 0 ) {
			let siteRefs = [];
			this.props.sitesInFolder?.map(site => {
				if (site) {
					siteRefs[site.id] = React.createRef();
				}
			})
			this.setState({siteRefs: siteRefs})
		} else {
			this.setState({siteRefs: []})
		}

	}

	componentDidUpdate(prevProps, prevState) {

			// if changing the preview site model
		if ( prevProps.previewingSite === true && this.props.previewingSite === false
		) {
			let siteRef = this.state.siteRefs ? this.state.siteRefs[prevProps.previewSiteModel.id] : null;
			this.scrollToSite(siteRef)
		}

		if (prevProps.sitesInFolder !== this.props.sitesInFolder && this.props.sitesInFolder.length > 0) {
			this.setupSiteRefs();
		}

	}

	componentDidMount = () => {

        if( !this.props.activeFolder ){
            this.props.fetchPublicFolder(this.props.publicFolderUID, this.props.slug).catch((response) => {
                // console.log("response", response )
				this.setState({loadFailed: true})
            });
        }

		// window.addEventListener('scroll', this.onScroll, {passive: true})

		if (this.props.sitesInFolder.length > 0) {
			this.setupSiteRefs();
		}

	}

	componentWillUnmount = () => {
		// window.removeEventListener('scroll', this.onScroll)
	}

	scrollToSite = (siteRef) => {
		if (siteRef?.current) {
			// Scrolling while preview is open doesn't work. Scroll just after close on delay.
			setTimeout(()=> { 
				siteRef.current.scrollIntoView({ block: "center", inline: "nearest" }) 
			}, 1);
		}
	}

	render() {

		if( this.state.loadFailed ){
			return (
				<>
				<div className="row-page public-folder notfound">
					<div className="notfound-message">Page not found.</div>
				</div>
				</>
			)
		}
        
		return (
            <>
			<div className="row-page public-folder">
                { this.props.sitesInFolder.length !== 0 ? (
                    <div id="siteslist" className={`rows`}> 
                        { this.props.sitesInFolder.map((site, index) => (
                            <Site  
                                key={`public-folder-site-${site.id}`}  
                                index={site.id}  
                                site={site}
                                isFeed={this.props.isFeed}

                                containingFolderID={this.props.activeFolder.id}
                                login={this.props.login} 
                                duplicateTemplate={this.props.duplicateTemplate}
                                forwardedRef={this.state.siteRefs ? this.state.siteRefs[site.id] : null} 
                                message={this.props.message}
                                siteUnavailable={ site?.is_coming_soon ?? false }
                                setForDuplication={this.props.setForDuplication}
                            /> 
                        ))} 
                    </div> 
                ) : ( null )}
 			</div>
            </>
		)
	}

}

function mapReduxStateToProps(state, ownProps) {

    const slug = ownProps.match.params.folder ? ownProps.match.params.folder.toLowerCase() : null;

	// if(ownProps.match.params.userid) {
	// 	console.log('load', slug, 'from user', ownProps.match.params.userid)
	// }

	const activeFolder = state.publicFolders.length > 0 ? state.publicFolders.find(folder => folder.slug === ownProps.match.params.folder && folder.creatorId === ownProps.match.params.userid ) : null;
   	const sitesInFolder = activeFolder?.sites ?? [];

	return {
        slug            : slug,
        publicFolderUID : ownProps.match.params.userid,
		activeFolder    : activeFolder,
        sitesInFolder   : sitesInFolder,
		previewingSite  : state.sitePreview.previewingSite,
		previewSiteModel: state.sitePreview.previewSiteModel,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
        fetchPublicFolder   : actions.fetchPublicFolder,
		// paginatePublicFolder: actions.paginatePublicFolder
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(PublicFolder))