import React from 'react';
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./components/App";
import { initDarkMode } from './darkmode';
import { actionTypes } from "./actions";
import { Provider } from 'react-redux';
import { Router } from "react-router";
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import createStore from "./store";
import cargoFetch from '@cargo/fetch';
import { helpers, API_ORIGIN } from "@cargo/common";
import { isProduction, isLocalEnv } from "@cargo/common/helpers";
import * as Sentry from "@sentry/browser";
import _ from 'lodash';

try {

	// Remove legacy cookies that may contain auth data
	if (document.cookie) {
		document.cookie.split(';').forEach((cookie) => {
			const expiry = cookie.replace(/^ +/, '').replace(/=.*/, '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=');
			document.cookie = `${expiry}cargo.site`;
			document.cookie = `${expiry}${window.location.hostname}`;
		});
	}

	// kill legacy localstorage auth
	localStorage.removeItem('c3-auth');

} catch(e) {
	console.error('Unable remove legacy cookies', e);
}

export const { store } = createStore();

cargoFetch.on('rejected', (failedResponse, config) => {

	if(failedResponse.status === 403 && config.preventLogout !== true) {
		// unauthorized request. Assume login credentials are not valid (anymore);
		console.warn('Unauthorized request. Logout...');

		store.dispatch({
			type: actionTypes.AUTHENTICATE_USER_REJECTED,
			payload: {}
		});

	}

});

const appRoot = createRoot(
	document.getElementById('app')
)

// store last scroll pos when leaving
addEventListener('beforeunload', (event) => {
	try {
		sessionStorage.setItem('lastScrollPosition', document.scrollingElement.scrollTop);
	} catch(e) {
		console.error(e);
	}
});


document.addEventListener('DOMContentLoaded', function () {
	initDarkMode(store)
}, false);

// prevent auto scroll restoration by the browser
if (window.history.scrollRestoration) {
	window.history.scrollRestoration = 'manual';
}

// create history object
const history = createBrowserHistory();

// Scroll restoration for page navigation
const oldScrollPositions = {};

const runScroll = (top = 0) => {
	document.scrollingElement.scrollTo(0, top);

	requestAnimationFrame(() => {
		document.scrollingElement.scrollTo(0, top);
	});
}

// scroll to top when navigating (not on back button)
history.push = _.wrap(history.push, function(original, path, options = {}) {

	const newPathname = (typeof path === "string" ? path : path.pathname).replace(/\/$/, "");
	const oldPathName = decodeURIComponent(window.location.pathname.replace(/\/$/, ""));

	// store scroll position of currently rendered content
	oldScrollPositions[oldPathName] = document.scrollingElement.scrollTop;

	// call the orig function
	var args = Array.prototype.slice.call(arguments);

	if(!options.preventScroll) {
		runScroll(options.restoreScroll ? oldScrollPositions[newPathname] : 0);
	}

	args.shift();

	original.apply(this, args);

});

// back/forward buttons
window.addEventListener('popstate', e => {

	const newPathname = decodeURIComponent(window.location.pathname.replace(/\/$/, ""));
	const oldPathName = history.location.pathname.replace(/\/$/, "");

	// update scroll position of currently rendered content
	oldScrollPositions[oldPathName] = document.scrollingElement.scrollTop;

	runScroll(oldScrollPositions[newPathname]);

});


if (!isLocalEnv) {

	Sentry.init({
		environment: CARGO_ENV,
		release: BUILD_VERSION,
		dsn: "https://0813f9f6eb300310f23c630752900633@o4504992622247936.ingest.sentry.io/4506203124465664",
		integrations: integrations => {
			return [
				...integrations,
				new Sentry.BrowserTracing()
			]
		},
		tracesSampleRate: 0,
		tunnel: `${API_ORIGIN.replace('/v1', '')}/sentry/tunnel`
	});

}

appRoot.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>
);