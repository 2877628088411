import { actionTypes, mutateFolderSiteArray } from "../actions";
import { combineReducers } from 'redux';
import _ from "lodash";

export default function(state = [], action){

	if(!action.payload && !action.meta) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_FOLDERS_FULFILLED: 

			if(action.payload.data) {
				return action.payload.data
			}

		break;

		case actionTypes.FETCH_ACCOUNT_FULFILLED: 

			if(action.payload.data?.folders) {
				return action.payload.data?.folders
				
			}

		break;

		case actionTypes.CREATE_FOLDER_PENDING: 

			if( action.meta ) {

				let folderObj = {
					'created_at': null,
					'id': 'temp',
					'master_muid': null,
					'name': action.meta.name,
					'sites': [],
					'sort': null,
					'updated_at': null
				}

				let newState = [ ...state, folderObj ]

				return newState

			}

		break;

		case actionTypes.CREATE_FOLDER_FULFILLED: 

			if(action.payload.data) {

				let newState = [ ...state, action.payload.data]
				newState = _.remove(newState, function(n) { return n.id !== 'temp';});

				return newState
				
			}

		break;

		case actionTypes.ADD_CLONE_TO_FOLDER:

			if(action.meta){

				let newState = [ ...state];
				const updatedFolder = action.meta;
				// Find site obj in state
				let existingFolderIndex = newState.findIndex(folder => folder.id === updatedFolder.id );

				if( existingFolderIndex === -1 ){
					return state
				}

				newState[existingFolderIndex] = _.cloneDeep(updatedFolder);

				return newState;

			}
		
		break;

		case actionTypes.UPDATE_FOLDER_PENDING: 

			if( action.meta ){

				const updatedFolder = action.meta.folder;

				// Find site obj in state
				let existingFolderIndex = state.findIndex(folder => folder.id === updatedFolder.id );

				if( existingFolderIndex === -1 ){
					return state
				}

				// Duplicate state
				let newState = [ ...state];

				newState[existingFolderIndex] = _.cloneDeep(updatedFolder)

				return newState
			}

		break;

		case actionTypes.SAVE_FOLDER_SORT_FULFILLED: 
			// Update folder sort in state when single sort 
			// is fulfilled
			if( action.meta ) {

				let newState = []
				let folderStateForRef = [...state]

				_.each(action.meta, (newSortId, index) => { 
					// Find the folder
				    let folderAtId = folderStateForRef.find((folder)=> folder.id === newSortId );
				    // update sort with index from new array
				    folderAtId.sort = index;
				    // Push the folder into the correct index
				    newState.push(folderAtId);
				})

				return newState
			}

		break;

		case actionTypes.UPDATE_FOLDER_FULFILLED: 

			if(action.payload.data) {

				let existingFolderIndex = state.findIndex((item) => item.id === action.payload.data.id );
				
				if( existingFolderIndex === -1 ){
					return state
				}

				let newState = [ ...state]

				newState[existingFolderIndex] = action.payload.data;

				return newState

			}

		break;

		case actionTypes.UPDATE_FOLDER_REJECTED:

			const clearSortingState = new CustomEvent('clear-sorting-on-newtork-error');
			window.dispatchEvent( clearSortingState );

			const showRemoteAlert = new CustomEvent('open-remote-alert', {
			   detail: {
			       message: 'Sort failed, please refresh. (Sorry)',
			   }
			});

			document.dispatchEvent( showRemoteAlert );
			
		break;

		case actionTypes.DUPLICATE_SITE_PENDING:

			// duping a site in a folder. Update state to 
			// include the dupe inside of the correct folder
			const withoutModel = action.meta?.withoutModel;

			if( action.meta?.folder ) {

				return state.map(folder => {

					if( folder.id === action.meta.folder.id) {
						
						let newIndex = action.meta.insert_before_this_id && folder.sites.findIndex(site => site.site_id === action.meta.insert_before_this_id) || 0;
						// Is this the all folder?
						if( withoutModel ){
							newIndex = 0
						}
						// console.log("Which folder is this?", folder, newIndex, action.meta.offset, action.meta.insert_before_this_id )

						// insert temporary clone in folder
						return {
							...folder,
							sites: mutateFolderSiteArray(folder, {
								folder_id: folder.id,
								site_id: 'clone'
							}, 'add', newIndex + action.meta.offset)
						}

					} 

					return folder;

				});

			}


		break;

		case actionTypes.DUPLICATE_SITE_FULFILLED:

			if(action.meta?.folder) {

				return state.map(folder => {

					if(folder.id === action.meta.folder.id) {
							
						const updatedFolder = {...folder};

						// find clone and replace the site_id with the newly cloned site
						updatedFolder.sites = updatedFolder.sites.map(site => {

							if(site.site_id === "clone") {
								return {
									...site,
									site_id: action.payload.data.id
								}
							}
							return site;

						})
						return updatedFolder;

					} 
					return folder;

				});
				
			}


		break;

		case actionTypes.RESTORE_SITE_FULFILLED:

			const allFolder = state.find((folder)=> { return folder.slug === 'all' });

			if( allFolder && action.payload.data.id ){

				return state.map(folder => {

					if( folder.id === allFolder.id ) {
						
						// console.log("folder", folder )

						const newIndex = 0;
						// insert temporary clone in folder
						return {
							...folder,
							sites: mutateFolderSiteArray(folder, {
								folder_id: folder.id,
								site_id: action.payload.data.id
							}, 'add', newIndex )
						}

					} 

					return folder;

				});

			}


		break;

		case actionTypes.DELETE_SITE_FULFILLED:

		
			if(action.meta?.siteId ) {

				return state.map(folder => {
					// Check if site is in folder
					let objIndex = folder.sites.findIndex((site) => site.site_id === action?.meta.siteId );

					if( objIndex !== -1 ) {
						// create copy of folder
						const updatedFolder = {...folder};

						// find site in folder and filter it out.
						updatedFolder.sites = updatedFolder.sites.filter((site) => site.site_id !== action?.meta.siteId );
						// Return folder with filtered sites list
						return updatedFolder;
					} 

					return folder;

				});
				
			}

		break;

		case actionTypes.DELETE_FOLDER_PENDING: 

			if( action.meta ){
				// Delete a folder...
				let newState = [ ...state]
				newState = _.remove(newState, function(n) { return n.id !== action.meta.id });
				return newState
			}

		break;

		case actionTypes.DELETE_FOLDER_FULFILLED: 

			if( action.payload.data ){
				// Optimistic Delete performed in PENDING 
				return state
			}

		break;

		case actionTypes.LOGOUT_USER_FULFILLED:

			return [];

		break;
	}

	return state;

};