import { actions } from "./actions";
import { matchPath } from 'react-router-dom';

let store;

export const useDarkMode = enable => {

	if(enable) {
		document.body.classList.add('dark');
		document.body.classList.remove('light');
	} else {
		document.body.classList.add('light');
		document.body.classList.remove('dark');
	}

	store.dispatch(actions.updateHomepageState({
		darkMode: enable
	}));

}

export const storeDarkMode = stateToStore => {

	store.dispatch(actions.updateUserMeta({
		User_ColorTheme: stateToStore
	}));

}

export const toggleDarkMode = () => {

	if( document.body.classList.contains('dark') ){
		useDarkMode(false)
	} else {
		useDarkMode(true)
	}
}

export const initDarkMode = _store => {

	store = _store;

	let lastSavedTheme;

	store.subscribe(() => {

		const state = store.getState();
		const currentSavedTheme = state.account?.meta?.User_ColorTheme;
		const deviceThemePreferenceIsDark = window.matchMedia(`(prefers-color-scheme: dark)`).matches;
		const currentHomepageThemeSettingIsDark = state.homepageState?.darkMode;
		const isDesignLab = matchPath(window.location.pathname, { path: '/designlab/:folder?', exact: false }) ? true : false;
		const isAuthenticated = state.auth?.authenticated === true;

		if( !isAuthenticated && isDesignLab //If we're logged out and in the design lab
			&& currentHomepageThemeSettingIsDark === true // And the homepage is set to dark mode
		){
			useDarkMode(false); //Set the design lab to light mode.
		}

		if( !isAuthenticated && !isDesignLab //If we're logged out and not in the design lab
			&& currentHomepageThemeSettingIsDark !== deviceThemePreferenceIsDark // And the theme doesn't match the device preference theme.
		){
			useDarkMode(deviceThemePreferenceIsDark); // Set the theme to match device preference.
		}

		if ( !isAuthenticated ) return; //If we're logged in, 

		if( currentSavedTheme !== lastSavedTheme ) {
			// update this before we run another call to the store
			// and prevent an infinite looop
			lastSavedTheme = currentSavedTheme;

			if( currentSavedTheme !== null && currentSavedTheme !== undefined ) {
				useDarkMode(currentSavedTheme === "dark")
			} else {
				// revert to automatic dark mode
				useDarkMode(darkModeQuery.matches);
			}
		
		}

	})
	
	const darkModeMediaQueryCallback = () => {

		// ignore media query if logged out
		const state = store.getState();
		const isDesignLab = matchPath(window.location.pathname, { path: '/designlab/:folder?', exact: false }) ? true : false;
		const isAuthenticated = state.auth?.authenticated === true;

		if ( !isAuthenticated && isDesignLab ) return;

		// ignore the media query if we have a user preference
		if(lastSavedTheme !== null && lastSavedTheme !== undefined) return;

		useDarkMode(darkModeQuery.matches);

	}

	const darkModeQuery = window.matchMedia(`(prefers-color-scheme: dark)`);
	darkModeQuery.addEventListener('change', darkModeMediaQueryCallback);

	// get initial media query state
	darkModeMediaQueryCallback();

};
