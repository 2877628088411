import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';

export const InUseLaptopTile = ({ data, category, availbleImageWidth, availbleBaseImageWidth }) => {

    const [imageLoaded, setImageLoaded] = useState(false);
    const imageParentRef = useRef(null);

    useEffect(() => {
        return () => {
            setImageLoaded(false);
        }
    }, [data]);

    const handleImageLoad = async (event) => {
        const imgElement = event.target;
        try {
            await imgElement.decode();
            setImageLoaded(true);
        } catch (error) {
            // setImageLoaded(true);
        }
    };


	const imageHash = data?.inuse_screenshot?.hash ?? data?.screenshot?.hash ?? null;
	const imageName = data?.inuse_screenshot?.name ?? data?.screenshot?.name ?? null;
    const imgWidth  = data?.inuse_screenshot?.width ?? data?.media?.width ?? null;
    const imgHeight = data?.inuse_screenshot?.height ?? data?.media?.height ?? null;
	const url = `https://freight.cargo.site/w/1000/q/75/i/${imageHash}/${imageName}`;


	const instagramHref = data.instagram ? data.instagram : data.inuse_instagram_url ? data.inuse_instagram_url : null;
	const instagramTag = instagramHref ? instagramHref.replace(/^(https?:\/\/)?(www\.)?instagram\.com\/?/g, '').replace('/', "") : null;

	const siteDirectLink = data?.url ? data.url : data.direct_link;
	const siteTitle      = data?.name ? data.name : data.inuse_website_title;

    let heightStyle = {};
    let laptopHeightStyle = {};

    // Calculate the aspect ratio based on the provided natural width and height
    const aspectRatio = imgWidth / imgHeight;
    // Calculate the final rendered height based on the aspect ratio
    const calculatedHeight = ( availbleImageWidth / aspectRatio ).toFixed(2);

    const laptopBaseAspectRatio = 1810 / 73;
    const calculatedLaptopHeight = ( availbleBaseImageWidth / laptopBaseAspectRatio ).toFixed(2);

    heightStyle       = imageLoaded ? {} : {height: calculatedHeight + 'px'};
    laptopHeightStyle = imageLoaded ? {} : {height: calculatedLaptopHeight + 'px'};

	return (
		<div className={`in-use-entry`}>
			<div className="laptop-frame">
				<a className="in-use-image-link" href={siteDirectLink} target="_blank" ref={imageParentRef}>
					<div className="laptop-screen">
						<img 
                            className="laptop-screenshot" 
                            src={url} 
                            style    ={heightStyle}
                            onLoad   ={(event) => { handleImageLoad(event) }}
                            // onError  ={() => setImageLoaded(true) }
                        />
					</div>
					<img
						className="laptop-image"
						src={`${PUBLIC_URL}/images/macbook-base.jpg`}
                        style={laptopHeightStyle}
					/>	
				</a>
			</div>
			<div className="lookbook-captions">
				{category === 'inuse' ? ( null ) : (
					<span className="lookbook-link-icon">
						<a href={siteDirectLink} target="_blank">
							{/* <img src={`${PUBLIC_URL}/images/link.png`} /> */}
							<img 
								// src={`${PUBLIC_URL}/images/globe.png`} 
								src={data.favicon_url}
							/>
						</a>
					</span>
				)}
				<span className="lookbook-image-caption">
					<a href={siteDirectLink} target="_blank">{siteTitle}</a>
				</span>
				{instagramHref ? ( 
					<span className="lookbook-image-caption">
						<a href={instagramHref} target="_blank">@{instagramTag}</a> 
					</span>
				) : null}
			</div>
		</div>
    )
}