import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { bindActionCreators } from 'redux';

class VideoFrame extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		document.body.style.overflow = 'hidden';
		window.addEventListener('keydown', this.handleHotKey)
	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleHotKey)
		document.body.style.overflow = '';
	}

	handleHotKey = (e) => {

		if (e.keyCode === 27) {
			e.preventDefault();
			this.closeVideoFrame();
		}
	}

	closeVideoFrame = () => {
		this.props.updateHomepageState({ previewingVimeoID: null });
	}

	render() {

		if( !this.props.previewingVimeoID ) {
			return null;
		}
		
		let vimeoIDstring = this.props.previewingVimeoID.replace(/\//, '?h=')

		return (
			<div 
                className="video-frame"
            >
				<div 
					className="clickout"
					onClick={(e)=>{
						this.closeVideoFrame();
					}}
				></div>
                <div className="iframe-wrapper">
                    <iframe 
                        src={`https://player.vimeo.com/video/${vimeoIDstring}&badge=0&autopause=0&quality_selector=1&autoplay=1&muted=1&player_id=0&app_id=58479`}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture" 
                        title="Upload your own fonts"
                    ></iframe>
                </div>
            </div>
		)

	}

}


function mapReduxStateToProps(state, ownProps) {

	return {
		previewingVimeoID: state.homepageState.previewingVimeoID,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		removeUIWindow: actions.removeUIWindow,
		updateHomepageState: actions.updateHomepageState,
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(VideoFrame)