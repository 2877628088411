import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import _ from 'lodash'
import Site from "./site";
import { filterSites } from '../selectors';
import { Alert, AlertContext } from "@cargo/ui-kit";
import { HotKeyProxy } from "./ui-kit";
import GlobeIcon from "../svg-icons/globe.svg";

class SitesList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			altPressed: false,
			isDragging: false,
			isTransition: false,
			draggingSiteId: false,
			draggedOverFolderId: null,
			isDraggingOverNavigation: false,
			draggedSiteModel: null,
			adjacentIndexes: {
				above: -1,
				below: -1,
				left: -1,
				right: -1,
			},
			insertBeforeTarget: null,
			showInsertionIndicator: false,
			draggingSiteWidth: null,
			mousePos: {
				x: null,
				y: null,
			},
			draggingItemReturnTransition: {
				x: null,
				y: null
			},
			sortTransition: false,
			dropping: false,
			previousSortIndexes: null,
			coordinatesByIndex: null,
			sitesForRender: {},
			slug: this.props.slug,
			isDropping: false,
			scrollingDirection: null,
			initialScrollY: null,
		}

		this.onDragOver = _.throttle(this.onDragOver, 10);
		this.mouseDownTarget = null;
		this.draggingTargetPermissions = null;
		this.dragMap = new Map();
		this.gridRef = React.createRef();
		this.scrollIntervalRef = React.createRef();
		this.transitionMeasureRef = React.createRef();
		this.placeHolderImg = 'https://freight.cargo.site/t/original/i/692fc6a7f6de41ef426ea5182a42cab1e7c15ff89fcc457e9dcd1c3b9bbe5704/placeholder.jpg';

	}

	componentDidUpdate( prevProps, prevState ) {

		if(
			this.props.folders !== prevProps.folders
			|| this.props.slug !== prevProps.slug
		) {
			try {
				if(this.props.slug && this.props.folder) {
					// valid folder path, store it
					localStorage.setItem('last-visted-slug', this.props.slug)
				} else {
					// invalid path, remove from storage
					localStorage.removeItem('last-visted-slug')
				}
			} catch(e) {}
		}

		if(this.props.folder !== prevProps.folder) {
			this.setWindowTitle();

			// update state with actively rendered folder
			this.props.updateHomepageState({
				renderedFolder: this.props.folder?.id || null
			});

		}

		this.dragMargins = this.transitionMeasureRef?.current?.getBoundingClientRect().width;

		if( prevState.sitesForRender 
			&& this.state.coordinatesByIndex 
			&& prevState.sitesForRender.length !== this.state.sitesForRender.length 
			&& this.state.slug === prevState.slug
			// && this.props.currentSearchTerm === prevProps.currentSearchTerm
		){
			this.transitionSitesOnCollectionChange( prevState.sitesForRender, this.state.sitesForRender )
		}

		// if not set
		// OR if the length of sites changes (adding / removing)
		if ( 
				this.state.coordinatesByIndex === null 
				|| this.props.sites.length !== prevProps.sites.length
				|| this.state.slug !== prevState.slug
				// || this.props.sitesForRender !== prevState.sitesForRender
		) {
			this.setCoordinatesByIndex()
		}

		if(    this.props.sites !== prevProps.sites 
			// || this.props.templates !== prevProps.templates
			|| this.props.sites.length !== prevProps.sites.length
			|| this.props.slug !== prevProps.slug
			|| ( this.props.deletedSites !== prevProps.deletedSites && this.props.slug === 'trash' )
			|| prevProps.folder?.sites?.length !== this.props.folder?.sites?.length
			|| ( this.props.savedFolderId === this.props.folder?.id && this.props.templates !== prevProps.templates )
		){
			this.setSitesForRender();
		}

		if( this.props.slug !== this.state.slug ){
			this.setState({slug: this.props.slug })
		}
		
		if( this.props.currentSearchTerm !== prevProps.currentSearchTerm ){
			this.setSitesForRender();
		}
		
		// this.props.uiWindows.byId['account-manager-window']
		// Cancel dragging when any window is open
		if(    this.state.isDragging 
			&& this.props.hasUiWindow
		 ){
			// remove event on mouseup...
			this.setState({
				droppedIndex: null,
				isDragging: false,
				isDropping: true,
			}, ()=>{
				this.onDragStop();
			})
		}

	}

	setCoordinatesByIndex = () => {
		let coordinates = [];

		let sites = document.querySelectorAll('.site');

		_.each(sites, (site, index) => {
			coordinates[index] = site.getBoundingClientRect();
		})

		this.setState({
			coordinatesByIndex: coordinates
		});

	}

	setSitesForRender = () => {

		const sites = this.getSitesForRender();

		this.setState({
			sitesForRender: sites
		}, ()=>{
			this.setCoordinatesByIndex();

			if(this.props.hasFolders) {

				// restore scroll position if saved
				try {
					const scrollPos = parseInt(sessionStorage.getItem('lastScrollPosition'));

					// delete after using
					sessionStorage.removeItem('lastScrollPosition');

					if(!isNaN(scrollPos)) {
						document.scrollingElement.scrollTop = scrollPos;
					}
				} catch(e) {
					console.error(e)
				}

			}
		})

	}

	getSitesForRender = () => {

		if( this.props.slug === 'trash' && !this.props.trashFetched ){
			return {}
		}

		let siteList = this.props.slug !== 'trash' ? this.props.sites : this.props.deletedSites;

		if( this.props.savedFolderId && this.props.folder?.id && this.props.savedFolderId === this.props.folder?.id ){
			siteList = [ ...siteList, ...this.props.templates ]
		}

		const sites = filterSites(siteList, {
			deleted: this.props.slug === 'trash',
			folder: this.props.folder, 
			search: this.props.currentSearchTerm
		});

		return sites;

	}

	startDragging = (e) => {

		this.setCoordinatesByIndex();

		if( this.mouseDownTargetId ){

			// check if we've hit a 3px threshold to start dragging
			let threshold = 2;
			let xThresholdMet = Math.abs(this.state.mouseDownCoordinates?.x - e.clientX) > threshold;
			let yThresholdMet = Math.abs(this.state.mouseDownCoordinates?.y - e.clientY) > threshold;
			if (xThresholdMet || yThresholdMet) {
				window.removeEventListener('pointermove', this.startDragging);
			} else {
				return;
			}

			let draggedSiteID = parseInt(this.mouseDownTargetId);
			let sitesForRender = this.state.sitesForRender;
			let draggedSiteModel = sitesForRender.find((site)=>{ return site.id == draggedSiteID });

			this.setState({
				draggingSiteId: draggedSiteID,
				draggedSiteEl: this.draggedSiteEl,
				draggedSiteModel: draggedSiteModel,
				draggedSiteIndex: _.indexOf(sitesForRender, draggedSiteModel),
				isDragging: true,
				initialScrollY: window.scrollY
			})
			
		}
	}

	onMouseDown = (e) => {
		let originalMouseDownEvent = e;

		// Store event target id captured from DOM. Mousemove is bad at figuring this out.
		this.mouseDownTargetId = e.currentTarget.closest('[s-id]')?.getAttribute('s-id');
		this.draggedSiteEl = e.currentTarget; //Likely the parent element.
		this.preciseDragStartTarget = e.target; //More precise target, needed for knowing if drag started with the title area.
		this.draggingImgURL = e.currentTarget.closest('[s-id]')?.querySelector('img')?.getAttribute('src');
		this.draggingTargetPermissions = _.find(this.props.user.permissions, (siteItem) => { return siteItem.site_id === parseInt(this.mouseDownTargetId) });

		if( this.preciseDragStartTarget.closest('.details') ){
			return 
		}

		if( this.props.slug === 'trash' ) return null // deleted sites page
		if( e.ctrlKey ) return null //context menu is open
		if( e.button === 2 || e.which === 2 ) return null //context menu is open

		// store the original click locations
		let siteRectangle = this.draggedSiteEl.querySelector('.site-preview');
		let rect = e.target.getBoundingClientRect();
		let x = e.clientX - rect.left; //x position within the element.
		let y = e.clientY - rect.top;  //y position within the element.
		if( this.preciseDragStartTarget.closest('.details') ){
			x = siteRectangle?.getBoundingClientRect().width / 2;
			y = siteRectangle?.getBoundingClientRect().height / 2;
		}

		if( !siteRectangle?.getBoundingClientRect().width ){ return }
			
		this.setState({
			draggingSiteWidth: siteRectangle?.getBoundingClientRect().width,
			offsetDragStart: {x: x, y: y},
			mouseDownCoordinates: {x: e.clientX, y: e.clientY}
		})

		// listen for a pointer move to indicate dragging
		window.addEventListener('pointermove', this.startDragging)
		// after dragging is confirmed, determine when it ends
		window.addEventListener('mouseup', (e) =>{
			// remove event on mouseup...
			window.removeEventListener('pointermove', this.startDragging);
			if ( this.state.isDragging && !this.state.isDropping ) {
				this.setState({ isDropping: true },()=>{
					this.onDragStop()
				})
			}
		}, {once: true})
	}

	handleDragKeydown = (e) => {

		// escape while dragging
		if (e.which === 27 && this.state.isDragging) {
			// trick sort to think the dropped index is not changing
			this.setState({
				droppedIndex: null,
				isDragging: false,
				isDropping: true,
			}, ()=>{
				this.onDragStop();
			})
			e.preventDefault();
			
		}
	}

	onSiteMouseUp = (e) => {
		// if not dragging — prevent default and allow a click of the child site link element to propagate

		e.preventDefault();
		e.stopPropagation();

		if ( this.state.isDragging && !this.state.isDropping ) {
			this.setState({ isDropping: true },()=>{
				this.onDragStop()
			})
		}

	}

	transitionSitesOnCollectionChange = ( prevSitesForRender ) => {

		const oldSortIndexes = _.map(prevSitesForRender, site => site.id);
		// set old sort indexs and sort transition to trigger
		// inline styles added / removed from site item
		// creating the transition.
		this.setState({
			sortTransition: true,
			previousSortIndexes: oldSortIndexes,
		}, ()=> {
			this.setState({
				sortTransition: false,
				previousSortIndexes: null
			})
		}, ()=> {
			this.setCoordinatesByIndex();
		})

	}

	sortSites = (sortedSites, skipSorting) => {

		if(this.props.slug === 'trash') {
			return Promise.resolve();
		}

		const oldSortIndexes = _.map(this.getSitesForRender(), site => site.id);
		const newSortIndexes = _.map(sortedSites, site => site.id);

		this.props.folder.sites.forEach((site, index) => {
			site.sort = newSortIndexes.indexOf(site.site_id);
		});


		let sortedSitesLen = sortedSites.length;

		// Get new index from state
		const newIndex = this.state.droppedIndex;
		// Get rect from cached DOM coordinates
		let galleryChildRect = this.state.coordinatesByIndex[newIndex];
		if( !galleryChildRect && sortedSitesLen === newIndex ){
			galleryChildRect = this.state.coordinatesByIndex[newIndex - 1];
		}


		// Subtract mouse pos from gallery child rect. 
		const returnTransitionX = this.state.mousePos.x - galleryChildRect.x;
		const returnTransitionY = this.state.mousePos.y - galleryChildRect.y + ( window.scrollY - this.state.initialScrollY );
		// Begin state setting chain and drop continue drop logic.


		// console.log('return transition', returnTransitionX, returnTransitionY)

		this.setState({
			sitesForRender: sortedSites,
			sortTransition: true,
			previousSortIndexes: oldSortIndexes,
			dropping: true,
			isDragging: false,
			draggingItemReturnTransition: {
				x: returnTransitionX,
				y: returnTransitionY
			}
		}, ()=> {
			this.setState({
				sortTransition: false,
				previousSortIndexes: null
			})
		})

		// match the new sort index to the old of each site
		// transform it the distance of the difference
		// when complete
		if( skipSorting ){ 
			return Promise.resolve()
		} else {
			return this.props.updateFolder(this.props.folder);
		}

	}

	onDragStop = () => {


		this.clearScrollInterval();

		if( this.dragStopping ){ return }
		this.dragStopping = true;
		
		let sitesForRender = this.getSitesForRender();
		sitesForRender = [ ...sitesForRender];
		let oldIndex = this.state.draggedSiteIndex;
		let newIndex = this.state.droppedIndex;
		let skipSorting = oldIndex === newIndex ? true : false;

		// If draggedOverFolderId is set, we are dropping into a folder.
		if( this.state.draggedOverFolderId ){
			// console.log('dragged over folder', this.state.draggedOverFolderId)
			const targetFolder = this.props.folders.find( folder => folder.id == this.state.draggedOverFolderId );
			const currentFolder = this.props.folder;

			// Remove hovering classes.
			[...document.querySelectorAll('.folders a[folder-id].hovering')].forEach(folder=>{
				folder.classList.remove('hovering');
			})

			// Use updateFolderItems to update the folder.
			if (this.state.altPressed === true) {
				// Duplicate the site into the folder
				this.initDuplicateSite(this.state.draggedSiteModel, null, null, targetFolder);
			} else {
				// Add the site to the folder via updateFolderItems
				this.props.updateFolderItems(targetFolder, {site_id: this.state.draggedSiteModel.id}, 'add');

				// If the current folder is anything other than the saved folder, remove the site from the current folder.
				// if (currentFolder.slug !== "all" && currentFolder?.id !== targetFolder.id) {
				// 	this.props.updateFolderItems(currentFolder, {id: this.state.draggedSiteModel.id}, 'remove');
				// }
			} 

			this.clearSortingState();

			return;
		}
		
		// no sort update? just clear everything
		if ( !newIndex && newIndex !== 0 ) {

			this.clearSortingState();

		// if there is a sort update
		} else {

			if( this.state.altPressed
				&& this.draggingTargetPermissions?.role !== 'Viewer' 
				&& this.draggingTargetPermissions?.role !== 'Inuse'
			){
				// if index is the same, we are not resorting.
				if( oldIndex === newIndex ){
					newIndex = null;
				}
				// Duplicate site on alt drop if no resort is required.
				this.initDuplicateSite(this.state.draggedSiteModel, newIndex);

				setTimeout(()=>{
					this.clearSortingState();
				},1)

				return

			}

			let siteInList = sitesForRender.splice(oldIndex, 1)[0];
			// add site in new index position
			sitesForRender.splice(newIndex, 0, siteInList);

			this.sortSites(sitesForRender, skipSorting).then(()=> {

				setTimeout(()=>{
					this.clearSortingState();
				}, 1)

			})
		}

		window.removeEventListener('pointermove', this.startDragging)
	}

	clearSortingState = () => {

		this.dragStopping = false;

		this.setState({
			draggingSiteId: false,
			draggedSiteModel: null,
			draggedOverFolderId: null,
			isDraggingOverNavigation: false,
			isDragging: false,
			droppedIndex: null,
			showInsertionIndicator: false,
			draggingSiteWidth: null,
			draggingSiteHeight: null,
			dropping: false,
			adjacentIndexes: {
				above: -1,
				below: -1,
				left: -1,
				right: -1,
			},
			mousePos: {
				x: null,
				y: null,
			},
			draggingItemReturnTransition: {
				x: null,
				y: null
			},
			isDropping: false,
			initialScrollY: null
		})
	}

	handlePageScrollRegions = ( clientY ) => {

		let nearTop = clientY < 100;
		let nearBottom = clientY > (window.innerHeight - 100);

		if (nearTop && !this.scrollIntervalRef.current ) {
            this.scrollDocument(-10, 'up');
            return
        }

        if (nearBottom && !this.scrollIntervalRef.current ) {
            this.scrollDocument(10, 'down');
            return
        }

        if (this.scrollIntervalRef.current 
        	&& (
        		(!nearTop && this.state.scrollingDirection === 'up')
        	 	|| (!nearBottom && this.state.scrollingDirection === 'down')
        	)
        ){
        	this.clearScrollInterval();
        	return
        }


	}

	clearScrollInterval = () => {
		clearInterval( this.scrollIntervalRef.current );
		this.scrollIntervalRef.current = null;
		this.setState({
			scrollingDirection: null
		})
	}

	scrollDocument = (step, direction) => {

		this.scrollIntervalRef.current = setInterval(() => { 
			var scrollY = window.pageYOffset;
			window.scroll(0, scrollY + step)
		}, 16)

		this.setState({'scrollingDirection': direction})

	}

	onDragOver = (e) => {

		const debugDragOver = false;

		if (!this.state.isDragging) return;

		this.handlePageScrollRegions( e.clientY );

		if( e.altKey && !this.state.altPressed && this.props.slug !== 'saved' && this.draggingTargetPermissions && this.draggingTargetPermissions?.role !== 'Viewer' && this.draggingTargetPermissions?.role !== 'Inuse' ){
			this.setState({
				altPressed: true
			});
		}

		// items can shift around 15px in any direction while indicating so we take them into account while measuring
		let dragMargins = this.dragMargins;
		// clear the drag map at first drag instane
		if(!this.state.showInsertionIndicator){
			this.dragMap.clear();
		}

		const galleryEl = this.gridRef.current;
		const galleryRect = galleryEl?.getBoundingClientRect();
		if (!galleryRect) return;

		const searchPoints = 60;
		let searchIncrement = 1.033;
		let sweepAngle = 2.399827721492203;

		let galleryChildren = Array.from(galleryEl.children);
		// strip out site ghost and transition measurer
		galleryChildren = galleryChildren.filter((el)=> { 
			return !el.classList.contains('transition-measurer') 
				&& !el.classList.contains('site-ghost') 
				// && !el.classList.contains('dragged') 
		});

		// start by getting position of cursor relative to gallery position
		const elements = [];
		let x = e.clientX + -galleryRect.left;
		let y = e.clientY + -galleryRect.top;

		let dist = 15;
		let i, angle =0, posX, posY = 0;
		let el = null;

		// then, make a sweeping circle around that point, collecting elements that intersect that point
		for(i = 0; i < searchPoints; i++){

			el = null;
			angle = angle + sweepAngle				
			dist = dist*searchIncrement+2
			posX = e.clientX+Math.cos(angle)*(dist);
			posY = e.clientY+Math.sin(angle)*(dist);
			el = document.elementFromPoint(posX, posY);

			if(el){
				el = el.closest('.site') || null;
			}

			if( !galleryEl.contains(el) ){
				el = null;
			}

			if(
				el &&
				elements.indexOf(el) === -1 
			){

				// instead of checking against a live boundingclientrect,
				// check against the initial positions we collected for that element in case things
				// are sliding around while we drag
				if( this.dragMap.has(el) ){

					let rect = this.dragMap.get(el);

					if(
						posX <= rect.x2 + galleryRect.left &&
						posX >= rect.x1 + galleryRect.left &&
						posY <= rect.y2 + galleryRect.top &&
						posY >= rect.y1 + galleryRect.top 
					) {
						elements.push(el);
					}

				} else {
					elements.push(el);						
				}

			}

					 // debug display
					 // 				if( !this.disp){
					 // 					this.disp = [];
					 // 				}
					 // 
					 // 				if( !this.disp[i] )  {
					 // 
					 // 					this.disp[i] = document.createElement('div');
					 // 					this.disp[i].style.pointerEvents ='none';
					 // 					this.disp[i].style.position ='fixed';
					 // 					this.disp[i].style.top =0
					 // 					this.disp[i].style.left =0
					 // 					this.disp[i].style.zIndex = 9999;
					 // 					this.disp[i].style.width = '10px';
					 // 					this.disp[i].style.borderRadius='10px';
					 // 					this.disp[i].style.height = '10px';
					 // 					this.disp[i].style.marginLeft = '-5px';
					 // 					this.disp[i].style.marginTop = '-5px';
					 // 					document.body.appendChild(this.disp[i]);
					 // 					this.disp[i].style.backgroundColor = 'red';					
					 // 
					 // 				}
					 // 
					 // 				this.disp[i].style.transform = `translate3d( ${posX}px, ${posY}px, 0px)`;




		}

		const positions = elements.map(el=>{

			let rect;
				if( this.dragMap.has(el)  ){
					rect =this.dragMap.get(el);
				} else {

					// dragMargins add a bit of dead space around each element to make allowances for drag animation
					// without it, elements can slide back and forth each frame as it goes in and out of hit-range
					let clientRect = el.getBoundingClientRect();

					rect = {
						w: clientRect.width+-(dragMargins*2),
						h: clientRect.height+-(dragMargins*2),
						x1: clientRect.left + -galleryRect.left + dragMargins,
						x2: clientRect.left + clientRect.width +-galleryRect.left + -dragMargins,
						y1: clientRect.top +-galleryRect.top + dragMargins,
						y2: clientRect.top + clientRect.height +-galleryRect.top + -dragMargins
					}

					this.dragMap.set(el, rect); 					
				}

			let inY = false,
				inX = false,
				above = false,
				below = false,
				toLeft = false,
				toRight = false,
				distance = 0,
				rise = 0,
				run = 0;

			if ( x >= rect.x1 && x <= rect.x2  ){
				inX = true;

				if ( x < rect.x1+rect.w*.5){
					toRight = true;
				} else {
					toLeft = true;
				}

				run = 0;

			} else if ( rect.x1 > x ){

				toRight = true;
				run = rect.x1 - x;

			} else if ( x > rect.x2 ){

				run = rect.x2 - x;		
				toLeft = true;
			}

			if( y >= rect.y1 && y <= rect.y2 ){
				inY = true;

				if ( y < rect.y1 + rect.h*.5 ){
					below = true;
				} else {
					above = true;
				}

				rise = 0;

			} else if ( rect.y1 > y ){
				below = true;
				rise = rect.y1 - y;

			} else if ( y > rect.y2 ){
				above = true;
				rise = rect.y2 - y;					
			}


			distance = Math.sqrt( (rise*rise)+(run*run) );	
			
			const index = galleryChildren.indexOf(el);
			const slotName = el.getAttribute('slot');
			let slotIndex = 0;
			let colIndex = 0;
			
			return {
				inX,
				inY,
				distance,
				rise,
				run,
				above,
				below,
				toRight,
				toLeft,
				index,
				slotIndex,
				el,
				rect
			}				
		});

		const defaultPos = {el: null, index: -1};
		let toLeftPos = defaultPos,
			toRightPos = defaultPos,
			abovePos = defaultPos,
			belowPos = defaultPos,
			toLeftPositions = [],
			toRightPositions = [],
			abovePositions = [],
			belowPositions = [];

		positions.forEach(pos=>{

			if( pos.above){ abovePositions.push(pos) }
			if( pos.below){ belowPositions.push(pos) }
			if( pos.toRight ){ toRightPositions.push(pos) }
			if( pos.toLeft ){ toLeftPositions.push(pos) }

		});

		let insertBeforeTarget = null;
		let closestOnLeft = _.minBy(toLeftPositions, (pos)=> Math.abs(pos.distance) )
		let closestOnRight = _.minBy(toRightPositions, (pos)=> Math.abs(pos.distance) )
		
		if( closestOnLeft && closestOnRight){

			if( Math.abs(closestOnRight.rise) < Math.abs(closestOnLeft.rise)){
				toRightPos = closestOnRight;
				toLeftPos = toLeftPositions.find(pos=>pos.index == toRightPos.index +- 1)
				// debugDragOver && console.log(toLeftPos, '<<< >>>', toRightPos)
			} else {
				toLeftPos = closestOnLeft
				toRightPos = toRightPositions.find(pos=>pos.index == toLeftPos.index + 1)
				// debugDragOver && console.log(toLeftPos, '<<< >>>', toRightPos)
			}

		} else if ( closestOnLeft){
			toLeftPos = closestOnLeft
		} else if ( closestOnRight ){
			toRightPos = closestOnRight
		}

		debugDragOver && console.log(toLeftPos.index, '<< >>', toRightPos.index, toLeftPos, toRightPos)

		// using row method, so null out above/below positions
		abovePos = defaultPos
		belowPos = defaultPos
		toRightPos = toRightPos || defaultPos
		toLeftPos = toLeftPos || defaultPos

		let insertBefore = null;

		// if there is a right positioned element, that is our insert before target
		if( toRightPos?.el){
			insertBeforeTarget = toRightPos.el
			insertBefore = toRightPos.index;

		// if there is nothing to the right, get the left and find what's right of it
		} else {

			insertBeforeTarget = toLeftPos.el?.nextElementSibling;
			insertBefore = parseInt(insertBeforeTarget?.getAttribute('site-sort'));

			// If insert before is NaN becuase we're parsing int of null
			// and toLeftPos sort is the same as the length of our render list
			if( !insertBefore && !insertBefore !== 0 && parseInt( toLeftPos.el?.getAttribute('site-sort') ) === this.state.sitesForRender.length - 1 ){
				// Drop the item one item to the left of the last item in the list.
				insertBefore = parseInt( toLeftPos.el?.getAttribute('site-sort') ) + 1;
			}
		}
		
		if (insertBefore !== undefined && insertBefore !== null) {
			debugDragOver && console.log('> insertbefore', insertBefore, insertBeforeTarget)
		} else {
			debugDragOver && console.log('nothing to insert')
		}
		

		let offsetX = this.state.offsetDragStart.x;
		let offsetY = this.state.offsetDragStart.y;

		let insertAtIndex = this.state.draggedSiteIndex < insertBefore ? insertBefore - 1 : insertBefore;


		// **********
		// Handle dropping below the last row of sites 
		// *********
		let lastEl = galleryChildren[galleryChildren.length - 1];
		let lastElRect = null;
		let lastElBottomThreshold = null;
		// Make sure we have a last El and it's not the only item in the list
		if( lastEl && galleryChildren.length - 1 !== 0 ){
			lastElRect = lastEl.getBoundingClientRect();
			// Get the absolute bottom of the list in pixels
			lastElBottomThreshold = lastElRect.top + window.scrollY + lastElRect.height;
		}

		if( y > lastElBottomThreshold || // If our current Y coordinate is greater than the bottom of the list OR...
			(
				( isNaN(insertAtIndex) || !insertAtIndex && insertAtIndex !== 0 ) // If we don't have an insert index AND...
				&& galleryChildren.length - 1 !== 0 // If we have more than 1 item to sort.
			)
		){
			// Set the left Pos to the final item in the list.
			toLeftPos  = { index: galleryChildren.length - 1 };
			toRightPos = { index: -1 };
			abovePos   = { index: -1 };
			belowPos   = { index: -1 };
			// Insert to the final item in the list.
			insertAtIndex = galleryChildren.length - 1;
		}

		const isDraggingOverNavigation = e.target.closest('.main-navigation') ? true : false;
		if (isDraggingOverNavigation) {
			// Prevent sorting if dragging over navigation
			this.clearScrollInterval();
			insertAtIndex = null;
		}

		const draggedOverFolderEl = e.target.closest('.folders a[folder-id]');
		let draggedOverFolderId = null;
		if( draggedOverFolderEl ){
			draggedOverFolderId = draggedOverFolderEl.getAttribute('folder-id');
			// Check if site beign dragged is already in the dragged over folder (using the draggedOverFolderId).
			// Add hovering class to folder
			if( this.props.folder?.id != draggedOverFolderId ){
				draggedOverFolderEl.classList.add('hovering');
			}
			// Remove hovering class from all other folders
			[...document.querySelectorAll('.folders a[folder-id].hovering')].forEach(folder=>{
				if( folder !== draggedOverFolderEl ){
					folder.classList.remove('hovering');
				}
			})
		} else {
			// Remove hovering class from all folders
			[...document.querySelectorAll('.folders a[folder-id].hovering')].forEach(folder=>{
				folder.classList.remove('hovering');
			}
			)
		}

		const showInsertionIndicator = !isDraggingOverNavigation && !draggedOverFolderId;

		this.setState({
			draggedOverFolderId,
			isDraggingOverNavigation,
			mousePos: {
				x: e.clientX - offsetX,
				y: e.clientY - offsetY,
			},
			adjacentIndexes: {
				left: toLeftPos.index,
				right: toRightPos.index,
				above: abovePos.index,
				below: belowPos.index,
			},
			droppedIndex: insertAtIndex,
			// insertBeforeTarget,
			showInsertionIndicator,
		})

	}

	getTransformBySortIndexChange = (oldIndex, newIndex, siteId) => {
		
		if ( ( !oldIndex && oldIndex !== 0 ) || oldIndex === newIndex || oldIndex === -1) return null;
		
		let oldCoords = this.state.coordinatesByIndex[oldIndex]
		let newCoords = this.state.coordinatesByIndex[newIndex]
		let x = oldCoords?.x - newCoords?.x;
		let y = oldCoords?.y - newCoords?.y;

		if( x == null || x == undefined ){
			x = 0;
		}

		if( y == null || y == undefined ){
			y = 0;
		}

		return {transform: `translate3d(${x}px, ${y}px, 0)`, transition: 'none'};
	}

	duplicateSiteFromQuery = ( customEvent ) => {

		let siteId = customEvent.detail.siteId;
		this.initDuplicateSite( siteId, null, true );

	}

	onDuplicateSiteEvent = e => {
		this.duplicateTemplate(e.detail.siteId);
	}

	initDuplicateSite = ( siteModel, newIndex, withoutModel, targetFolder ) => {

		let userRole = _.find(this.props.user.permissions, (siteItem) => { return siteItem.site_id === siteModel.id });

		if( userRole?.role === 'Viewer' || userRole?.role === 'Inuse' ){
			return
		}
	
		if( withoutModel ){
			siteModel = {
				id: siteModel,
				version: 'Cargo3'
			}
		}

		if( siteModel.version === 'Cargo3'){
			// set redux state id to clone
			this.props.setDuplicatingSiteId('clone');
		}
		
		// If we have passed an index, insert site 
		let insertBeforeThisSiteId = newIndex !== null && newIndex != undefined ? this.state.sitesForRender[newIndex].id : siteModel.id;
		let offset = newIndex ? 1 : 0;

		this.props.duplicateSite(siteModel.id, targetFolder ? targetFolder : (this.props.folder ?? null), insertBeforeThisSiteId, offset, withoutModel).then((res) => {
			// DUPLICATE_SITE_PENDING in Site / Folder
			// adds 'clone' to folder and sites list.
			if( siteModel.version === 'Cargo3'){
				// set redux state to real site ID
				// this will be cleared by account data pull
				this.props.setDuplicatingSiteId(res.data.id);
			}
		
			this.finishDuplication(res);
		})


	}

	finishDuplication = (res) => {

			if( res.data.version === 'Cargo3'){
				// Immediatly fetch permissions...
				this.props.syncAfterSiteDuplication( this.props.folder, res );
			} else {
				// Update site permissions after duplication state is complete.
				// The event this is looking for is emitted from the progress bar.
				window.addEventListener('syncAfterSiteDuplication', (e)=> { 
					this.props.syncAfterSiteDuplication( this.props.folder, res )
				}, { once: true });
			}
	}

	duplicateTemplate = ( siteID, withLogin ) => {

		if( this.props.sitePreview.previewingSite ){
			this.props.history.push(this.props.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, ''), {
				preventScroll: true
			});
		}

		this.continueTemplateDuplication( siteID );

	}

	continueTemplateDuplication = ( siteID ) => {
		// navigate to the root folder
		this.props.history.push("/");

		// scroll to top
		document.documentElement.scrollTo(0, 0)

		this.props.setDuplicatingSiteId('clone');

		// duplicate the site into the root folder
		this.props.duplicateSite(siteID, this.props.rootFolder).then((res) => {
			this.props.setDuplicatingSiteId(res.data.id);
			// Immediatly fetch permissions...
			this.props.syncAfterSiteDuplication( this.props.rootFolder, res );
		})
	}

	render() {

		if(// wait for folders to fetch
			this.props.hasFolders === false ){
			return null
		}
		if( // only after we ran our folder fetch we should check to see if the path exists
			!this.props.folder && this.props.slug !== '' && this.props.slug != 'trash'
		) {
			return null;
		}

		if( ( this.props.slug === 'trash' && !this.props.trashFetched ) ||
		 	( this.props.slug === 'trash' && this.props.deletedSites.length === 0 )
		){
			return (
				<div id="header">
					{/* <div className="title">Trash</div> */}
					<div className="message">
						The Trash is empty
					</div>
				</div>
			)
		}

		const siteGhostPosition = this.state.mousePos.x && this.state.mousePos.y ? 
			`translate3d(${this.state.mousePos.x}px, ${this.state.mousePos.y}px, 0)` 
			: `translate3d(0,0,0)`;

		const siteGhostStyle = {
			transform: siteGhostPosition,
			width: this.state.draggingSiteWidth,
			opacity: this.state.isDraggingOverNavigation ? 0.4 : 1
		}

		// Places re-sorted element ontop of drag ghost.
		const dragReturnTransitionSyles = {
			transition: 'none',
			transform: `translate3d(${this.state.draggingItemReturnTransition.x}px, ${this.state.draggingItemReturnTransition.y}px, 0)`,
			width: this.state.draggingSiteWidth,
		}

		const hideGhost = this.state.mousePos.x === null || this.state.mousePos.y === null ? true : false;
		let leftDragIndex = this.state.adjacentIndexes?.left + 1;
		let rightDragIndex = this.state.adjacentIndexes?.right + 1;
		let draggedSiteIndex = this.state.draggedSiteIndex + 1;

		let siteSpacingTranslate = ``

		if( this.state.sitesForRender.length > 0 ){
			if( this.state.showInsertionIndicator 
				&& this.state.isDragging
				// do not show if the index is adjacent to original position
				&& (leftDragIndex !== draggedSiteIndex && leftDragIndex+1 !== draggedSiteIndex)
				&& (rightDragIndex !== draggedSiteIndex && rightDragIndex-1 !== draggedSiteIndex) 
			){
				siteSpacingTranslate = `
					.site:nth-child(${leftDragIndex}) {
						--base-translate: var(--translate-left) !important;
					}
					.site:nth-child(${rightDragIndex}) {
						--base-translate: var(--translate-right) !important;
					}
				`
			}

			// Determine the transform point for the dragged site based on this.state.mousePos and this.state.offsetDragStart
			let draggedSiteTransform = 'translate3d(0,0,0)';
			if (this.state.offsetDragStart?.x && this.state.offsetDragStart?.y && this.state.coordinatesByIndex[this.state.draggedSiteIndex]) {
				const rect = this.state.draggedSiteEl.querySelector('.site-preview').getBoundingClientRect();
				const offsetPercentY = this.state.offsetDragStart.y / rect.height;
				const offsetPercentX = this.state.offsetDragStart.x / rect.width;

				const offsetCenterX = offsetPercentX - 0.5;
				const offsetCenterY = offsetPercentY - 0.5;

				// Use offsetCenterY and offsetCenterX to calculate the necessary offset for the dragged site, given that it is being scaled down to 50%.
				const offsetX = offsetCenterX * rect.width;
				const offsetY = offsetCenterY * rect.height;

				draggedSiteTransform = `translate3d(${offsetX}px, ${offsetY}px, 0)`;
			}

			const sitePreviewStyle = {
				transform: this.state.isDraggingOverNavigation ? `scale(0.5) ${draggedSiteTransform}` : 'scale(1)',
			}

			return  (
				<>

					<style>{siteSpacingTranslate}</style>

					{this.state.isDragging && <style>{`body, body * {
						${this.draggingTargetPermissions?.role === 'Viewer' || this.draggingTargetPermissions?.role === 'Inuse' ? 
							`cursor: default !important;` : this.state.altPressed ? `cursor: copy !important;` : `cursor: default !important;`
						}
					}`}</style>}

					{this.props.slug === 'trash' ?
						<div id="header">
							{/* <div className="title">Trash</div> */}
							<div className="message">
								Items in the Trash are removed after 30 days<br/>
								<AlertContext.Consumer>
								    {(Alert) =><button 
								    	className="empty-trash"
								    	onClick={()=>{

								    		Alert.openModal({
								    			type: 'slide-confirm',
								    			header: 'Empty Trash?',
								    			slideMessage: 'Slide to empty Trash',
								    			message: `All items in the Trash will be permanently deleted. Once a site is deleted, it cannot be recovered.`,
								    			ignoreUnmount: true,
								    			HotKeyProxy: HotKeyProxy,
												onConfirm: () => {
													this.props.deleteAllSites()
												}
								    		});

								    	}}
								    >
								    		Empty Trash
								    </button> }
								</AlertContext.Consumer>
							</div>
						</div>
					: null}

					<div id="siteslist" ref={this.gridRef}>
						{this.state.sitesForRender.map((site, index) => (
							<Site 
								sort={index}
								key={`site-${site.id}`} 
								id={site.id}
								containingFolderID={this.props.folder?.id}
								index={site.id}
								isDragging={ site.id === this.state.draggingSiteId }
								draggingInProgress={ this.state.draggingSiteId ? true : false }
								activeIndex={false}
								sortable={false}
								site={site}
								withinFolder={this.props.folder}
								onMouseDown={this.onMouseDown}
								message={this.props.message}
								// onMouseUp={this.onMouseUp}
								onDraggingMouseUp={ this.onSiteMouseUp }
								onDragStop={ this.onDragStop }
								dropping={ this.state.dropping && site.id === this.state.draggingSiteId }
								draggingSiteId={ this.state.draggingSiteId }
								transitioning={ this.state.sortTransition }
								duplicateTemplate={ this.duplicateTemplate }
								transitionStyles={ site.id !== this.state.draggingSiteId ? this.getTransformBySortIndexChange(this.state.previousSortIndexes?.indexOf(site.id), index, site.id) : dragReturnTransitionSyles }
							/>

						))}
						{this.state.isDragging && 
							<div 
								s-url={this.state.draggedSiteModel.site_url}
								s-id={this.state.draggedSiteModel.id}
								style={siteGhostStyle}
								className={`site site-ghost image-loaded${this.state.altPressed && this.state.draggedSiteModel.id ? ' indicate-duplication' : ''}${hideGhost ? ' hidden' : ''} ${this.state.isDraggingOverNavigation ? 'over-navigation' : ''}`}
							>	
								<div className="site-preview" style={sitePreviewStyle}>
									<img 
										src={ this.draggingImgURL }
										width="1000"
										height="625"
										onError={e => {
											e.target.src = 'https://freight.cargo.site/t/original/i/692fc6a7f6de41ef426ea5182a42cab1e7c15ff89fcc457e9dcd1c3b9bbe5704/placeholder.jpg'
										}}
									/>
								</div>										
							</div>
						} 
						
						<div className="transition-measurer" ref={this.transitionMeasureRef}></div>
					</div>
				</>
			)

		}

		return (
			<div id="header">
				<div className="title no-sites sites-list">
					{this.props.addSitesButton ?
						<div 
							className={`no-sites-button clickable`}
							onClick={()=>{
								if (this.props.addSitesButton) {
									this.props.history.push('/designlab');
								}
							}}
						>	
							<img
								className="light-no-sites"
								draggable="false"
								src="data:image/svg+xml,%3Csvg width='3001' height='1876' viewBox='0 0 3001 1876' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='3000' height='1875' fill='transparent'/%3E%3Cpath opacity='0.6' d='M1 1L3000 1875' stroke='black'/%3E%3C/svg%3E"
								width="1000"
								height="625"
							/>
							<img
								className="dark-no-sites"
								draggable="false"
								src="data:image/svg+xml,%3Csvg width='3001' height='1876' viewBox='0 0 3001 1876' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='3000' height='1875' fill='transparent'/%3E%3Cpath opacity='0.6' d='M1 1L3000 1875' stroke='rgba(255, 255, 255, .85)'/%3E%3C/svg%3E"
								width="1000"
								height="625"
							/>
							<svg id="add-sites-plus" width="63" height="63" viewBox="0 0 63 63">
							<circle cx="31.3901" cy="31.5668" r="30" fill="var(--addSites-circle-fill)"/>
							<path d="M31.3901 16.5668V46.5668" stroke="var(--addSites-plus-stroke)" strokeWidth="2"/>
							<path d="M46.3901 31.5668L16.3901 31.5668" stroke="var(--addSites-plus-stroke)" strokeWidth="2"/>
							</svg>
						</div>
					: <GlobeIcon/>}
				</div>
			</div>
		)
	}

	onKeyDown = e => {

		if( e.key === 'Alt' && this.props.slug !== 'saved'){
			this.setState({
				altPressed: true
			})
		}

		this.handleDragKeydown(e);

	}

	onKeyUp = e => {

		if( e.key === 'Alt'){
			this.setState({
				altPressed: false
			})
		}

	}

	setWindowTitle = () => {

		if(this.props.folder && this.props.folder.slug !== "all") {
			// set folder title
			document.title = 'Cargo: ' + this.props.folder.name;
		} else {
			// set default title
			document.title = 'Cargo';
		}

	}

	componentDidMount() {

		window.addEventListener("keydown", this.onKeyDown);
		window.addEventListener("keyup", this.onKeyUp);
		window.addEventListener('mousemove', this.onDragOver);
		window.addEventListener('clear-sorting-on-newtork-error', this.clearSortingState )
		window.addEventListener('duplicate-site-from-query', this.duplicateSiteFromQuery )
		window.addEventListener('duplicate-site', this.onDuplicateSiteEvent )

		this.setWindowTitle();

		// update state with actively rendered folder
		this.props.updateHomepageState({
			renderedFolder: this.props.folder?.id || null
		});

		this.dragMargins = this.transitionMeasureRef?.current?.getBoundingClientRect().width;

		this.setSitesForRender();

		if( this.props.savedFolderId === this.props.folder?.id && !this.props.templates.length ){
			// Give templates a chance to load.
			this.props.fetchTemplates()
			.then(() => {
				// Give templates a chance to process through redux.
					this.setSitesForRender();
			})
			.catch(() => {
				this.setSitesForRender();
			});
		}

	}

	componentWillUnmount() {

		window.removeEventListener('clear-sorting-on-newtork-error', this.clearSortingState )
		window.removeEventListener("keydown", this.onKeyDown);
		window.removeEventListener("keyup", this.onKeyUp);
		window.removeEventListener('duplicate-site-from-query', this.duplicateSiteFromQuery )
		window.removeEventListener('duplicate-site', this.onDuplicateSiteEvent )

		this.props.updateHomepageState({
			renderedFolder: null
		});

	}

}

function mapReduxStateToProps(state, ownProps) {

	const slug = ownProps.match.params.folder ? ownProps.match.params.folder.toLowerCase() : null;

	// if(ownProps.match.params.userid) {
	// 	console.log('load', slug, 'from user', ownProps.match.params.userid)
	// }

	let openUiWindows = { ...state.uiWindows.byId }
	const hasUiWindows = !_.isEmpty( openUiWindows );

	return {
		slug,
		folder: state.folders.find(folder => folder.slug === (slug || 'all')),
		currentSearchTerm: state.homepageState.currentSearchTerm,
		hasFolders: state.homepageState.hasFolders,
		isMobile: state.homepageState.isMobile,
		sites: state.sites,
		deletedSites: state.deletedSites,
		folders: state.folders,
		trashFetched: state.homepageState.trashFetched,
		savedFolderId: state.account.saved_folder_id,
		templates: state.templates ? state.templates.flatMap(template => template.sites) : [],
		hasUiWindow: hasUiWindows,
		uiWindows: state.uiWindows,
		user: state.account,
		addSitesButton: state.folders.find(folder => folder.slug === (slug || 'all'))?.slug === 'all',
		sitePreview: state.sitePreview,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchTemplates           : actions.fetchTemplates,
		updateFolder             : actions.updateFolder,
		updateFolderItems        : actions.updateFolderItems,
		duplicateSite            : actions.duplicateSite,
		setDuplicatingSiteId     : actions.setDuplicatingSiteId,
		syncAfterSiteDuplication : actions.syncAfterSiteDuplication,
		deleteAllSites           : actions.deleteAllSites,
		addUIWindow              : actions.addUIWindow,
		updateUserMeta			 : actions.updateUserMeta,
		updateHomepageState      : actions.updateHomepageState,
	}, dispatch);

}


export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(SitesList)