import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import Fuse from 'fuse.js';
import _ from 'lodash'
import { NavLink, withRouter } from 'react-router-dom';
import Site from "../site";
import { SectionHeader } from "./section-header";

let isDev = CARGO_ENV !== 'production';

class RowPage extends Component {

	constructor(props) {

		super(props);

		this.state = {
			streamsLoaded: false,
			siteRefs: []
		}

		this.ticking = false;
		this.fullyPaginated = false;

	}


	setupSiteRefs = () => {

		if (this.props.sitesInFolder && this.props.sitesInFolder.length > 0) {
			let siteRefs = [];
			this.props.sitesInFolder?.map(site => {
				if (site) {
					siteRefs[site.id] = React.createRef();
				}
			})
			this.setState({siteRefs: siteRefs})
		} else {
			this.setState({siteRefs: []})
		}

	}

	componentDidUpdate(prevProps, prevState) {

			// if changing the preview site model
		if ( prevProps.previewingSite === true && this.props.previewingSite === false
		) {
			let siteRef = this.state.siteRefs ? this.state.siteRefs[prevProps.previewSiteModel.id] : null;
			this.scrollToSite(siteRef)
		}

		if (prevProps.sitesInFolder !== this.props.sitesInFolder && this.props.sitesInFolder.length > 0) {
			this.setupSiteRefs();
		}

	}

	componentDidMount = () => {

		window.addEventListener('scroll', this.onScroll, {passive: true})

		if (this.props.sitesInFolder.length > 0) {
			this.setupSiteRefs();
		}

	}

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.onScroll)
	}

	scrollToSite = (siteRef) => {
		if (siteRef?.current) {
			// Scrolling while preview is open doesn't work. Scroll just after close on delay.
			setTimeout(()=> { 
				siteRef.current.scrollIntoView({ block: "center", inline: "nearest" }) 
			}, 1);
		}
	}

	// check 10 times per second max
	onScroll = _.throttle((e)=> {

		const feedKey = this.props.activeFolder.key;
		const last_known_scroll_position = window.scrollY + window.innerHeight;

		if ( !this.ticking && !this.fullyPaginated && !this.props.activeFolder.fullyPaginated ) {

			window.requestAnimationFrame(() => {

				if (last_known_scroll_position > document.body.offsetHeight - (screen.height * 2)) {

					this.ticking = true;

					if( feedKey ){
						this.fullyPaginated = true;
						this.ticking = false;
						// this.props.fetchRowSites(feedKey)
						// 	.then((res)=> {
						// 		// Check if we are still returning sites
						// 		if( res.data.length === 0 ){
						// 			// If our call is empty, we are at the end of our data set.
						// 			this.fullyPaginated = true;
						// 		}
						// 	}).finally(()=>{
						// 		this.ticking = false;
						// 	})

					} else {
						
						this.props.paginateTemplateSites(this.props.activeFolder)
							.then((res)=> {
								// Check if we are still returning sites
								if( res.data.sites.length === 0 ){
									// If our call is empty, we are at the end of our data set.
									this.fullyPaginated = true;
								}
							}).finally(() => {
								this.ticking = false;
							})

					}

				}

			});

		}

	}, 100)

	render() {

		const sectionColor = this.props.activeFolder.section.color;
		const folderType = this.props.isFeed ? this.props.activeFolder.feed_type : null;

		return (
			<>
			<div className="row-page" style={{'--section-color': sectionColor }}>

				<SectionHeader folder= { this.props.activeFolder } />

 				<div id="siteslist" className={`rows ${folderType}`}> 

 					{this.props.isFeed && folderType === 'page-scrape' ? <>
 						{this.props.sitesInFolder?.map((siteModel)=>{
							return !siteModel.display ? ( null ) : (
									<div 
										className={`link site image-loaded`} 
										key={`site-${siteModel.id}`} 
										dimensions={siteModel?.thumb_meta?.thumbnail_crop?.imageModel?.width === siteModel?.thumb_meta?.thumbnail_crop?.imageModel?.height ? 'square' : 'landscape'}
									>
										<div className="site-link-frame" dangerouslySetInnerHTML={ {__html: siteModel.content_partial_html.split('</a>')[0].replace(/data-src/g, 'src')} }></div>
										<div className="details"><span className="title">&nbsp;</span></div>
									</div>
								)
							})
 						}
					</> : <>
						{ this.props.sitesInFolder.map((site, index) => (
	 						<Site  
	 							key={`row-page-site-${site.id}`}  
	 							index={site.id}  
	 							site={site}
	 							isFeed={this.props.isFeed}

	 							containingFolderID={this.props.activeFolder.id}
	 							login={this.props.login} 
	 							duplicateTemplate={this.props.duplicateTemplate}
	 							forwardedRef={this.state.siteRefs ? this.state.siteRefs[site.id] : null} 
	 							message={this.props.message}
	 							siteUnavailable={ site?.is_coming_soon ?? false }
	 							setForDuplication={this.props.setForDuplication}
	 						/> 
	 					))} 
 					</>}

					
 				</div> 
 			</div>
			</>
		)
	}

}

function mapReduxStateToProps(state, ownProps) {

	const activeFolder = state.templates.find(folder => folder.slug === ownProps.match.params.folder);
	const isFeed = activeFolder.is_feed;

   	const sitesInFolder = !isFeed ? activeFolder.sites : state.designLab.feeds[activeFolder.key].data;

	return {
		userSites: state.sites,
		folders: state.templates,
		activeFolder: activeFolder,
		authenticated: state.auth.authenticated,
		rootFolder: state.folders.find(folder => folder.slug === 'all'),
		currentSearchTerm: state.homepageState.currentSearchTerm,
		sitesInFolder: sitesInFolder,
		previewingSite: state.sitePreview.previewingSite,
		previewSiteModel: state.sitePreview.previewSiteModel,
		isFeed: isFeed,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchTemplates: actions.fetchTemplates,
		duplicateSite: actions.duplicateSite,
		syncAfterSiteDuplication: actions.syncAfterSiteDuplication,
		setDuplicatingSiteId: actions.setDuplicatingSiteId,
		paginateTemplateSites: actions.paginateTemplateSites
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(RowPage))